import { Form, Select } from "formik-antd"
import * as React from "react"
import { creditScore } from "../../utils/values"

function CreditScoreInput(props) {
  return (
    <Form.Item
      name="credit_score"
      hasFeedback
      required={false}
      label="Credit Score"
      validate={(value) =>
        value && value.length === 0
          ? `Please enter a valid credit score`
          : undefined
      }
    >
      <Select
        name="credit_score"
        mode="single"
        size="large"
        style={{ textAlign: "left" }}
        placeholder="Credit Score"
      >
        {creditScore.map((s) => (
          <Select.Option key={s.value}>{s.option}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CreditScoreInput

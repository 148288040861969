import React, { useEffect, useState } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { Card } from "antd"
import { useAuth } from "../hooks/useAuth"
import { MerchantForm } from "../components/forms"
import { useApply } from "../hooks/useApply"
import { APPLICATION_STORE } from "../utils/constants"
import { getLeads } from "../services/lead.service"
import { getLoans } from "../services/loan.service"
import { Spinner } from "../components/core/Spinner"

function RootPage(props) {
  sessionStorage.removeItem("mid")
  const [redirect, setRedirect] = useState(null)
  const { authed } = useAuth()
  const { fetchMerchant, merchant, setMerchant, merchantId } = useApply()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      if (authed) {
        const loans = await getLoans()
        if (loans.success && loans.data.count > 0) {
          if (loans.data.count === 1) {
            setRedirect(`/loans/${loans.data.results[0].id}`)
          } else {
            setRedirect("/loans")
          }
        } else {
          const leads = await getLeads()
          if (leads.success && leads.data.count >= 1) {
            if (leads.data.count === 1) {
              setRedirect(`/apps/${leads.data.results[0].id}`)
            } else {
              setRedirect("/apps")
            }
          }
        }
      } else if (merchantId) {
        setRedirect(`/m/${merchantId}`)
      } else {
        setRedirect("/")
        sessionStorage.removeItem("mid")
        sessionStorage.removeItem(APPLICATION_STORE)
        setMerchant(null)
      }
    })()
  }, [authed, merchant, setMerchant, merchantId])

  async function onSubmit(values, actions) {
    console.debug("Fetching Merchant:", values, actions)
    actions.setSubmitting(true)
    const { mid } = values

    if (mid === null || mid.length === 0) {
      actions.setErrors({ mid: "Please enter a valid store identifier" })
      actions.setSubmitting(false)
    } else {
      console.debug("Fetching mid", mid)
      const response = await fetchMerchant(mid)
      if (response.success) {
        history.push(`/m/${mid}/`)
      } else {
        actions.setSubmitting(false)
        actions.resetForm()
      }
    }
  }

  if (redirect === null) {
    return <Spinner />
  }

  if (redirect !== "/") {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            from: props.location,
          },
        }}
      />
    )
  }
  return (
    <Card>
      <MerchantForm
        title="Please enter the merchant store identifier"
        label="If not sure, please ask a sales associate"
        placeholder="Merchant ID"
        onSubmit={onSubmit}
      />
    </Card>
  )
}

export default RootPage

import React from "react"
import { Formik } from "formik"
import { Typography, Row, Col, Tooltip, Space } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { SubmitButton, Form, Input, Select } from "formik-antd"
import {
  PhoneInput,
  EmailInput,
  AddressInput,
  MoneyInput,
  SsnInput,
  DateTextInput,
  EmploymentStatusInput,
} from "../inputs"
import { ApplyCompliance } from "../compliance/ApplyCompliance"
import CreditScoreInput from "../inputs/CreditScoreInput"
import AgreeCheckbox from "../inputs/AgreeCheckbox"
import { housingStatus, timeAtAddress } from "../../utils/values"
import ApplyFormMessage from "../../lang/applyform.json"

const { Title } = Typography

function ApplyForm({
  values,
  submitText,
  onSubmit,
  additionalCompliance,
  merchantCode,
  isSubmitted,
  disabledFields = null,
  autoTooltip,
}) {
  const validErrors =
    ApplyFormMessage.validMessage[merchantCode] ||
    ApplyFormMessage.validMessage["default"]
  const labels =
    ApplyFormMessage.label[merchantCode] || ApplyFormMessage.label["default"]
  const tooltips =
    ApplyFormMessage.tooltip[merchantCode] ||
    ApplyFormMessage.tooltip["default"]

  function renderInput(key, input, responsive = 12) {
    return (
      <Col
        key={key}
        xs={24}
        sm={24}
        md={responsive}
        lg={responsive}
        xl={responsive}
      >
        {input}
      </Col>
    )
  }

  function renderAddress(formik) {
    if (!values.hasOwnProperty("address")) return null
    return (
      <AddressInput
        hasFeedback
        placeholder="Address"
        required={false}
        formik={formik}
        showValidateSuccess={false}
        errorAddressMessage={validErrors.address}
        errorCityMessage={validErrors.city}
        errorZipMessage={validErrors.zip}
        disabledFields={disabledFields}
        autoTooltip={autoTooltip}
      />
    )
  }

  function renderPersonal(formik) {
    if (!values.hasOwnProperty("personal")) return null

    const ret = []
    const { personal } = values

    Object.keys(personal).forEach((k) => {
      switch (k) {
        case "first_name":
          ret.push(
            renderInput(
              "personal.first_name",
              <Form.Item
                name="personal.first_name"
                placeholder="First Name"
                label="First Name"
                required={false}
                hasFeedback
                validate={(value) =>
                  value.length === 0 ? validErrors.firstName : undefined
                }
              >
                <Input
                  size="large"
                  name="personal.first_name"
                  placeholder="First Name"
                  autoComplete="off"
                  autoCorrect="off"
                  disabled={disabledFields?.personalFirstName}
                />
              </Form.Item>
            )
          )
          break
        case "last_name":
          ret.push(
            renderInput(
              "personal.last_name",
              <Form.Item
                name="personal.last_name"
                placeholder="Last Name"
                label="Last Name"
                required={false}
                hasFeedback
                validate={(value) =>
                  value.length === 0 ? validErrors.lastName : undefined
                }
              >
                <Input
                  size="large"
                  name="personal.last_name"
                  placeholder="Last Name"
                  autoComplete="off"
                  autoCorrect="off"
                  disabled={disabledFields?.personalLastName}
                />
              </Form.Item>
            )
          )
          break
        case "email":
          ret.push(
            renderInput(
              "personal.email",
              <EmailInput
                name="personal.email"
                key="personal.email"
                label="Email"
                hasFeedback
                placeholder="Email"
                formik={formik}
                errorMessage={validErrors.email}
                disabled={disabledFields?.personalEmail}
              />
            )
          )
          break
        case "phone":
          ret.push(
            renderInput(
              "personal.phone",
              <PhoneInput
                name="personal.phone"
                key="personal.phone"
                label={labels.phone}
                tooltip={tooltips?.phone}
                hasFeedback
                placeholder="Phone"
                required={false}
                showValidateSuccess={false}
                formik={formik}
                errorMessage={validErrors.phone}
                disabled={disabledFields?.personalPhone}
                autoTooltip={autoTooltip}
              />
            )
          )
          break
        default:
          return null
      }
    })

    return ret
  }

  function renderSpecial(formik) {
    const ret = []

    if (
      values.hasOwnProperty("income") &&
      values.income.hasOwnProperty("annual_income")
    ) {
      ret.push(
        renderInput(
          "income.annual_income",
          <MoneyInput
            name="income.annual_income"
            key="income.annual_income"
            tooltip={tooltips.annual_income}
            hasFeedback
            min={9600}
            // min={
            //   merchantCode === "fortiva" ||
            //   merchantCode === "valliani-greatmall" ||
            //   merchantCode === "aff-lto" ||
            //   merchantCode === "valliani-southland"
            //     ? 9600
            //     : 18000
            // }
            label={labels.annual_income}
            formik={formik}
            placeholder="$ Annual Income"
            autoTooltip={autoTooltip}
          />,
          12
        )
      )
    }
    if (
      values.hasOwnProperty("income") &&
      values.income.hasOwnProperty("net_monthly_income")
    ) {
      ret.push(
        renderInput(
          "income.net_monthly_income",
          <MoneyInput
            name="income.net_monthly_income"
            min={1}
            hasFeedback
            label="Net Monthly Income"
            tooltip="Your monthly income after taxes."
            formik={formik}
            placeholder="$ Net Monthly Income"
            autoTooltip={autoTooltip}
          />
        )
      )
    }

    if (
      values.hasOwnProperty("income") &&
      values.income.hasOwnProperty("monthly_household_expense")
    ) {
      ret.push(
        renderInput(
          "income.monthly_household_expense",
          <MoneyInput
            name="income.monthly_household_expense"
            key="income.monthly_household_expense"
            min={1}
            tooltip="Please enter the rent or mortgage payment that you are responsible for each month. A value is required, if you do not have a housing payment, you may enter 0."
            hasFeedback
            label="Monthly Rent or Mortgage Payment"
            formik={formik}
            placeholder="$ Monthly Rent or Mortgage Payment"
            autoTooltip={autoTooltip}
          />,
          12
        )
      )
    }

    if (values.hasOwnProperty("credit_score")) {
      ret.push(
        renderInput("credit_score", <CreditScoreInput key="credit_score" />, 12)
      )
    }

    if (values.hasOwnProperty("employment")) {
      if (values.employment.hasOwnProperty("status")) {
        ret.push(
          renderInput(
            "status",
            <EmploymentStatusInput
              key="employment.status"
              name="employment.status"
            />
          )
        )
      }
    }

    if (values.hasOwnProperty("address")) {
      if (values.address.hasOwnProperty("status")) {
        ret.push(
          renderInput(
            "address.status",
            <Form.Item
              name="address.status"
              key="address.status"
              label="Housing Status"
              hasFeedback
              required={false}
              validate={(value) =>
                value.length === 0
                  ? `Please enter a valid housing status`
                  : undefined
              }
            >
              <Select
                name="address.status"
                mode="single"
                size="large"
                style={{ textAlign: "left" }}
                placeholder="Housing Type"
              >
                {housingStatus.map((s) => (
                  <Select.Option key={s.value}>{s.option}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        )
      }
      if (values.address.hasOwnProperty("address_length")) {
        ret.push(
          renderInput(
            "address.address_length",
            <Form.Item
              name="address.address_length"
              key="address.address_length"
              label="Time at Address"
              hasFeedback
              required={false}
              validate={(value) =>
                value.length === 0
                  ? `Please enter a valid time at address`
                  : undefined
              }
            >
              <Select
                name="address.address_length"
                mode="single"
                size="large"
                style={{ textAlign: "left" }}
                placeholder="Time at Address"
              >
                {timeAtAddress.map((s) => (
                  <Select.Option key={s.value}>{s.option}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        )
      }
    }

    if (values.hasOwnProperty("personal")) {
      if (values.personal.hasOwnProperty("dob")) {
        ret.push(
          renderInput(
            "personal.dob",
            <DateTextInput
              name="personal.dob"
              key="personal.dob"
              formik={formik}
              label="Birthday"
              tooltip={tooltips.dob}
              placeholder="MM/DD/YYYY"
              isBirthday
              errorMessage={validErrors.dob}
              autoTooltip={autoTooltip}
            />
          )
        )
      }

      if (values.personal.hasOwnProperty("ssn")) {
        ret.push(
          renderInput(
            "personal.ssn",
            <SsnInput
              name="personal.ssn"
              key="personal.ssn"
              label="Social Security Number"
              hasFeedback
              placeholder="Social Security Number"
              formik={formik}
              tooltip={tooltips.ssn}
              errorMessage={validErrors.ssn}
              autoTooltip={autoTooltip}
            />,
            ret.length % 2 === 0 ? 24 : 12
          )
        )
      }
    }

    return ret
  }

  return (
    <Formik initialValues={values} onSubmit={onSubmit}>
      {(formik) => (
        <Form layout="vertical">
          {values.hasOwnProperty("personal") && (
            <Title style={{ textAlign: "left" }} level={5}>
              Personal Profile
            </Title>
          )}
          <Row gutter={16}>{renderPersonal(formik)}</Row>
          {values.hasOwnProperty("address") && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Title
                style={{
                  textAlign: "left",
                  margin: 0,
                  marginRight: 10,
                  padding: 0,
                }}
                level={5}
              >
                Permanent Address
              </Title>
              {merchantCode === "trek" && (
                <Tooltip title="A PO Box or a rented mailbox cannot be used in the billing address">
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </div>
          )}
          {renderAddress(formik)}
          {values.hasOwnProperty("income") && (
            <Title style={{ textAlign: "left" }} level={5}>
              Personal Profile
            </Title>
          )}
          <Row gutter={16}>{renderSpecial(formik)}</Row>
          {values.hasOwnProperty("agree") && (
            <AgreeCheckbox merchantCode={merchantCode} />
          )}
          {additionalCompliance}
          <Row style={{ textAlign: "center", marginBottom: 30 }}>
            <Space direction="vertical">
              <ApplyCompliance />
            </Space>
          </Row>
          <Row justify="center">
            <SubmitButton size="large" shape="default" disabled={isSubmitted}>
              {submitText}
            </SubmitButton>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default ApplyForm

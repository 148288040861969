import { Progress } from "antd"
import { useTheme } from "../../hooks/useTheme"
import { moneyFormat } from "../../utils/formatters"

export default function LoanBalance({ loan }) {
  const { colors } = useTheme()
  console.log("loan", loan)

  function calculatePercentage() {
    let display = 100

    if (loan.funded_amount === "0.00" || loan.funded_amount === null)
      return display

    if (loan.type === "credit_line") {
      display =
        ((loan.approved_amount + loan.funded_amount) /
          loan.approve_order_amount) *
        100
    } else if (loan.type === "lease_to_own" || loan.type === "personal_loan") {
      display =
        ((loan.approved_amount - loan.funded_amount) / loan.approved_amount) *
        100
    }
    return display
  }

  return (
    <>
      <span style={{ fontSize: 14, color: "rgba(0, 0, 0, 0.45)" }}>
        Balance&nbsp;{moneyFormat(loan.approved_amount - loan.funded_amount)}
      </span>
      <Progress
        strokeColor={colors.green}
        showInfo={false}
        percent={calculatePercentage()}
      />
    </>
  )
}

import React, { useState } from "react"
import GenesisDisclosure from "./GenesisDiscolsure"
import GenesisApprovalCard from "./GenesisApprovalCard"

function GenesisOffer(props) {
  const [showDisclosures, setShowDisclosures] = useState(false)

  if (showDisclosures) {
    return (
      <GenesisDisclosure acceptOffer={props.acceptOffer} offer={props.offer} />
    )
  }

  return (
    <GenesisApprovalCard
      merchant={props.merchant}
      offer={props.offer}
      onReviewDisclosures={() => setShowDisclosures(true)}
    />
  )
}

export default GenesisOffer

import { Modal, Button, Row, Typography } from "antd"
import { CheckOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"
import { useTheme } from "../../hooks/useTheme"

const { Title } = Typography

function NewLoanModal({ visible, close }) {
  const { colors, isMobile } = useTheme()

  return (
    <Modal
      visible={visible}
      closable={false}
      centered
      title={
        <>
          <Row justify="center" style={{ marginTop: 10 }}>
            <CheckOutlined
              style={{ color: colors.green, fontSize: isMobile ? 32 : 48 }}
            />
          </Row>
          <Row justify="center" style={{ marginTop: 10 }}>
            <Title style={{ textAlign: "center" }} level={4}>
              <FormattedMessage
                id="NewLoanModal.title"
                defaultMessage="Account Ready"
              />
            </Title>
          </Row>
        </>
      }
      onCancel={close}
      footer={
        <Row justify="center">
          <Button onClick={close} style={{ justify: "center" }} type="primary">
            <FormattedMessage id="general.okay" defaultMessage="Okay" />
          </Button>
        </Row>
      }
    >
      <p style={{ textAlign: "center", fontSize: 18 }}>
        <FormattedMessage
          id="NewLoanModal.body"
          defaultMessage="Account is ready.  Waiting for merchant to create order.  Page updates automatically."
        />
      </p>
    </Modal>
  )
}

export default NewLoanModal

import _ from "underscore"

function setDefaults(fields) {
  Object.entries(fields).forEach((entry) => {
    const [k, v] = entry
    if (_.isObject(v)) setDefaults(v)
    if (_.isBoolean(v)) fields[k] = ""
    if (_.isString(v)) fields[k] = ""
    if (_.isArray(v)) fields[k] = ""
  })
}

/*
function omitFields(basicFields) {
    const fields = _.omit(basicFields, function (value) {
        if (_.isObject(value)) {
            if (_.keys(value).length === 0) {
                return true;
            } else {
                omitFields(value)
            }
        }
        if (_.isBoolean(value)) return !value;
        return false;
    });

    return fields;
}
*/

export function prepareApplyFields(offer) {
  console.log("papare fields", offer)
  // if (offer.originator.key !== 'citizens') {
  // return {};
  // }

  console.log("initial apply_fields", offer.apply_fields)
  const fields = offer.apply_fields

  const emp = _.isEmpty(fields)
  console.log("emp?", emp)
  // const fields = omitFields(offer.apply_fields);
  // const fields = offer.apply_fields;

  setDefaults(fields)
  if (!_.isEmpty(fields)) {
    fields.agree = false
  }

  return fields
}

import React from "react"
import { Modal, Typography } from "antd"
import BounceLoader from "react-spinners/BounceLoader"
import BeatLoader from "react-spinners/BeatLoader"

import { css } from "@emotion/react"
import { useTheme } from "../../hooks/useTheme"

const { Title } = Typography

function Loader(props) {
  const { themeColor } = useTheme()
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${themeColor};
  `
  let loader = (
    <BounceLoader color={themeColor} loading css={override} size={125} />
  )
  loader = <BeatLoader color={themeColor} loading />

  return (
    <Modal
      visible={props.visible}
      title={<Title level={3}>{props.title}</Title>}
      style={{ textAlign: "center", padding: 25 }}
      footer={null}
      closable={false}
    >
      <div style={{ padding: 30 }}>{loader}</div>
      <br />
      <Title level={5}>{props.body}</Title>
    </Modal>
  )
}
export default Loader

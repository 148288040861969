import React, { Fragment } from "react"

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx

class ThreatMetrix extends React.Component {
  constructor(props) {
    super(props)

    this.addInitScript = this.addInitScript.bind(this)
    this.addMainScript = this.addMainScript.bind(this)
    this.insertScript = this.insertScript.bind(this)
    this.insertScriptText = this.insertScriptText.bind(this)
  }

  componentDidMount() {
    /*
        const script = document.createElement('script');
        script.src = '/js/citfraud.js';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        const script2 = document.createElement('script');
        const { leadId } = this.props;
        script2.innerText = `threatmetrix.profile("h.online-metrix.net", "rz31aez3", "${leadId}");`;
        script2.async = true;
        script2.type = "text/javascript";
        document.body.appendChild(script2);
        */

    if (typeof window !== "undefined") {
      // this.addMainScript();
      this.addInitScript()

      // this.addMainScript(this.addInitScript);
    }
  }

  addMainScript(callback) {
    this.insertScript("/js/citfraud.js", callback)
  }

  // bghhrame

  addInitScript() {
    const { leadId } = this.props
    const version =
      process.env.REACT_APP_ENV === "production" ? "rz31aez3" : "bghhrame"
    const scriptText = `threatmetrix.profile("h.online-metrix.net", "${version}", "${leadId}");`
    this.insertScriptText(scriptText)
  }

  insertScript(src, callback) {
    const script = document.createElement("script")
    script.src = src
    script.type = "text/javascript"
    script.async = true
    if (callback) {
      script.onload = callback
      script.onreadystatechange = callback
    }
    document.body.appendChild(script)
  }

  insertScriptText(scriptText) {
    const script = document.createElement("script")
    script.innerText = scriptText
    script.async = true
    script.type = "text/javascript"
    console.log("script is", script)
    document.body.appendChild(script)
  }

  render() {
    return <></>
  }
}

export default ThreatMetrix

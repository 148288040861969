import React from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Button } from "antd"

function FooterLoginPage(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <p>If you already have an account, please login below</p>
      <Link to="/login">
        <Button
          shape="default"
          style={{
            color: "white",
            backgroundColor: props.merchantColor,
          }}
        >
          <FormattedMessage id="buttons.login" defaultMessage="Login" />
        </Button>
      </Link>
    </div>
  )
}

export default FooterLoginPage

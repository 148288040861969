import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"

function LogoutComponent() {
  const { logout } = useAuth()
  useEffect(() => {
    ;(async () => {
      await logout()
    })()
  })

  return <Redirect to={{ pathname: "/" }} />
}

export default LogoutComponent

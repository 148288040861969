/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { Checkbox, Form, SubmitButton } from "formik-antd"
import { Formik } from "formik"
import { Typography, Row, Button } from "antd"
import { SingleOfferCard } from "../cards"
import DocumentLink from "../compliance/DocumentLink"
import DocumentModal from "../compliance/DocumentModal"
import { useTheme } from "../../hooks/useTheme"

const { Text } = Typography

function FortivaCompliance({ offer, onSubmit, setIsSubmit }) {
  const { activeTheme } = useTheme()
  const [visible, setVisible] = useState(false)
  const [activeDoc, setActiveDoc] = useState(null)
  const [isDisclosure, setIsDisclosure] = useState(false)

  const documentObject = {}
  offer.documents.forEach((doc) => {
    documentObject[doc.type] = doc
  })

  function handleClick(doc) {
    setVisible(true)
    setActiveDoc(doc)
  }

  return (
    <>
      <DocumentModal
        doc={activeDoc}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <Formik initialValues={{ agree: false }} onSubmit={onSubmit}>
        <Form layout="vertical">
          <div style={{ textAlign: "center", paddingTop: 10 }}>
            <Form.Item
              name="agree"
              hasFeedback
              validate={(value) => {
                if (value !== true) return "Please agree to the terms."
                return undefined
              }}
            >
              <Checkbox name="agree" />
              &nbsp;&nbsp;
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontColor: "black",
                }}
              >
                Please review the{" "}
                <DocumentLink
                  theme={activeTheme}
                  doc={documentObject.terms_and_conditions}
                  handleLinkClick={handleClick}
                  title="Summary of Credit Terms"
                />{" "}
                which summary the key terms of the Cardholder Agreement that
                will govern your Fortiva Retail credit card.
              </Text>
            </Form.Item>
            <Form.Item
              name="agree1"
              hasFeedback
              validate={(value) => {
                if (value !== true) return "Please agree to the terms."
                return undefined
              }}
            >
              <Checkbox name="agree1" />
              &nbsp;&nbsp;
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontColor: "black",
                }}
              >
                By selecting this offer and checking this box, I agree that I am
                submitting an application electronically for processing and I
                certify that I am at least 18 years of age (19 in AL) and that
                everything I've stated above is true and accurate. I do certify
                that I do not presently have a petition filed under federal
                bankruptcy laws (whether voluntary or involuntarily) and do not
                anticipate filing a petition.
              </Text>
            </Form.Item>
            {isDisclosure && (
              <div
                style={{
                  height: 510,
                  border: "1px solid black",
                  marginBottom: 20,
                }}
              >
                {documentObject?.cardholder_agreement?.text && (
                  <iframe
                    style={{ padding: 10 }}
                    title="Offer"
                    height="100%"
                    width="100%"
                    srcDoc={`<html style="font-family: 'Segoe UI', Roboto, 'Helvetica Neue';">${documentObject.cardholder_agreement.text}</html>`}
                  />
                )}
              </div>
            )}
            <Row justify="center">
              <div style={{ marginTop: 0 }}>
                {isDisclosure ? (
                  <SubmitButton onClick={() => setIsSubmit(true)}>
                    Accept Offer
                  </SubmitButton>
                ) : (
                  <Button type="primary" onClick={() => setIsDisclosure(true)}>
                    Review Disclosures
                  </Button>
                )}
              </div>
            </Row>
          </div>
        </Form>
      </Formik>
    </>
  )
}

function FortivaOffer(props) {
  console.log("FortivaOffer props", props)
  return (
    <SingleOfferCard
      offer={props.offer}
      compliance={
        <FortivaCompliance
          offer={props.offer}
          onSubmit={props.acceptOffer}
          setIsSubmit={props.setIsSubmit}
        />
      }
    />
  )
}

export default FortivaOffer

import { useMemo } from "react"
import { useApiRequest } from "./useApiRequest"
import {
  getMerchant,
  getOrder,
  getOrders,
  approveOrder,
  getLoans,
  getLoan,
  getLead,
  getLeads,
  getOffer,
} from "./requests"

export const useMerchant = (mid) => {
  const request = useMemo(() => getMerchant(mid), [mid])
  return useApiRequest(request)
}

export const useOrder = (order_id, refreshOrder = 0) => {
  const request = useMemo(
    () => getOrder(order_id, refreshOrder),
    [order_id, refreshOrder]
  )
  return useApiRequest(request)
}

export const useOrders = () => {
  const request = useMemo(() => getOrders(), [])
  return useApiRequest(request)
}

export const useLoans = () => {
  const request = useMemo(() => getLoans(), [])
  return useApiRequest(request)
}

export const useLoan = (loan_id, refreshLoan = 0) => {
  const request = useMemo(
    () => getLoan(loan_id, refreshLoan),
    [loan_id, refreshLoan]
  )
  return useApiRequest(request)
}

export const useLeads = () => {
  const request = useMemo(() => getLeads(), [])
  return useApiRequest(request)
}

export const useLead = (lead_id) => {
  const request = useMemo(() => getLead(lead_id), [lead_id])
  return useApiRequest(request)
}

export const useOffer = (offer_id) => {
  const request = useMemo(() => getOffer(offer_id), [offer_id])
  return useApiRequest(request)
}

export const useApproveOrder = (order_id) => {
  const request = approveOrder(order_id)
  return useApiRequest(request)
}

import React, { useState } from "react"
import { Form, Input } from "formik-antd"

const MoneyInput = ({
  name,
  hasFeedback,
  required,
  label,
  min,
  max,
  minErrorMessage,
  maxErrorMessage,
  tooltip,
  placeholder,
  style,
  formik,
  autoTooltip,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleFocus = () => {
    setIsTooltipVisible(true)
  }
  const handleBlur = () => {
    setIsTooltipVisible(false)
  }

  const validate = (value) => {
    if (typeof value === "undefined") return undefined
    if (typeof value === "object") return "Please enter a valid amount"
    let parsed
    if (typeof value === "number") {
      parsed = value
    } else {
      parsed = parseInt(value.replace(/\D/g, ""))
    }
    const minDisplay = `$${min}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const maxDisplay = `$${max}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (min !== undefined && max !== undefined) {
      console.log(min, max, parsed)
      console.log(parsed >= min, "is parsed gre eq?")
      return parsed && parsed >= min && parsed <= max
        ? undefined
        : `Please enter an amount between ${minDisplay} and ${maxDisplay}`
    }
    if (min === undefined && max !== undefined) {
      if (parsed < max) return undefined
      if (maxErrorMessage) return maxErrorMessage
      return `Please enter an amount less than ${maxDisplay}`
    }
    return parsed && parsed >= min
      ? undefined
      : `Please enter an amount greater than ${minDisplay}`
  }

  return (
    <Form.Item
      name={name}
      hasFeedback={hasFeedback}
      required={required}
      label={label}
      validate={validate}
      tooltip={{
        title: tooltip,
        visible: autoTooltip ? isTooltipVisible : undefined,
      }}
    >
      <Input
        name={name}
        size="large"
        inputMode="numeric"
        style={style}
        placeholder={placeholder}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "")
          const parsed = value.replace(/\$\s?|(,*)/g, "")
          const display = `$ ${parsed}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          formik.setFieldValue(name, display)
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Form.Item>
  )
}

export default MoneyInput

import React, { useEffect } from "react"
import { useParams, Redirect } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useApply } from "../../hooks/useApply"
import { Spinner } from "../../components/core/Spinner"
import { useTheme } from "../../hooks/useTheme"
import {
  StreetCredLander,
  MerchantLander,
  DefaultLander,
  ProductSelectLander,
} from "../../components/landers"
import FooterLoginPage from "../FooterLoginPage"

function MerchantPage() {
  const { merchant_id } = useParams()
  const { themeLander, merchantColor, merchantLogo } = useTheme()
  const { error, loading, merchant, fetchMerchant } = useApply()

  useEffect(() => {
    fetchMerchant(merchant_id)
    // eslint-disable-next-line
  }, [merchant_id])

  if (error) {
    if (error?.response?.status === 404) {
      return <Redirect to={{ pathname: "/" }} />
    }
  }

  if (!merchant || loading) {
    return <Spinner />
  }

  let view = null

  const merchantLander = (
    <FormattedMessage
      id={
        themeLander === "boost"
          ? "MerchantLander.boost"
          : "MerchantLander.standard"
      }
      defaultMessage="Apply now and get offers instantly!"
    />
  )

  if (merchant?.selectable_product) {
    return (
      <ProductSelectLander
        img={merchantLogo}
        merchant={merchant}
        login={<FooterLoginPage merchantColor={merchantColor} />}
      />
    )
  }

  switch (themeLander) {
    case "streetcred":
      view = <StreetCredLander />
      break
    case "ace":
      view = (
        <MerchantLander
          img="/logos/ace.png"
          text={merchantLander}
          merchantColor={merchantColor}
        />
      )
      break
    case "paypossible":
    case "citizens":
      view = (
        <MerchantLander
          img={merchantLogo}
          text={merchantLander}
          merchantColor={merchantColor}
          login={<FooterLoginPage merchantColor={merchantColor} />}
        />
      )
      break
    case "boost":
      view = (
        <MerchantLander
          img="/logos/boostmobile.png"
          text={merchantLander}
          merchantColor={merchantColor}
        />
      )
      break
    default:
      view = <DefaultLander merchant={merchant} />
  }

  return <>{view}</>
}
export default MerchantPage

import React from "react"
import { Card, Row, Typography, Button } from "antd"

const { Text } = Typography

function FlexshopperOffer({ offer, disclaimer, acceptOffer }) {
  return (
    <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
      <Row justify="center">
        <img src={offer.originator.image_url} alt="lender-logo" height={60} />
      </Row>

      <Row justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
        <p style={{ fontSize: 22, margin: 0, fontWeight: 300 }}>
          You've Been Pre-Qualified* By {offer.originator.name}
        </p>
      </Row>

      <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>{offer.amount}</p>
      <br />

      {offer.term ? (
        <div>
          <b>Obtain Ownership in Up To:&nbsp;&nbsp;</b>
          {offer.term}
          &nbsp;&nbsp;
        </div>
      ) : null}

      <Row justify="center" style={{ marginTop: 20 }}>
        <Button type="primary" onClick={acceptOffer}>
          Continue
        </Button>
      </Row>

      <p
        style={{
          marginTop: 15,
          fontStyle: "italic",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        * An approval and spending limit are subject to underwriting criteria
        and additional verification.
      </p>
      <Row justify="center" style={{ marginTop: 10 }}>
        {
          <Row
            justify="center"
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontSize: 10,
              textAlign: "center",
            }}
          >
            <Text>{disclaimer || offer.originator.disclaimer}</Text>
          </Row>
        }
      </Row>
    </Card>
  )
}
export default FlexshopperOffer

import { Form, Input } from "formik-antd"
import { ValidateEmailInput } from "../../utils/validation"

function EmailInput(props) {
  return (
    <Form.Item
      name={props.name}
      hasFeedback={props.hasFeedback}
      showValidateSuccess={props.showValidateSuccess}
      required={props.required}
      label={props.label}
      validate={(value) =>
        ValidateEmailInput(value, false, props?.errorMessage)
      }
    >
      <Input
        name={props.name}
        size="large"
        autoComplete="email"
        autoCorrect="off"
        autoCapitalize="none"
        placeholder={props.placeholder}
        required={props.required}
        onChange={(e) => {
          props.formik.setFieldValue(props.name, e.target.value.trim())
        }}
        disabled={props.disabled}
      />
    </Form.Item>
  )
}

export default EmailInput

import { useState, useEffect } from "react"
import api from "../services/api"
import { useAuth } from "./useAuth"

export const useApiRequest = (request) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { logout } = useAuth()

  useEffect(() => {
    const fetchData = () => {
      api(request)
        .then((response) => {
          console.log("response", response.data)
          setLoading(false)
          setError(null)
          setData(response.data)
        })
        .catch((error) => {
          console.log("error", error)
          if (
            error.response?.status === 401 ||
            error.response?.status === 400
          ) {
            logout()
          }
          if (error.response?.status === 404) {
            console.log("got a 404 going to redirect to 404", error)
          } else {
            console.log(" you we got some other error", error)
          }
          setError(error)
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  return { error, loading, data }
}

import React, { useEffect, useState, useCallback } from "react"
import { Redirect, useParams } from "react-router-dom"
import { notification, Card, Alert, Row, Divider } from "antd"
import { FormattedMessage } from "react-intl"
import _ from "underscore"
import { OfferForm } from "../components/forms"
import { Spinner } from "../components/core/Spinner"
import Loader from "../components/core/Loader"
import LoaderPage from "../components/core/LoaderPage"
import {
  fetchOffer,
  applyAndWait,
  prepareApplication,
} from "../services/offer.service"
import { fetchLead } from "../services/lead.service"
import { getSecurePaymentDebitCard } from "../services/offer.service"
import { useApply } from "../hooks/useApply"

import { prepareApplyFields } from "../helpers/offerApply.helper"
import CitizensOtp from "../components/lenders/CitizensOtp"
import CitizensForm from "../components/forms/CitizensForm"
import OwnleaseForm from "../components/forms/OwnleaseForm"
import UownForm from "../components/forms/UownForm"
import sleep from "../utils/sleep"

import { useTheme } from "../hooks/useTheme"

export default function OfferApplyPage() {
  const { offer_id } = useParams()
  const { merchantId } = useApply()
  const [redirect, setRedirect] = useState(null)
  const [enableAutopay] = useState(false)
  const [showOtp, setShowOtp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [applyFields, setApplyFields] = useState(null)
  const [showApplyLoader, setShowApplyLoader] = useState(false)
  const [offer, setOffer] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [securePaymentDebitCardUrl, setSecurePaymentDebitCardUrl] = useState("")
  const [debitCardToken, setDebitCardToken] = useState("")

  const { isMobile, viewHeight } = useTheme()

  window.addEventListener("message", receiveIFrameMessage, false)

  const submitApplication = useCallback(
    async (applyFields) => {
      setShowApplyLoader(true)
      const applyObj = await prepareApplication(applyFields)
      const applyResponse = await applyAndWait(offer_id, applyObj)
      if (applyResponse.success) {
        setOffer(applyResponse.data)
      }
      return applyResponse
    },
    [offer_id]
  )

  async function handleSubmit(values, actions) {
    if (isSubmitted) {
      actions.setSubmitting(true)
      let applyResponse = null
      if (offer.originator.key === "ownlease" && debitCardToken !== "") {
        values = {
          ...values,
          debit_card: {
            token: debitCardToken,
          },
        }
      }
      applyResponse = await submitApplication(values)

      setShowApplyLoader(false)

      if (!applyResponse.success) {
        if (!applyResponse.data) {
          const error = applyResponse.error.response
          _.keys(error.data).forEach((k) => {
            console.log("i'm in the error", k)
            if (_.isArray(error.data[k])) {
              actions.setFieldError(k, error.data[k][0])
            } else if (_.isObject(error.data[k])) {
              console.log("what not here tho", k)
              _.keys(error.data[k]).forEach((kk) => {
                actions.setFieldError(`${k}.${kk}`, error.data[k][kk][0])
              })
            }
          })
        }

        if (applyResponse?.data?.errors?.length > 0) {
          applyResponse.data.errors.map((err) =>
            notification.error({
              message: "Error",
              description: err,
              duration: 5,
            })
          )
        } else {
          notification.error({
            message: "Error",
            description: applyResponse.error.message,
            duration: 5,
          })
        }
        setIsSubmitted(false)
      }
      actions.setSubmitting(false)
    }
  }

  const setupApplyFields = useCallback(async () => {
    if (!offer) return null
    let fields = prepareApplyFields(offer)

    if (
      process.env.REACT_APP_ENV === "development" &&
      (offer.originator.key === "americanfirst" ||
        offer.originator.key === "finwise")
    ) {
      fields = {
        address: { status: "rent" },
        bank_account: {
          account_number: 1231213,
          account_type: "checking",
          routing_number: 111000025,
        },
        debit_card: {
          cvv: 123,
          expiry_month: 10,
          expiry_year: 2024,
          number: "4111111111111111",
          zip: 10003,
        },
        income: {
          last_pay_date: "",
          next_pay_date: "",
          payroll_frequency: "weekly",
          net_monthly_income: "$ 2000.00",
        },
        documents: false,
        agree_authorize: false,
        click_submit: false,
      }
    }
    // if (offer.originator.key === "ownlease") {
    //   delete fields.income
    // }

    return _.isEmpty(fields) ? null : fields
  }, [offer])

  const redirectToLoan = useCallback(async () => {
    setLoading(true)

    let leadResponse = await fetchLead(offer.lead.id)
    let count = 0

    while (leadResponse.data.loans.length === 0) {
      if (count >= 60) {
        return
      }
      await sleep(1000)
      leadResponse = await fetchLead(offer.lead.id)
      count += 1
    }

    if (leadResponse.success) {
      if (leadResponse.data.loans.length === 0) {
        setRedirect({
          to: {
            pathname: `/leads/${leadResponse.lead.id}`,
          },
        })
      } else if (leadResponse.data.loans.length === 1) {
        setRedirect({
          to: {
            pathname: `/loans/${leadResponse.data.loans[0].id}`,
            state: {
              loan: leadResponse.data.loans[0],
            },
          },
        })
      } else {
        setRedirect({
          to: {
            pathname: `/loans`,
            state: {
              lead: leadResponse.data,
            },
          },
        })
      }
    }
    setLoading(false)
  }, [offer])

  function receiveIFrameMessage(event) {
    if (event.origin === "https://securepayments.loanpro.io") {
      if (event.data?.status === 200) {
        setDebitCardToken(event.data?.token)
      }
    }
  }

  /**
   * Make sure we have the offer data
   */
  useEffect(() => {
    let mounted = true
    if (!offer) {
      setLoading(true)
      if (mounted) {
        ;(async () => {
          const offerResponse = await fetchOffer(offer_id)
          setLoading(false)
          if (offerResponse.success) {
            setOffer(offerResponse.data)
          } else if (offerResponse.error?.response?.status === 404) {
            setRedirect({ to: { pathname: "/404" } })
          }
        })()
      }
    } else {
      ;(async function () {
        if (
          offer.originator.key === "ownlease" &&
          offer.actions?.securepayment_debit_card
        ) {
          try {
            setLoading(true)
            const result = await getSecurePaymentDebitCard(offer.id)
            if (result.success) {
              setSecurePaymentDebitCardUrl(result.data?.url || "")
            }
          } catch {
          } finally {
            setLoading(false)
          }
        }
      })()
    }
    return () => (mounted = false)
  }, [offer, offer_id, merchantId])

  useEffect(() => {
    let mounted = true
    if (!offer) return
    if (mounted) {
      if (
        offer.status === "offered" ||
        offer.status === "otp_valid" ||
        offer.status === "otp_sent"
      ) {
        if (offer.actions.send_otp || offer.actions.validate_otp) {
          setShowOtp(true)
        } else {
          setShowOtp(false)
        }
        if (offer.actions.apply) {
          ;(async () => {
            const fieldSnapshot = await setupApplyFields()
            if (
              !fieldSnapshot ||
              (offer.originator.key === "citizens" && merchantId === "trek")
            ) {
              submitApplication({})
            } else {
              setApplyFields(fieldSnapshot)
            }
          })()
        }
      } else {
        redirectToLoan()
      }
    }
    return () => (mounted = false)
  }, [offer, redirectToLoan, setupApplyFields, submitApplication, merchantId])

  // useEffect(() => {
  //   console.log("we're changing auto pay", enableAutopay)
  // }, [enableAutopay])

  if (loading || !offer) {
    if (
      offer &&
      offer.originator.key === "ownlease" &&
      offer.status !== "offered"
    ) {
      return (
        <LoaderPage
          title="Finalizing Lease"
          body="Please wait while we finalize your lease terms and set up your account"
        />
      )
    }
    return <Spinner />
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (showOtp) {
    return <CitizensOtp initialOffer={offer} updateOffer={setOffer} />
  }

  const documentObject = {}
  if (offer) {
    offer.documents.forEach((doc) => {
      documentObject[doc.type] = doc
    })
  }

  if (offer && offer.originator.key === "citizens") {
    console.log("we are citizens", offer)

    return (
      <>
        <Loader
          visible={showApplyLoader}
          title={
            <FormattedMessage
              id="OfferApplyPage.loaderTitle"
              defaultMessage="Finalizing Application"
            />
          }
          body={
            <FormattedMessage
              defaultMessage="Please wait while  we finalize your application and set up your account"
              id="OfferApplyPage.loaderBody"
            />
          }
        />
        {merchantId !== "trek" && (
          <Card
            style={{ padding: 0, margin: 0 }}
            bodyStyle={{ paddingTop: 0, margin: 0 }}
          >
            <iframe
              title="Terms"
              style={{ margin: 0, padding: 0 }}
              frameBorder="0"
              height={isMobile ? viewHeight * 0.8 : 828}
              width="100%"
              src={documentObject.terms_and_conditions.link}
            />
            <Divider />
            <Row style={{ paddingTop: 20 }}>
              <CitizensForm
                offer={offer}
                initialValues={{}}
                onSubmit={handleSubmit}
                enableAutopay={enableAutopay}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
              />
            </Row>
          </Card>
        )}
      </>
    )
  }

  if (offer && offer.originator.key === "ownlease") {
    return (
      <>
        <Loader
          visible={showApplyLoader}
          title={<FormattedMessage id="OfferApplyPage.loaderLeaseTitle" />}
          body={<FormattedMessage id="OfferApplyPage.loaderLeaseBody" />}
        />
        <Card
          style={{ padding: 0, margin: 0 }}
          bodyStyle={{ paddingTop: 0, margin: 0, width: "100%" }}
        >
          <Row style={{ paddingTop: 20 }}>
            {offer && offer.status === "offered" && applyFields && (
              <OwnleaseForm
                values={applyFields}
                securePaymentDebitCardUrl={securePaymentDebitCardUrl}
                onSubmit={handleSubmit}
                debitCardToken={debitCardToken}
                setIsSubmitted={setIsSubmitted}
                isSubmitted={isSubmitted}
                offer={offer}
              />
            )}
          </Row>
        </Card>
      </>
    )
  }

  if (offer && offer.originator.key === "uown") {
    return (
      <>
        <Loader
          visible={showApplyLoader}
          title={<FormattedMessage id="OfferApplyPage.loaderLeaseTitle" />}
          body={<FormattedMessage id="OfferApplyPage.loaderLeaseBody" />}
        />
        <Card
          style={{ padding: 0, margin: 0 }}
          bodyStyle={{ paddingTop: 0, margin: 0, width: "100%" }}
        >
          <Row style={{ paddingTop: 20 }}>
            {offer && offer.status === "offered" && applyFields && (
              <UownForm
                values={applyFields}
                submitText="Submit"
                onSubmit={handleSubmit}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
              />
            )}
          </Row>
        </Card>
      </>
    )
  }

  return (
    <>
      {offer &&
        offer.errors &&
        offer.errors.map((e) => (
          <Alert
            message={e}
            key={e}
            type="error"
            showIcon
            closable
            style={{ marginBottom: 10 }}
          />
        ))}
      <Loader
        visible={showApplyLoader}
        title={
          <FormattedMessage
            id="OfferApplyPage.loaderTitle"
            defaultMessage="Finalizing Application"
          />
        }
        body={
          <FormattedMessage
            defaultMessage="Please wait while we finalize your application and set up your account"
            id="OfferApplyPage.loaderBody"
          />
        }
      />
      {offer && offer.status === "offered" && applyFields && (
        <Card>
          <OfferForm
            values={applyFields}
            submitText="Submit"
            onSubmit={handleSubmit}
            offer={offer}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
          />
        </Card>
      )}
    </>
  )
}

const APP_THEME = {
  paypossible: {
    color: "#19C27F",
    themeColor: "#19C27F",
    logo: "/logos/paypossible_logo_green.png",
    img: "/logos/paypossible_logo_green.png",
    dark: "/paypossible_black.png",
    url: "https://paypossible.com",
    title: "paypossible",
    label: "PayPossible",
  },
  enablefinancing: {
    color: "#5a53e6",
    themeColor: "#5a53e6",
    logo: "/logos/enableLogo.png",
    dark: "/logos/enableLogo.png",
    img: "/logos/enableLogo.png",
    url: "https://app.enablefinancing.com",
    title: "enablefinancing",
    label: "Enable",
  },
  eazeconsulting: {
    color: "#1A1C39",
    themeColor: "#1A1C39",
    logo: "/logos/eazeLogo.png",
    dark: "/logos/eazeLogo.png",
    img: "/logos/eazeLogo.png",
    url: "https://app.eazeconsulting.com",
    title: "eazeconsulting",
    label: "Eaze",
  },
  boostmobile: {
    color: "#f79323",
    themeColor: "#f79323",
    logo: "/logos/boostmobile.png",
    img: "/logos/boostmobile.png",
    dark: "/PP_Logo_Landscape_Black.png",
    url: "https://app.paypossible.com",
    title: "paypossible",
    label: "PayPossible",
  },

  // trekfinancing.citizensbank.com
  citizensbank: {
    color: "#19C27F",
    themeColor: "#19C27F",
    logo: "/logos/trek.png",
    img: "/logos/trek.png",
    dark: undefined,
    url: "https://pay.citizensbank.com",
    title: "citizens",
    label: "Citizens Pay",
  },
  // apply.own.lease
  own: {
    color: "#194a6c",
    themeColor: "#194a6c",
    logo: "/logos/ownlease-logo.png",
    img: "/logos/ownlease-logo.png",
    dark: undefined,
    url: "https://app.own.lease",
    title: "ownlease",
    label: "Own.Lease",
  },
  emattressoutlet: {
    color: "#194a6c",
    themeColor: "#194a6c",
    logo: "/logos/emattressoutlet.png",
    img: "/logos/emattressoutlet.png",
    dark: undefined,
    url: "https://app.own.lease",
    title: "emattressoutlet",
    label: "Own.Lease",
  },
  flexshopper: {
    color: "#3A73D4",
    themeColor: "#3A73D4",
    logo: "/logos/flexshopper.png",
    img: "/logos/flexshopper.png",
    dark: undefined,
    url: "https://www.flexshopper.com/",
    title: "flexshopper",
    label: "FlexShopper",
  },
  bolsterhardware: {
    color: "#C51B38",
    themeColor: "#C51B38",
    logo: "/logos/ace-hardware.png",
    img: "/logos/ace-hardware.png",
    dark: undefined,
    url: "http://bolsterhardare.com/",
    title: "bolsterhardware",
    label: "BolsterHardware",
  },
}

APP_THEME["flexint"] = APP_THEME["flexshopper"]

export default APP_THEME

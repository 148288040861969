import React from "react"
import { Row, Col, Typography, Button } from "antd"
import { useHistory } from "react-router-dom"

import { useTheme } from "../../hooks/useTheme"
import PoweredBy from "../core/PoweredBy"

const { Text } = Typography

function StreetCredLander() {
  const { push } = useHistory()
  const { poweredByLogo } = useTheme()

  return (
    <div style={{ backgroundColor: "#ffffff", height: "100vh" }}>
      <Row align="middle" justify="center">
        <Col
          style={{ backgroundColor: "#ffffff", paddingTop: 90, maxWidth: 300 }}
        >
          <Row align="middle" justify="center">
            <Row align="middle" justify="center">
              <img src="/logos/scc.png" width="90%" alt="merchant-logo" />
            </Row>
            <Row align="middle" justify="center" style={{ paddingTop: 25 }}>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  lineHeight: "28px",
                }}
              >
                Street Cred Capital is now offering device financing to
                qualified customers through our partnership with PayPossible.
                The application takes just minutes to complete to get offers
                instantly.
              </Text>
            </Row>
            <Row align="middle" justify="center">
              <Button
                style={{ marginTop: 50, fontSize: 22, height: 60, width: 256 }}
                onClick={() => {
                  push("/apply")
                }}
              >
                Get Started
              </Button>
            </Row>
            <Row align="middle" justify="center">
              <br />
            </Row>
          </Row>
          {poweredByLogo && <PoweredBy />}
        </Col>
      </Row>
    </div>
  )
}

export default StreetCredLander

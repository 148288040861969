const DOCUMENT_KEYS = {
  arbitration: "Arbitration Provision",
  payment_authorization: "Payment Authorization",
  privacy_policy: "Terms of Use & Privacy Policy",
  terms_and_conditions: "Application Terms & Conditions",
  application_disclosures: "Application Disclosures",
  electronic_disclosure: "Electronic Disclosure",
  cardholder_agreement: "Cardholder Agreement",
}

function DocumentLink({ doc, theme, handleLinkClick, title }) {
  return (
    <button
      onClick={() => handleLinkClick(doc)}
      style={{
        background: "none",
        border: "none",
        padding: "0",
        color: theme.themeColor,
        textDecoration: "underline",
        cursor: "pointer",
      }}
    >
      {title || DOCUMENT_KEYS[doc.type]}
    </button>
  )
}

export default DocumentLink

import React from "react"
import { Divider, Typography, Row } from "antd"
import { useTheme } from "../../hooks/useTheme"

const { Text } = Typography

function BasicHeader(props) {
  const { themeColor, headerLogo } = useTheme()
  return (
    <>
      {false && headerLogo && (
        <img src={headerLogo} alt="header-logo" width={192} />
      )}
      <Row justify="start" style={{ marginTop: -5, marginBottom: 0 }}>
        <Text type="secondary">{props.text}</Text>
      </Row>
      <div style={{ width: "20%", marginTop: 0 }}>
        <Divider style={{ borderWidth: 5, borderColor: themeColor }} />
      </div>
    </>
  )
}
export default BasicHeader

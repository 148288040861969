import { useState } from "react"
import { Formik } from "formik"
import { Row, Col, Typography, Button } from "antd"
import { Input, Select, Checkbox, Form } from "formik-antd"
import { useApply } from "../../hooks/useApply"
// import CitizensCompliance from "../lenders/CitizensCompliance";

const { Title, Text } = Typography

function AgreeCheckbox({ offer, documentObject }) {
  return (
    <div style={{ textAlign: "center" }}>
      <Form.Item
        name="agree"
        required
        validate={(value) => {
          if (value !== true) {
            return "Please agree to continue"
          }
          return undefined
        }}
      >
        <Checkbox name="agree">
          <Text
            style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: documentObject.prequal_consent.text,
              }}
            />
          </Text>
          {false && (
            <Text
              style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
            >
              By checking this box, I acknowledge I have accessed, read, and
              agree to your&nbsp;
              <a
                rel="noreferrer"
                target="_blank"
                href={documentObject.privacy_policy.link}
              >
                Privacy Policy
              </a>
              ,&nbsp;
              <a
                rel="noreferrer"
                target="_blank"
                href={documentObject.terms_and_conditions.link}
              >
                Terms and Conditions
              </a>
              ,&nbsp;
              <a
                rel="noreferrer"
                target="_blank"
                href={documentObject.electronic_disclosure.link}
              >
                Consent to Electronic Documents
              </a>
              , and&nbsp;
              <a
                rel="noreferrer"
                target="_blank"
                href={documentObject.identity_verification.link}
              >
                Verification of My Identity
              </a>
              .&nbsp; I understand this application will result in a hard
              inquiry on my credit file that may impact my credit score.
            </Text>
          )}
        </Checkbox>

        {false && (
          <>
            <Checkbox name="agree">
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontColor: "black",
                }}
              >
                I would like to apply for this line of credit according to{" "}
                <a href={documentObject.terms_and_conditions.link}>
                  Citizens Pay Terms and Conditions
                </a>{" "}
                and I acknowledge I have accessed, read, and agree to your{" "}
                <a href="_blank">Privacy Policy</a>, and{" "}
                <a href="https://www.citizensbank.com/disclosures/APR-verification-of-identity.aspx">
                  Verification of My Identity
                </a>
                .
              </Text>
            </Checkbox>
            <Row style={{ paddingTop: 20 }}>
              <Text type="secondary" style={{ fontSize: 12 }}>
                Upon submitting your application, Citizens Pay will complete a
                full credit review that will result in a hard inquiry on your
                credit file that may impact your credit score.
              </Text>
            </Row>
          </>
        )}
      </Form.Item>
    </div>
  )
}

export default function CitizensForm({
  initialValues,
  onSubmit,
  enableAutopay,
  offer,
  isSubmitted,
  setIsSubmitted,
}) {
  const { merchantId } = useApply()
  const [autopay, setAutopay] = useState(true)
  const accountTypes = [
    { value: "checking", option: "Checking " },
    { value: "savings", option: "Savings" },
  ]

  const documentObject = {}
  if (offer) {
    offer.documents.forEach((doc) => {
      documentObject[doc.type] = doc
    })
  }

  /* async function doSubmit(values, actions) {
        actions.isValid = true;
        if (values.agree !== true) {
            actions.setFieldError("agree", "Please agree to continue");
        }
        actions.setSubmitting(false);
        console.log("here", values, actions)
    }
    */

  let values = {
    agree: false,
  }
  if (enableAutopay) {
    values = {
      bank_account: {
        account_type: "",
        account_number: "",
        routing_number: "",
      },
      agree: false,
    }
  }

  const handleAppy = (formik) => {
    setIsSubmitted(true)
    formik.handleSubmit()
  }

  return (
    <>
      <Formik initialValues={values} onSubmit={onSubmit}>
        {(formik) => (
          <Form layout="vertical">
            {false && (
              <Row>
                <Form.Item
                  name="enableAutopay"
                  required={false}
                  onChange={(e) => {
                    setAutopay(e.target.checked)
                  }}
                >
                  <Checkbox name="enableAutopay">Enable Autopay</Checkbox>
                </Form.Item>
              </Row>
            )}
            {enableAutopay && (
              <>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Title style={{ textAlign: "left" }} level={5}>
                      Add Your Monthly Repayment Method (optional)
                    </Title>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="bank_account.account_type"
                      label="Account Type"
                      defaultValue="checking"
                      validate={(value) => {
                        if (typeof value === "undefined")
                          return "Please select an account type"
                        return undefined
                      }}
                      hasFeedback
                      showValidateSuccess={false}
                      required={false}
                    >
                      <Select
                        name="bank_account.account_type"
                        mode="single"
                        autoComplete="off"
                        autoCorrect="off"
                        disabled={!autopay}
                        size="large"
                        style={{ textAlign: "left" }}
                        placeholder="Account Type"
                      >
                        {accountTypes.map((t) => (
                          <Select.Option key={t.value}>
                            {t.option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="bank_account.account_number"
                      label="Account Number"
                      required={false}
                      hasFeedback
                    >
                      <Input
                        size="large"
                        name="bank_account.account_number"
                        placeholder="Account Number"
                        disabled={!autopay}
                        autoComplete="off"
                        inputMode="numeric"
                        autoCorrect="off"
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^\d]/g, "")
                          formik.setFieldValue(
                            "bank_account.account_number",
                            value
                          )
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="bank_account.routing_number"
                      label="Routing Number"
                      required={false}
                      hasFeedback
                    >
                      <Input
                        size="large"
                        name="bank_account.routing_number"
                        placeholder="Routing Number"
                        autoComplete="off"
                        disabled={!autopay}
                        inputMode="numeric"
                        autoCorrect="off"
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^\d]/g, "")
                          formik.setFieldValue(
                            "bank_account.routing_number",
                            value
                          )
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <AgreeCheckbox offer={offer} documentObject={documentObject} />
            <Row justify="center">
              <div style={{ marginTop: 10 }}>
                <Button
                  type="primary"
                  onClick={() => handleAppy(formik)}
                  disabled={isSubmitted}
                >
                  {merchantId === "trek" ? "APPLY" : "Apply"}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
      <Row
        style={{ paddingTop: 20, fontSize: 11, textAlign: "center" }}
        className="citizen-form-footer"
      >
        <Text type="secondary">
          <span
            dangerouslySetInnerHTML={{
              __html: documentObject.footer_text.text,
            }}
          />
        </Text>
      </Row>
    </>
  )
}

import React, { useEffect, useState } from "react"
import { Route, Redirect, useLocation, useHistory } from "react-router-dom"
import { Row, Spin } from "antd"
import { useAuth } from "../../hooks/useAuth"
import BasicLayout from "./BasicLayout"

function ProtectedRoute({ component: Component, ...rest }) {
  const history = useHistory()
  const { authed, verifyToken } = useAuth()
  const { search, pathname } = useLocation()
  const token = new URLSearchParams(search).get("token")

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async function () {
      if (token) {
        setLoading(true)
        const result = await verifyToken(token)
        if (result.success) {
          history.push(pathname)
        }
        setLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [token])

  if (loading) {
    return (
      <Row justify="center">
        <Spin size="large" />
      </Row>
    )
  }

  if (!authed) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: pathname,
          },
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <BasicLayout>
          <Component {...rest} {...props} />
        </BasicLayout>
      )}
    />
  )
}

export default ProtectedRoute

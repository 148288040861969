import React from "react"
import {
  CommentOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons"

const helpLists = {
  paypossible: [
    {
      label: "Help",
      url: "https://support.paypossible.com/hc/en-us",
      icon: <QuestionCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Contact",
      url: "https://paypossible.com/contact",
      icon: <CommentOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "About",
      url: "https://www.paypossible.com/about",
      icon: <InfoCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Privacy Policy",
      url: "https://www.paypossible.com/privacy-policy",
      icon: <SafetyCertificateOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms",
      url: "https://www.paypossible.com/terms-of-use",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
  ],
  enablefinancing: [
    {
      label: "FAQ",
      url: "https://www.enablefinancing.com/faq/",
      icon: <QuestionCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Credit Authorization",
      url: "https://www.enablefinancing.com/credit-authorization-agreement/",
      icon: <CommentOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Electronic Consent",
      url: "https://www.enablefinancing.com/telephone-and-email-consent/",
      icon: <InfoCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms of Service",
      url: "https://www.enablefinancing.com/terms-of-service/",
      icon: <CustomerServiceOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Privacy Policy",
      url: "https://www.enablefinancing.com/privacy-policy/",
      icon: <SafetyCertificateOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms of Use",
      url: "https://www.enablefinancing.com/terms-of-use/",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
  ],
  eazeconsulting: [
    {
      label: "FAQ",
      url: "https://www.eazeconsulting.com/faq/",
      icon: <QuestionCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Credit Authorization",
      url: "https://www.eazeconsulting.com/credit-authorization-agreement/",
      icon: <CommentOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Electronic Consent",
      url: "https://www.eazeconsulting.com/telephone-and-email-consent/",
      icon: <InfoCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms of Service",
      url: "https://www.eazeconsulting.com/terms-of-service/",
      icon: <CustomerServiceOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Privacy Policy",
      url: "https://www.eazeconsulting.com/privacy-policy/",
      icon: <SafetyCertificateOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms",
      url: "https://www.eazeconsulting.com/terms-of-use/",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
  ],
  citizens: [
    {
      label: "FAQ",
      url: "https://www.citizenspay.com/faq/",
      icon: <QuestionCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Credit Authorization",
      url: "https://www.citizenspay.com/credit-authorization-agreement/",
      icon: <CommentOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Electronic Consent",
      url: "https://www.citizenspay.com/telephone-and-email-consent/",
      icon: <InfoCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms of Service",
      url: "https://www.citizenspay.com/terms-of-service/",
      icon: <CustomerServiceOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Privacy Policy",
      url: "https://www.citizenspay.com/privacy-policy/",
      icon: <SafetyCertificateOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms",
      url: "https://www.citizenspay.com/terms-of-use/",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
  ],
  ownlease: [
    {
      label: "Help",
      url: "https://support.own.lease/",
      icon: <QuestionCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Contact",
      url: "https://own.lease/contact-us/",
      icon: <CommentOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "About",
      url: "https://own.lease/#howitworks",
      icon: <InfoCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Privacy Policy",
      url: "https://own.lease/privacy-policy/",
      icon: <SafetyCertificateOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms",
      url: "https://own.lease/terms-of-use/",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
  ],
  flexshopper: [
    {
      label: "Help",
      url: "https://www.flexshopper.com/page/faqs",
      icon: <QuestionCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Contact",
      url: "https://www.flexshopper.com/page/contact-us",
      icon: <CommentOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "About",
      url: "https://www.flexshopper.com/page/how-it-works?itm_medium=footer-link&itm_content=how-it-works",
      icon: <InfoCircleOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Privacy Policy",
      url: "https://www.flexshopper.com/page/privacy",
      icon: <SafetyCertificateOutlined style={{ paddingRight: 5 }} />,
    },
    {
      label: "Terms",
      url: "https://www.flexshopper.com/page/terms-of-use",
      icon: <FileTextOutlined style={{ paddingRight: 5 }} />,
    },
  ],
}

export default helpLists

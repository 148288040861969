import React from "react"
import { Modal, Typography } from "antd"
import { useTheme } from "../../hooks/useTheme"

const { Title, Text } = Typography

export default function ConcoraConsentModal({ showModal, close }) {
  const { isMobile } = useTheme()

  return (
    <Modal
      visible={showModal}
      width={isMobile ? "100vw" : "80vw"}
      centered
      title={
        <Title level={3} style={{ textAlign: "center", marginTop: 30 }}>
          CONSENT TO ELECTRONIC COMMUNICATIONS
        </Title>
      }
      footer={null}
      closable
      onCancel={close}
      bodyStyle={{ padding: 30, margin: 0 }}
    >
      <Text>
        By accepting a prequalified or preapproved offer, and/or submitting an
        application online, you agree to the following and this constitutes your
        consent for us to send you electronic communications about your
        preapproved offer, application, and/or Account during the application
        process. When you successfully consent and submit your acceptance of a
        preapproved offer and/or application through this website, you have
        successfully demonstrated that you are able to access the information we
        have posted on such online website <br />
        <br />
        Communications: You consent to receive communications relating to the
        Account in electronic form. The communications covered by your consent
        may include, but are not limited to, (i) the initial disclosure
        statement, (ii) any disclosure required by federal, state, or local law,
        including disclosures under the federal Truth in Lending Act, the
        federal Fair Credit Reporting Act, and the financial privacy provisions
        of the Gramm-Leach-Bliley Act, and (iii) other disclosures, notices, or
        communications in connection with your preapproved offer and/or
        application for an Account <br />
        <br />
        Withdrawing Consent: You may not submit your acceptance of a preapproved
        offer or application online for an Account unless you also provide your
        consent to receive electronic communications. Because this consent
        applies only to your acceptance of a preapproved offer and/or submission
        of an application, and not to any future communications, you do not have
        an opportunity to withdraw the consent after your acceptance and/or
        application is submitted. <br />
        <br />
        Hardware and Software Requirements: In order to access and retain
        electronic communications, you must have the following: <br />
        <br />
        1. A widely used, recent generation web browser (for example, Internet
        Explorer, Safari, or Firefox); <br />
        2. A personal computer or equivalent device capable of connecting to the
        internet via dial-up, DSL, cable modem, wireless access protocol, or
        equivalent; <br />
        3. A widely used, recent-generation portable document file reader; and{" "}
        <br />
        4. Sufficient storage capacity on your hard drive or other data storage
        facility, or a means to print or store notices and information through
        your browser software <br />
        <br />
        Paper Copies of Communications: <br />
        Upon your request, we will provide you with a paper copy of any
        communication that we provide electronically. If you like a paper copy
        of any material, write to us at: First Electronic Bank, Member FDIC c/o
        Concora Credit, P.O. Box 4477, Beaverton, OR, 97076. There will be no
        charge for a paper copy of any material we have provided electronically.
        <br />
        <br />
      </Text>
    </Modal>
  )
}

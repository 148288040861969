import axios from "axios"
import { setupInterceptorsTo } from "./interceptors"

const api = setupInterceptorsTo(
  axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  })
)

export default api

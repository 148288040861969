import React from "react"
import { Row, Col, Typography, Button } from "antd"
import { useHistory } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import { useApply } from "../../hooks/useApply"
import PoweredBy from "../core/PoweredBy"
import { useTheme } from "../../hooks/useTheme"

const { Text } = Typography

function MerchantLander(props) {
  console.debug("Lander Props:", props)
  const { merchantId } = useApply()
  const { push } = useHistory()
  const { poweredByLogo } = useTheme()

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        height: "100vh",
        position: "relative",
      }}
    >
      <Row align="middle" justify="space-around">
        <Col
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "10rem",
            maxWidth: 300,
          }}
        >
          <Row align="middle" justify="center">
            <Row align="middle" justify="center">
              <img src={props.img} width="90%" alt="merchant-logo" />
            </Row>
            <Row align="middle" justify="center" style={{ paddingTop: 50 }}>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "28px",
                }}
              >
                {merchantId !== "trek" && props.text}
              </Text>
            </Row>
            <Row align="middle" justify="center">
              <Button
                shape="default"
                style={{
                  color: "white",
                  backgroundColor: props.merchantColor,
                  marginTop: 50,
                  fontSize: 22,
                  height: 60,
                  width: 256,
                }}
                onClick={() => {
                  push("/apply")
                }}
              >
                <FormattedMessage
                  id={
                    merchantId === "trek"
                      ? "buttons.apply.trek"
                      : "buttons.apply"
                  }
                  defaultMessage="Get Started"
                />
              </Button>
            </Row>
            <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  fontSize: 15,
                  lineHeight: "28px",
                }}
              >
                {merchantId === "trek" && (
                  <>
                    Checking eligibility{" "}
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      will not impact
                    </Text>{" "}
                    your credit score
                  </>
                )}
              </Text>
            </Row>
          </Row>
          {poweredByLogo && <PoweredBy />}
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 30 }}>
        {props.login && props.login}
      </Row>
      {merchantId === "trek" && (
        <div className="trek-merchant-bottom">
          0% APR - 9.99% APR for 6-12 Months Based on Credit: Offer available on
          qualifying purchases of select products charged to a Citizens Pay Line
          of Credit account. Minimum purchase for first transaction: $199-499,
          based on creditworthiness. Equipment purchase must be included in
          first transaction. Minimum subsequent purchase: $99. Promotional
          offers are based on creditworthiness with a 0% APR -9.99% APR from the
          date an eligible purchase is posted to your account for 6-12 months,
          then 29.99% APR for remaining term. Monthly payment and total finance
          charges are estimates, do not include taxes, and may vary from the
          actual minimum payment due and interest charges that will be shown on
          your Citizens Pay Line of Credit statement. Limited time offer. Other
          transactions charged to your account affect overall minimum payment
          amount. Regular account terms apply to non-promo purchases. Standard
          Purchase APR is 29.99% for non-promotional purchases. Account must be
          in good standing. Subject to credit approval.{" "}
          <a
            href="https://www.citizensbank.com/disclosures/genericapr-ri-citizenspay.aspx"
            rel="noreferrer"
            target="_blank"
          >
            Click here
          </a>{" "}
          for important information about Rates and Fees and the Citizens Pay
          Line of Credit Agreement. Citizens Pay Line of Credit Account offered
          by Citizens Bank, N.A.
          <br />
          <br />
          Promotional APR: Offer available on qualifying purchases of select
          products charged to a Citizens Pay Line of Credit account. 0% APR -
          9.99% APR from the date an eligible purchase is posted to your account
          until paid in full, based on term length. Minimum purchase for first
          transaction: $499. Equipment purchase must be included in first
          transaction. Minimum subsequent purchase: $99. Monthly payment and
          total finance charges are estimates, do not include taxes, and may
          vary from the actual minimum payment due and interest charges that
          will be shown on your Citizens Pay Line of Credit statement. Limited
          time offer. Standard Purchase APR is 29.99% for non-promotional
          purchases. Subject to credit approval. Other transactions affect
          overall minimum payment amount. Regular account terms apply to
          non-promo purchases. Account must be in good standing.{" "}
          <a
            href="https://www.citizensbank.com/disclosures/genericapr-ri-citizenspay.aspx"
            rel="noreferrer"
            target="_blank"
          >
            Click here
          </a>{" "}
          for important information about Rates and Fees and the Citizens Pay
          Line of Credit Agreement. Citizens Pay Line of Credit Account offered
          by Citizens Bank, N.A.
        </div>
      )}
    </div>
  )
}

export default MerchantLander

export function authHeader() {
  const jwt = sessionStorage.getItem("jwt")

  if (jwt) {
    return { authorization: `JWT ${jwt}` }
  }
  return {}
}

export function isAuthenticated() {
  return sessionStorage.getItem("jwt") != null
}

import React from "react"
import "../genesis.css"

function GenesisTermsConditions() {
  window.scrollTo({ top: 0 })
  return (
    <div
      style={{
        height: 510,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: "auto",
        border: "1px solid black",
        textAlign: "left",
      }}
    >
      <p className="disclosureHeading">
        Genesis Credit
        <sup>
          <span style={{ fontSize: "small" }}>&reg;</span>
        </sup>{" "}
        Account
        <br />
        First Electronic Bank<strong>&nbsp;</strong>
      </p>
      <table
        className="ratesAndFees"
        style={{
          height: "303px",
          cellSpacing: "0",
          cellPadding: "0",
          fontSize: 11,
        }}
      >
        <tbody>
          <tr>
            <td className="heading" colSpan="2">
              Interest Rates and Interest Charges
            </td>
          </tr>
          <tr className="ratesAndFees">
            <td className="borderBold left">
              Annual Percentage Rate (APR) for Purchases
            </td>
            <td className="borderBold right" style={{ fontSize: "18pt" }}>
              34.9%
            </td>
          </tr>
          <tr className="ratesAndFees">
            <td className="borderBold left">Paying Interest</td>
            <td className="borderNormal right">
              We will begin charging interest on purchases on the transaction
              date.
            </td>
          </tr>
          <tr className="ratesAndFees">
            <td className="borderBold left">
              For Credit Card Tips from the Consumer Financial Protection Bureau
            </td>
            <td className="borderBold right">
              To learn more about factors to consider when applying for or using
              a credit card, visit the website of the Consumer Financial
              Protection Bureau at &nbsp;
              <a
                href="http://www.consumerfinance.gov/learnmore"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.consumerfinance.gov/learnmore
              </a>
              .
            </td>
          </tr>
          <tr>
            <td className="heading" colSpan="2">
              <strong>Fees</strong>
            </td>
          </tr>
          <tr className="ratesAndFees tablerow1">
            <td className="borderBold left">Annual Fee</td>
            <td className="borderBold right">&nbsp;None</td>
          </tr>
          <tr>
            <td className="borderNormal left">
              <strong>Penalty Fees</strong>
              <ul>
                <li>Late Payment</li>
                <li className="disc">Returned Payment</li>
              </ul>
            </td>
            <td className="borderNormal right">
              <p>
                Up to{" "}
                <strong>
                  $38
                  <br />
                </strong>
                Up to <strong>$38</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: "justify", padding: "5px" }}>
        <strong>How We Will Calculate Your Balance:</strong> &nbsp;We use a
        method called &ldquo;average daily balance (including new
        purchases)&rdquo;. See &ldquo;How We Calculate Interest&rdquo; in the
        Important Disclosures below for more details.
        <br />
        <strong>Billing Rights:</strong>&nbsp;&nbsp;Information on your rights
        to dispute transactions and how to exercise those rights is provided in
        the Important Disclosures below and in the Account Agreement.
      </p>
      <hr />
      <p style={{ textAlign: "justify", padding: "5px" }}>&nbsp;</p>
      <p
        style={{
          padding: "5px",
          border: "1px solid black",
          borderRight: "none",
          borderLeft: "none",
          textAlign: "center",
          fontSize: "larger",
          fontWeight: "bold",
        }}
      >
        You can choose to stop receiving &ldquo;prescreened&rdquo; offers of
        credit from this and other companies by calling toll free,
        1-888-567-8688.&nbsp; See{" "}
        <span style={{ textDecoration: "underline" }}>
          PRESCREEN &amp; OPT OUT NOTICE
        </span>{" "}
        below for more information about prescreened offers.
      </p>
      <p
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          padding: "0px 50px 0px 50px",
          textAlign: "justify",
        }}
      >
        <span style={{ textDecoration: "underline" }}>
          PRESCREEN &amp; OPT OUT NOTICE
        </span>
        : This &ldquo;prescreened&rdquo; offer of credit is based on information
        in your credit report indicating that you meet certain
        criteria.&nbsp;&nbsp;This offer is not guaranteed if you do not meet our
        criteria.&nbsp;&nbsp;If you do not want to receive prescreened offers of
        credit from this and other companies, call the consumer reporting
        agencies toll free at 1-888-567-8688, or write to: TransUnion, P.O. Box
        505, Woodlyn, PA 19094-0505; Equifax, P.O. Box 740123, Atlanta, GA 30374
        0123; or Experian, P.O. Box 919, Allen, TX 75013.
      </p>
      <p
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          padding: "0px 50px 0px 50px",
          textAlign: "justify",
        }}
      >
        &nbsp;
      </p>
      <hr />
      <p style={{ padding: "5px", textAlign: "center" }}>
        <strong>TERMS AND CONDITIONS</strong>
      </p>
      <p>
        The Genesis Credit Account (&ldquo;Accounts&rdquo;) is issued by First
        Electronic Bank (&ldquo;we&rdquo;, &ldquo;us&rdquo;, and
        &ldquo;our&rdquo;) and may be used for purchases at participating
        retailers that accept this Account. Offer is (a) applicable only to the
        consumer who is informed that he or she is pre-approved for an Account,
        (b) non-transferable, and (c) subject to credit approval. Offer expires
        at close of business day on #PrescreenExpiration#.
      </p>
      <p>
        Information contained in your credit report from a credit reporting
        agency was used in connection with this credit offer. You are receiving
        this credit offer because you satisfied the criteria for
        creditworthiness that was previously established. However, please keep
        in mind that certain criteria for creditworthiness may still need to be
        met or verified before credit can actually be extended to you, and if we
        determine at the time you respond to this offer, that you no longer meet
        credit eligibility requirements previously established that you
        previously met, we may not extend credit to you.
      </p>
      <p>
        You authorize us to gather information about you, including from your
        employer, your bank, credit reporting agencies, (including obtaining a
        copy of your credit reports), and others, to verify your identity and
        evaluate your credit, and to review, maintain and perform collection
        activities on your Account. If you ask us, we will tell you whether or
        not we requested your credit report, and the names and addresses of any
        credit reporting agencies that provided us with such reports.
      </p>
      <p>
        To be approved for an Account, you must meet our credit qualification
        criteria. Your credit limit will be determined by your income and review
        of your debt, including debt on your credit report. You will be informed
        of your credit limit if you are approved.
      </p>
      <p>
        You authorize us, even if you are declined, to share your information,
        except your social security number, with the participating Retailers
        that accept your Account and any of their affiliates. You also authorize
        us to share experience and transaction information with them about your
        Account. To stop us from sharing information about you or your Account
        with the participating Retailers for marketing purposes, please call
        Genesis FS Card Services at 1-800-303-8341.
      </p>
      <p>
        If you are approved for an Account, we may report information about your
        Account to credit bureaus. Any late payments, missed payments, and other
        defaults on your Account may be reflected in your credit report.
      </p>
      <p>
        IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT. To
        help the government fight the funding of terrorism and money laundering
        activities, Federal law requires all financial institutions to obtain,
        verify, and record information that identifies each person who opens an
        account. What this means for you: When you open an account, we will ask
        for your name, address, date of birth, and other information that will
        allow us to identify you. We may also ask to see your driver&#39;s
        license or other identifying documents.
      </p>
      <p>
        <strong>Electronic Signature:</strong> You acknowledge that by clicking
        &ldquo;Submit&rdquo; or similar button on the electronic credit
        application, you are indicating your intent to receive electronic
        communications about your credit application for a Genesis Credit
        Account and that this shall constitute your electronic signature.
      </p>
      <p>&nbsp;</p>
      <hr />
      <p style={{ textAlign: "center" }}>
        <strong>CONSENT TO ELECTRONIC COMMUNICATIONS</strong>
      </p>
      <p>
        By accepting a preapproved offer and/or submitting an application
        online, you agree to the following and this constitutes your consent for
        us to send you electronic communications about your preapproved offer,
        application, and/or Account during the application process. When you
        successfully consent and submit your acceptance of a preapproved offer
        and/or application through this website, you have successfully
        demonstrated that you are able to access the information we have posted
        on such online website.
      </p>
      <p>
        <strong>Communications:</strong>&nbsp;You consent to receive
        communications relating to the Account in electronic form. The
        communications covered by your consent may include, but are not limited
        to, (i) the initial disclosure statement, (ii) any disclosure required
        by federal, state or local law, including disclosures under the federal
        Truth in Lending Act, the federal Fair Credit Reporting Act, and the
        financial privacy provisions of the Gramm-Leach-Bliley Act, and (iii)
        other disclosures, notices or communications in connection with your
        preapproved offer and/or application for an Account.
      </p>
      <p>
        <strong>Withdrawing Consent:</strong>&nbsp;You may not submit your
        acceptance of a preapproved offer or application online for an Account
        unless you also provide your consent to receive electronic
        communications. Because this consent applies only to your acceptance of
        a preapproved offer and/or submission of an application, and not to any
        future communications, you do not have an opportunity to withdraw the
        consent after your acceptance and/or application is submitted.
      </p>
      <p>
        <strong>Hardware and Software Requirements:</strong> In order to access
        and retain electronic communications, you must have the following:
      </p>
      <ol>
        <li>
          A widely used, recent generation web browser (for example, Internet
          Explorer, Safari or Firefox);
        </li>
        <li>
          A personal computer or equivalent device capable of connecting to the
          internet via dial-up, DSL, cable modem, wireless access protocol or
          equivalent;
        </li>
        <li>
          A widely used, recent-generation portable document file reader; and
        </li>
        <li>
          Sufficient storage capacity on your hard drive or other data storage
          facility, or a means to print or store notices and information through
          your browser software.
        </li>
      </ol>
      <p>
        <strong>Paper Copies of Communications:</strong>&nbsp;Upon your request,
        we will provide you with a paper copy of any communication that we
        provide electronically. If you would like a paper copy of any material,
        write to us at First Electronic Bank, c/o Genesis FS Card Services, P.O.
        Box 4477, Beaverton, Oregon 97076. There will be no charge for a paper
        copy of any material we have provided electronically.
        <br />
        &nbsp;
      </p>
      <hr />
      <p style={{ textAlign: "center" }}>
        <strong>
          IMPORTANT&nbsp;DISCLOSURES
          <br />
        </strong>
      </p>
      <p>
        Keep this document for your records. If you are approved for a Genesis
        Credit Account, we will mail you a complete Account Agreement.
        Definitions used in this document:
      </p>
      <ul>
        <li>
          &ldquo;you&rdquo; and &ldquo;your&rdquo; means the applicant or
          Accountholder;
        </li>
        <li>
          &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; mean First
          Electronic Bank;
        </li>
        <li>&ldquo;Account&rdquo; means your Genesis Credit Account.</li>
      </ul>
      <p>
        <strong>Using Your Account</strong>. You may use your Account for
        purchases from participating retailers that accept this Account.
      </p>
      <p>
        <strong>
          HOW INTEREST CHARGES ARE DETERMINED
          <br />
          <strong>Your Interest Rate.</strong>&nbsp;
        </strong>
        We use a daily periodic rate to calculate the interest on your Account.
        The daily periodic rate is the applicable APR multiplied by 1/365. The
        daily periodic rate for your Account is 0.0956 % and the applicable APR
        is 34.9%.
      </p>
      <p>
        <strong>When We Charge Interest.</strong>&nbsp;We charge interest on
        your purchases from the date you make the purchase until you pay the
        purchase in full.
      </p>
      <p>
        <strong>How We Calculate Interest.</strong>&nbsp;We calculate interest
        separately for each balance segment on your Account. We do this by
        applying the daily periodic rate to your average daily balance
        (including new Purchases), and then multiplying the resulting number by
        the number of days in the Billing Cycle for each balance segment. An
        average daily balance is calculated for the following balance segments
        as applicable: Purchase Balance segments and the Regular Balance
        segment.
      </p>
      <p>We calculate interest for each balance segment as follows:</p>
      <ol>
        <li>
          For each day, we take the beginning balance of each balance segment in
          the billing cycle, including any unpaid interest charges, add any new
          purchases and debits, and then subtract the applicable portion of any
          payments and credits. This gives us the daily balance. Monthly Fees,
          Late Payment Fees, Returned Payment Fees, and Documentation Fees are
          not included in the daily balance.
        </li>
        <li>
          Then, we add all the daily balances for the billing cycle together and
          divide the total by the number of days in the billing cycle.
        </li>
        <li>
          Next, we multiply the daily periodic rate by the average daily
          balance.
        </li>
        <li>
          Finally, we take the resulting number from #3 and multiply it by the
          number of days in the billing cycle. This gives us the interest charge
          for each balance segment for the billing cycle.
        </li>
      </ol>
      <p>
        <strong>Monthly Minimum Payment.</strong> Your monthly minimum payment
        due will be the greater of:
      </p>
      <ol>
        <li>$15; or</li>
        <li>
          The sum of:
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Each fixed monthly payment amount applicable to each of your
              Purchases (described below), plus
            </li>
            <li>5% of your Regular Balance.</li>
          </ol>
        </li>
      </ol>
      <p>
        Each Purchase will have a fixed monthly payment amount which we
        calculate when the Purchase is posted to your Account.&nbsp; We
        determine the fixed monthly payment amount for a Purchase by multiplying
        the Purchase amount by 5%, and then rounding the result to the nearest
        dollar. If the remaining balance of a Purchase plus accrued interest on
        that Purchase is less than the fixed monthly payment amount, we will
        reduce the fixed monthly payment amount for that Purchase accordingly.
        We will continue to include the fixed monthly payment amount for a
        Purchase in the monthly minimum payment due until the Purchase plus
        accrued interest on that Purchase is paid in full.
      </p>
      <p>
        If you make a payment in excess of the monthly minimum payment due for
        any month, it will not reduce the fixed monthly payment amount
        applicable to any Purchase that is used to calculate your monthly
        minimum payment due in subsequent months, but it may result in your
        paying off a Purchase more quickly.
      </p>
      <p>
        Your monthly minimum payment due also will include the greater of (i)
        any past due amount, or (ii) any amount by which the New Balance
        exceeded your Credit Limit. Your monthly minimum payment due will never
        exceed your New Balance.
      </p>
      <p>
        <strong>Application of Payments.</strong>&nbsp;For each Billing Cycle,
        payments up to the amount of your monthly minimum payment due will be
        applied to charges and principal due (including new transactions) in any
        way we determine. We will generally apply payments up to the amount of
        your monthly minimum payment due in a manner most favorable or
        convenient for us.
      </p>
      <p>
        For each Billing Cycle, to the extent your payments exceed your monthly
        minimum payment due, we will apply these excess amounts in the order
        required by applicable law. If applicable, during the last two Billing
        Cycles of a deferred interest period, if you have an outstanding
        deferred interest balance, we will apply these excess amounts to the
        deferred interest promotion balance.
      </p>
      <p>
        <strong>OTHER FEES</strong>
      </p>
      <p>
        <strong>Late Payment Fee.</strong>&nbsp;If we do not receive your
        monthly Minimum Payment Due by the Closing Date of the Billing Cycle in
        which the Payment Due Date occurs and the amount past due is more than
        $9.99, we will apply a Late Payment Fee to your Account. The Late
        Payment Fee is $27 if you were not charged a Late Payment Fee during any
        of the prior six billing periods. Otherwise, the Late Payment Fee is
        $38. The Late Payment Fee will never exceed the amount of your most
        recently required monthly minimum payment.
      </p>
      <p>
        <strong>Returned Payment Fee.</strong>&nbsp;If any payment on your
        Account is returned to us unpaid for any reason, we will apply a
        Returned Payment Fee to your Account. The Returned Payment Fee is $27 if
        you were not charged a Returned Payment Fee during any of the prior six
        Billing Cycles. Otherwise, the Returned Payment Fee is $38. The Returned
        Payment Fee will never exceed the monthly minimum payment.
      </p>
      <p>
        <strong>Documentation Fee.</strong>&nbsp;You may request a copy of a
        statement previously sent to you for a Documentation Fee of $3 per
        statement, which fee we will charge to your Account. Copies of sales
        tickets or other items posted to your Account may be obtained for a
        Documentation Fee of $10 per sales ticket or other item, which will be
        applied to your Account.&nbsp; Notwithstanding the foregoing, we will
        not impose any fee in connection with a good faith assertion of a
        billing error or other exercise of your Billing Rights (see below under
        &ldquo;Your Billing Rights &ndash; Keep This Notice For Future
        Use&rdquo;).
      </p>
      <p>
        <strong>MILITARY LENDING ACT (&ldquo;MLA&rdquo;)</strong>
        <br />
        The Military Lending Act provides protections for certain members of the
        Armed Forces and their dependents (&ldquo;Covered Borrowers&rdquo;). The
        provisions of this section apply to Covered Borrowers. If you would like
        more information about whether you are a Covered Borrower, you may
        contact us at 1-866-502-6439.
      </p>
      <p>
        <strong>MLA &ndash; Statement of MAPR.</strong>&nbsp;Federal law
        provides important protections to members of the Armed Forces and their
        dependents relating to extensions of consumer credit. In general, the
        cost of consumer credit to a member of the Armed Forces and his or her
        dependent may not exceed an Annual Percentage Rate of 36%. This rate
        must include, as applicable to the credit transaction or Account: (1)
        the costs associated with credit insurance premiums; (2) fees for
        ancillary products sold in connection with the credit transaction; (3)
        any application fee charged (other than certain application fees for
        specified credit transactions or Accounts); and (4) any participation
        fee charged (other than certain participation fees for a credit card
        Account).
      </p>
      <p>
        <strong>MLA &ndash; Oral Disclosures.</strong> In order to hear
        important disclosures and payment information about your Account, you
        may call 1-866-875-5929.
      </p>
      <p>
        <strong>
          MLA &ndash; Applicability of Arbitration of Disputes Provision.
        </strong>{" "}
        The Arbitration of Disputes Provision set forth in this document and in
        the Account Agreement does not apply to Covered Borrowers.
      </p>
      <p>
        <strong>OTHER PROVISIONS</strong>
        <br />
        <strong>
          ARBITRATION OF DISPUTES PROVISION: PLEASE READ THIS ARBITRATION OF
          DISPUTES PROVISION CAREFULLY. UNLESS YOU SEND US THE REJECTION NOTICE
          DESCRIBED BELOW, THIS PROVISION WILL APPLY TO YOUR ACCOUNT, AND MOST
          DISPUTES BETWEEN YOU, ON THE ONE HAND, AND US OR ANY SERVICER OF YOUR
          ACCOUNT, INCLUDING, BUT NOT LIMITED TO, GENESIS FS CARD SERVICES, INC.
          (&ldquo;GENESIS&rdquo;), ON THE OTHER HAND,&nbsp; WILL BE SUBJECT TO
          INDIVIDUAL ARBITRATION. THIS MEANS THAT: (1) NEITHER A COURT NOR A
          JURY WILL RESOLVE ANY SUCH DISPUTE; (2) YOU WILL NOT BE ABLE TO
          PARTICIPATE IN A CLASS ACTION OR SIMILAR PROCEEDING; (3) LESS
          INFORMATION WILL BE AVAILABLE; AND (4) APPEAL RIGHTS WILL BE LIMITED.
          THIS ARBITRATION OF DISPUTES PROVISION DOES NOT APPLY TO YOU IF, AS OF
          THE DATE YOU ARE APPROVED FOR AN ACCOUNT, YOU ARE A MEMBER OF THE
          ARMED FORCES OR A DEPENDENT OF SUCH MEMBER ENTITLED TO PROTECTION
          UNDER THE FEDERAL MILITARY LENDING ACT. PLEASE SEE THE SECTION OF THIS
          DOCUMENT LABELED &ldquo;MILITARY LENDING ACT.&rdquo; IF YOU WOULD LIKE
          MORE INFORMATION ABOUT WHETHER YOU ARE ENTITLED TO PROTECTION UNDER
          THE FEDERAL MILITARY LENDING ACT, YOU MAY CONTACT US AT{" "}
        </strong>
        <strong>&nbsp;1-866-502-6439.</strong>
      </p>
      <p>
        The Account Agreement, which we will send to you if approved, provides
        that you will resolve most claims against us or any servicer of your
        Account, including, but not limited to Genesis, that arise from or
        relate to your Account and other specified subjects by binding
        arbitration as opposed to in court with a judge or jury. You may opt out
        of this arbitration provision within 60 days after the opening date of
        your Account by sending a rejection notice. Your Account Agreement will
        explain how you may do so. Your Account Agreement terms will also
        provide that you waive the right to pursue class actions against us.
      </p>
      <p>
        <strong>Credit Bureau Disputes.</strong>&nbsp;If you believe we
        inaccurately reported credit history information about you or your
        Account to a credit reporting agency, write to us at Genesis FS Card
        Services, P.O. Box 4499, Beaverton, Oregon 97076.
      </p>
      <p>
        <strong>All Borrowers.</strong>&nbsp;You give us (as well as our agents,
        successors, and assigns) permission to access your credit report in
        connection with any transaction, or extension of credit and on an
        ongoing basis, for the purpose of reviewing your Account, taking
        collection action on your Account, or for any other legitimate purposes
        associated with your Account. Upon your request, we will inform you of
        whether or not a consumer credit report was ordered, and if it was, you
        will be given the name and address of the consumer reporting agency that
        furnished the report.
      </p>
      <p>
        <strong>
          YOUR BILLING RIGHTS &ndash; KEEP THIS NOTICE FOR FUTURE USE
        </strong>
        <br />
        This notice tells you about your rights and our responsibilities under
        the Fair Credit Billing Act.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>What To Do If You Find A Mistake On Your Statement.</strong>
        </span>{" "}
        If you think there is an error on your statement, please write to us at
        P.O. Box 4499, Beaverton, OR 97076.
      </p>
      <p>
        In your letter, give us the following
        information:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <ul>
        <li>
          <em>Account information:&nbsp;</em> Your name and Account Number.
        </li>
        <li>
          <em>Dollar amount:</em>&nbsp; The dollar amount of the suspected
          error.
        </li>
        <li>
          <em>Description of problem:</em>&nbsp; If you think there is an error
          on your bill, describe what you believe is wrong and why you believe
          it is a mistake.
        </li>
      </ul>
      <p>You must contact us:</p>
      <ul>
        <li>Within 60 days after the error appeared on your statement.</li>
        <li>
          At least three (3) business days before an automated payment is
          scheduled, if you want to stop payment on the amount you think is
          wrong.
        </li>
      </ul>
      <p>
        You must notify us of any potential errors in writing. You may call us,
        but if you do we are not required to investigate any potential errors,
        and you may have to pay the amount in question.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>What Will Happen After We Receive Your Letter.</strong>
        </span>{" "}
        When we receive your letter, we must do two things:
      </p>
      <ol>
        <li>
          Within 30 days of receiving your letter, we must tell you that we
          received your letter. We will also tell you if we have already
          corrected the error.
        </li>
        <li>
          Within 90 days of receiving your letter, we must either correct the
          error or explain to you why we believe the bill is correct.
        </li>
      </ol>
      <p>
        <strong>
          While we investigate whether or not there has been an error:
        </strong>
      </p>
      <ul>
        <li>
          We cannot try to collect the amount in question, or report you as
          delinquent on that amount.
        </li>
        <li>
          The charge in question may remain on your statement, and we may
          continue to charge you interest on that amount.
        </li>
        <li>
          While you do not have to pay the amount in question, you are
          responsible for the remainder of your balance.
        </li>
        <li>We can apply any unpaid amount against your credit line.</li>
      </ul>
      <p>
        <strong>
          After we finish our investigation, one of two things will happen:
        </strong>
      </p>
      <ul>
        <li>
          <em>If we made a mistake:</em> You will not have to pay the amount in
          question or any interest or other fees related to that amount.
        </li>
        <li>
          <em>If we do not believe there was a mistake:</em> You will have to
          pay the amount in question, along with applicable interest and fees.
          We will then send you a statement of the amount you owe and the date
          payment is due. We may then report you as delinquent if you do not pay
          the amount we think you owe.
        </li>
      </ul>
      <p>
        If you receive our explanation but still believe your bill is wrong, you
        must write to us within <em>10 days</em> telling us that you still
        refuse to pay. If you do so, we cannot report you as delinquent without
        also reporting that you are questioning your bill. We must tell you the
        name of anyone to whom we reported you as delinquent, and we must let
        those organizations know when the matter has been settled between us.
      </p>
      <p>
        If we do not follow all of the rules above, you do not have to pay the
        first $50 of the amount you question, even if your bill is correct.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>
            Your Rights If You Are Dissatisfied With Your Account Purchase.
          </strong>
        </span>{" "}
        If you are dissatisfied with the goods or services that you have
        purchased with your Account, and you have tried in good faith to correct
        the problem with the Retailer, you may have the right not to pay the
        remaining amount due on the Purchase.
      </p>
      <p>To use this right, all of the following must be true:</p>
      <ol>
        <li>
          The Purchase must have been made in your home state or within 100
          miles of your current mailing address, and the Purchase price must
          have been more than $50. (Note: Neither of these are necessary if your
          Purchase was based on an advertisement we mailed to you, or if we own
          the company that sold you the goods or services.)
        </li>
        <li>
          You must have used your Account for the Purchase. Purchases made with
          cash advances from an ATM or with a check that accesses your Account
          do not qualify.
        </li>
        <li>
          You must not yet have fully paid for the Purchase.
          <br />
          &nbsp;
        </li>
      </ol>
      <p>
        If all of the criteria above are met and you are still dissatisfied with
        the purchase, contact us in writing at P.O. Box 4499, Beaverton, OR
        97076.
      </p>
      <p>
        While we investigate, the same rules apply to the disputed amount as
        discussed above. After we finish our investigation, we will tell you our
        decision. At that point, if we think you owe an amount and you do not
        pay, we may report you as delinquent.
        <br />
        &nbsp;
      </p>
      <hr />
      <p style={{ textAlign: "center" }}>
        <strong>STATE DISCLOSURES</strong>
      </p>
      <p>
        <strong>California Residents:</strong> A married applicant may apply for
        a separate credit Account. As required by law, you are hereby notified
        that a negative credit report reflecting on your credit record may be
        submitted to a consumer reporting agency if you fail to fulfill the
        terms of your credit obligations. After credit approval, each applicant
        shall have the right to use the credit Account up to the limit of the
        credit Account. Each applicant may be liable for amounts extended under
        the plan to any joint applicant.
      </p>
      <p>
        <strong>Ohio Residents:</strong> The Ohio laws against discrimination
        require that all creditors make credit equally available to all
        creditworthy customers, and that credit reporting agencies maintain
        separate credit histories on each individual upon request. The Ohio
        Civil Rights Commission administers compliance with this law.
      </p>
      <p>
        <strong>Married Wisconsin Residents:</strong> No provision of any
        marital property agreement, unilateral statement or court decree
        adversely affects our rights, unless you give us a copy of such
        agreement, statement or court order before we grant you credit, or we
        have actual knowledge of the adverse obligation. All obligations on this
        Account will be incurred in the interest of your marriage or family. You
        understand that we may be required to give notice of this Account to
        your spouse.{" "}
        <strong>
          Married Wisconsin residents must furnish the name and address of their
          spouse to Genesis FS Card Services, P.O. Box 4477, Beaverton, OR
          97076.
        </strong>
      </p>
      <p>
        <strong>Delaware Residents:</strong> Service charges not in excess of
        those permitted by law will be charged on the outstanding balances from
        month to month.
      </p>
      <p>
        <strong>Kentucky Residents:</strong> You may pay the unpaid balance of
        your credit Account in whole or in part at any time.
      </p>
      <p>
        <strong>New York and Vermont Residents:</strong> We may obtain a
        consumer report for any legitimate purpose in connection with your
        Account or your application, including but not limited to reviewing,
        modifying, renewing and collecting on your Account. Upon your request,
        we will inform you of the names and addresses of any Consumer Reporting
        Agencies that have furnished the reports. New York residents may contact
        the New York State Department of Financial Services at 1-800-518-8866 or
        www.dfs.ny.gov to obtain a comparative list of credit card rates, fees
        and grace periods.
      </p>
      <p>
        <strong>Utah Residents:</strong> As required by law, you are hereby
        notified that a negative credit report reflecting on your credit record
        may be submitted to a credit reporting agency if you fail to fulfill the
        terms of your credit obligations.
      </p>
    </div>
  )
}

export default GenesisTermsConditions

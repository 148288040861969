import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import {
  Result,
  Space,
  Button,
  Tag,
  Tabs,
  Collapse,
  Badge,
  Statistic,
  List,
  Typography,
  Divider,
  Row,
  Col,
  Card,
  Alert,
  Spin,
  Table,
  Tooltip,
} from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"

import LoanDeclineView from "./LoanDeclineView"
import OrderStatus from "./OrderStatus"
import FrameModal from "../modals/FrameModal"
import { LoanAction, LoanSummary, OrdersSummary } from "."
import { MerchantInfo, OrderInfo } from "../descriptions"

import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"
import { fetchOrder } from "../../services/order.service"
import {
  formatPhoneNumber,
  formatDate,
  formatStatus,
  formatLoan,
  moneyFormat,
  getStatusColor,
  toTitleCase,
} from "../../utils/formatters"

const { Title } = Typography
const { Panel } = Collapse
const { TabPane } = Tabs

const NEW_CX = false

export default function LoanView({ loan }) {
  const { activeTheme, themeColor } = useTheme()
  const { setAppsMenu, merchantId } = useApply()
  const [showStips, setShowStips] = useState(false)
  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectOrderId, setSelectOrderId] = useState("")

  useEffect(() => {
    if (loan?.status !== "declined" && loan?.originator.key === "fortiva") {
      setAppsMenu(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan])

  const syncOrder = async (id) => {
    setSelectOrderId(id)
    setLoading(true)
    const orderResponse = await fetchOrder(id)
    if (orderResponse.success) {
      setOrder(orderResponse.data)
      setLoading(false)
    } else {
      setOrder(null)
      setLoading(false)
    }
  }

  const closeView = () => {
    setOrder(null)
  }

  const approvedText = useMemo(() => {
    if (loan && loan.status === "customer" && loan.contract_url === null) {
      return "Pre-Qualified Amount"
    }
    if (loan.originator.key === "fortiva") {
      return "Available Balance"
    }
    if (loan.originator.key === "genesis") {
      return "Approved Credit Limit"
    }
    return "Approved Amount"
  }, [loan])

  const amountText = useMemo(() => {
    if (loan && loan.status === "customer" && loan.contract_url === null) {
      return "Pre-Qualified Amount"
    }
    if (loan.originator.key === "fortiva") {
      return "Available Balance"
    }
    if (loan.originator.key === "genesis") {
      return "Available Credit Limit"
    }
    return "Approved Amount"
  }, [loan])

  const fundedText = useMemo(() => {
    switch (loan?.originator?.key) {
      case "fortiva":
        return "Transaction Amount"
      default:
        return "Funded Amount"
    }
  }, [loan])

  const typeText = useMemo(() => {
    switch (loan?.originator?.key) {
      case "genesis":
        return "Private Label Credit Card Account"
      case "citizens":
        return "Line of Credit"
      default:
        return formatLoan(loan.type, loan.originator.key)
    }
  }, [loan])

  const lenderTitleText = useMemo(() => {
    switch (loan?.originator?.key) {
      case "genesis":
        return "Program"
      default:
        return "Lender"
    }
  }, [loan])

  const lenderName = useMemo(() => {
    switch (loan?.originator?.key) {
      case "genesis":
        return (
          <>
            <p>Brand: Concora™ Credit Account</p>
            <p>
              Last 4 of Account Number:{" "}
              {loan?.reference_id.substring(
                loan?.reference_id.length - 4,
                loan?.reference_id.length
              )}
            </p>
          </>
        )
      default:
        return <p>Brand: {loan?.originator?.name}</p>
    }
  }, [loan])

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "description",
      key: "description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <p>{text}</p>,
      align: "center",
    },
    {
      title: (
        <>
          Amount{" "}
          <Tooltip title={"Includes taxes, shipping, discount, if applied"}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text) => <h5 className="hp-text-align-right">{text}</h5>,
      align: "right",
    },
  ]

  const pendingTitle = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return (
          <div style={{ fontSize: 20, fontWeight: 600 }}>
            Hang tight, your order is being created! Don't worry - you'll have a
            chance to verify details before completing the transaction.
          </div>
        )
      default:
        return (
          <div style={{ fontSize: 20, fontWeight: 600 }}>
            Your order will appear here for approval, once created by the
            Merchant.
          </div>
        )
    }
  }, [merchantId])

  const pendingExtra = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: 500, textAlign: "left" }}>
          For further assistance with your order please contact the merchant
          below.
          <div>Merchant Name: {loan.merchant?.name || ""}</div>
          <div>Merchant Phone: {loan.merchant?.phone?.number || ""}</div>
          <div>Merchant Email: {loan.merchant?.email?.email || ""}</div>
        </div>
      </div>
    )
  }, [loan])

  const identiferCodeBody = useMemo(() => {
    if (merchantId === "trek") {
      return (
        <>
          <p
            style={{
              fontSize: 11,
              fontWeight: 500,
              marginTop: 10,
              textAlign: "left",
            }}
          >
            Trek Financing is exclusively available for in-store purchases at
            participating retailers. To finalize your purchase, simply present
            your unique identifier code to a Trek Store employee or an
            authorized retailer employee offering Trek Financing.
          </p>
          <p
            style={{
              fontSize: 11,
              fontWeight: 500,
              textAlign: "left",
            }}
          >
            If you’re not currently in-store,{" "}
            <a
              rel="noreferrer"
              href="https://www.trekbikes.com/us/en_US/store-finder/"
              target="_blank"
            >
              click here to access the Trek Store Locator
            </a>{" "}
            and use the financing filter to find a participating retailer near
            you.
          </p>
        </>
      )
    }
  }, [merchantId])

  function renderOrders() {
    if (loan.orders.length === 0) {
      if (NEW_CX) {
        return <OrderStatus step={1} />
      }

      if (merchantId === "trek") {
        return (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <b>
              <FormattedMessage id="general.uniqueIdentiferCode" />
            </b>
            <div
              style={{
                fontSize: 20,
                backgroundColor: "black",
                padding: "3px 35px",
                color: "white",
                width: "fit-content",
                margin: "5px auto",
              }}
            >
              {loan.code}
            </div>
            {identiferCodeBody}
          </div>
        )
      }

      return (
        <Result
          status="info"
          style={{ marginTop: 10, padding: 0 }}
          title="Your order has not yet been created.  Please finalize your order with the merchant to proceed."
        />
      )
    }
    if (loan.orders.length === 1 && loan.orders[0].status === "pending") {
      if (NEW_CX) {
        return <OrderStatus step={2} />
      }

      return (
        <Result
          status="warning"
          style={{ margin: 0, padding: 0 }}
          title={pendingTitle}
          extra={pendingExtra}
        />
      )
    }

    return (
      <>
        <List
          size="large"
          itemLayout="vertical"
          dataSource={loan.orders}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <Row align="middle">
                <List.Item.Meta
                  title={
                    <>
                      <span>Order for ${item.total}</span>&nbsp;&nbsp;
                      {loan.originator.key !== "ownlease" && (
                        <Tag
                          color={getStatusColor(
                            "order",
                            item.status,
                            themeColor
                          )}
                        >
                          {formatStatus(item.status)}
                        </Tag>
                      )}
                    </>
                  }
                  description={`Order ${
                    item.purchase_order ? item.purchase_order : ""
                  } for $${item.total} at ${loan.merchant.name}`}
                />
                <>
                  {loan.originator.key === "citizens" ||
                  loan.originator.key === "citi" ? (
                    <>
                      {order && item.id === selectOrderId ? (
                        <Button type="link" onClick={closeView}>
                          Close
                        </Button>
                      ) : (
                        <Button
                          type="link"
                          onClick={() => syncOrder(item.id)}
                          disabled={item.status === "cancelled"}
                        >
                          View
                        </Button>
                      )}
                    </>
                  ) : (
                    <Link to={`/orders/${item.id}`}>View</Link>
                  )}
                </>
              </Row>
              {item.id === selectOrderId && renderOrderDetails()}
            </List.Item>
          )}
        />
        {merchantId === "trek" && (
          <Row align="middle" style={{ paddingLeft: 16 }}>
            <b>
              <FormattedMessage id="general.uniqueIdentiferCode" />
            </b>
            <Row align="middle" style={{ marginTop: 5 }}>
              <div
                style={{
                  fontSize: 16,
                  backgroundColor: "black",
                  padding: "3px 30px",
                  color: "white",
                  width: "fit-content",
                }}
              >
                {loan.code}
              </div>
              <div
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  marginLeft: 10,
                  width: "72%",
                }}
              >
                {identiferCodeBody}
              </div>
            </Row>
          </Row>
        )}
      </>
    )
  }

  function renderOrderDetails() {
    if (loading) {
      return (
        <Row justify="center">
          <Spin size="default" />
        </Row>
      )
    }

    if (order) {
      return (
        <Collapse defaultActiveKey={["1"]} ghost>
          <Panel key="1" showArrow={false}>
            {process.env.REACT_APP_FE === "bolster" && (
              <Row align="middle" style={{ marginBottom: 20 }}>
                <b>
                  <FormattedMessage id="general.uniqueIdentiferCode" />
                </b>

                <Row align="middle" style={{ marginTop: 5 }}>
                  <div
                    style={{
                      fontSize: 16,
                      backgroundColor: "black",
                      padding: "3px 30px",
                      color: "white",
                    }}
                  >
                    {order.alternate_id}
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: 500,
                      marginLeft: 10,
                      flex: 1,
                    }}
                  >
                    <p
                      style={{
                        marginBottom: 5,
                      }}
                    >
                      To finalize your purchase, simply present your unique
                      identifier code to a ACE Hardware employee at checkout.
                    </p>
                  </div>
                </Row>
              </Row>
            )}
            <Row justify="space-between" gutter={[16, 16]}>
              <Col md={8} xs={24}>
                <MerchantInfo merchant={order.merchant} title="Merchant" />
              </Col>
              <Col md={8} xs={24}>
                <OrderInfo order={order} title="Order Details" />
                <Link
                  to={{
                    pathname: `/loans/${order.loan.id}`,
                    state: { fromOrder: true },
                  }}
                >
                  View Account
                </Link>
              </Col>
            </Row>
            <Divider />
            <Table
              columns={columns}
              dataSource={order.items}
              bordered={false}
              rowKey="id"
              size="small"
              pagination={false}
            />
            <br />
            <Row justify="end">
              <Col
                md={6}
                sm={12}
                xs={24}
                className="hp-pb-16 hp-print-checkout"
              >
                {Number(order.subtotal) !== Number(order.total) && (
                  <Row align="middle" justify="space-between">
                    <p className="basic-par">Subtotal</p>
                    <p className="basic-par">$ {order.subtotal}</p>
                  </Row>
                )}
                {Number(order.discount) !== 0 && (
                  <Row align="middle" justify="space-between">
                    <p className="basic-par">Discount</p>
                    <p className="basic-par">-$ {order.discount}</p>
                  </Row>
                )}
                {Number(order.tax) !== 0 && (
                  <Row align="middle" justify="space-between">
                    <p className="basic-par">Tax</p>
                    <p className="basic-par">$ {order.tax}</p>
                  </Row>
                )}
                {Number(order.shipping) !== 0 && (
                  <Row align="middle" justify="space-between">
                    <p className="basic-par">Shipping</p>
                    <p className="basic-par">$ {order.shipping}</p>
                  </Row>
                )}

                <Divider />

                <Row align="middle" justify="space-between">
                  <p className="basic-par">Total</p>
                  <p className="basic-par">$ {order.total}</p>
                </Row>
              </Col>
            </Row>
            {order.actions.approve && order.status !== "approving" && (
              <Row justify="end">
                <Button
                  type="primary"
                  style={{
                    marginTop: 25,
                    textTransform:
                      merchantId === "trek" ? "uppercase" : "capitalize",
                  }}
                >
                  <Link to={`/orders/${order.id}`}>Approve</Link>
                </Button>
              </Row>
            )}
          </Panel>
        </Collapse>
      )
    }

    return null
  }

  function renderDetails() {
    return (
      <>
        <Title level={5}>{lenderTitleText}</Title>
        {lenderName}
        <p>Type: {typeText}</p>
        {loan.originator.phone && <p>Phone: {loan.originator.phone}</p>}
        {false && loan.originator.website && (
          <p>
            Website:{" "}
            <a href={loan.originator.website}>{loan.originator.website}</a>
          </p>
        )}
        <Divider />
        <Title level={5}>Account</Title>
        {!(
          loan.originator.key !== "citizens" ||
          loan.originator.key !== "fortiva"
        ) && <p>APR: {loan.apr}</p>}
        <p>
          {fundedText}: {moneyFormat(loan.funded_amount)}
        </p>
        <p>
          {amountText}: {moneyFormat(loan.approved_amount)}
        </p>
        {!(
          loan.originator.key === "fortiva" ||
          loan.merchant?.code === "trek" ||
          loan.merchant?.code === "valliani-southland" ||
          loan.merchant?.code === "citizens-test" ||
          loan.originator?.key === "genesis"
        ) && <p>Down Payment: {moneyFormat(loan.downpayment)}</p>}
        {!(
          loan.originator.key === "fortiva" ||
          loan.merchant?.code === "trek" ||
          loan.merchant?.code === "valliani-southland" ||
          loan.merchant?.code === "citizens-test" ||
          loan.originator?.key === "genesis"
        ) && <p>Payment: {moneyFormat(loan.payment)}</p>}
        {!loan.originator?.key === "genesis" && (
          <p>
            Payment Frequency:{" "}
            {loan.payment_frequency
              ? `${toTitleCase(loan.payment_frequency)}ly`
              : "N/A"}
          </p>
        )}
        <p>Date Created: {formatDate(loan.date_created)}</p>
        <p>Approved: {formatDate(loan.date_approved)}</p>

        {loan.originator.key !== "fortiva" && (
          <p>
            {" "}
            Application: <Link to={`/apps/${loan.lead.id}`}>View</Link>
          </p>
        )}
        <Divider />
        {loan.merchant && (
          <>
            <Title level={5}>Merchant</Title>
            <p>Name: {loan.merchant.name}</p>
            <p>Email: {loan.merchant.email?.email}</p>
            <p>Phone: {formatPhoneNumber(loan.merchant.phone?.number)}</p>
            <p>
              Address:
              {loan.merchant.address &&
                (loan.merchant.address?.street1,
                loan.merchant.address?.street2,
                loan.merchant.address?.city,
                loan.merchant.address?.state,
                loan.merchant.address?.zip)}
            </p>
          </>
        )}

        {loan.originator.disclaimer && (
          <>
            <Divider />
            <Title level={5}>
              {loan.originator.key === "finwise"
                ? "Account Example"
                : "Disclaimer"}
            </Title>
            <p>{loan.originator.disclaimer}</p>
          </>
        )}
      </>
    )
  }

  function renderLoanSummary() {
    if (loan.status === "declined") {
      return <LoanDeclineView loan={loan} />
    }
    return (
      <Row
        gutter={16}
        justify="center"
        style={{ textAlign: "center", paddingTop: 20, marginBottom: 0 }}
      >
        <Col span={12}>
          <Statistic
            title={fundedText}
            prefix="$"
            value={loan.funded_amount ? loan.funded_amount : "0"}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={approvedText}
            prefix="$"
            value={loan.approved_amount ? loan.approved_amount : "-"}
          />
        </Col>
      </Row>
    )
  }

  if (NEW_CX) {
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <LoanSummary loan={loan} />
        {loan.stipulation_url ? <LoanAction loan={loan} /> : null}
        {loan.stipulation_url ? null : <OrdersSummary loan={loan} />}
      </Space>
    )
  }

  const title =
    loan.originator.key === "citizens"
      ? "Citizens Pay Line of Credit"
      : `${loan.originator.name}`

  return (
    <>
      {loan.items_requested && (
        <Alert
          message={`Action Needed: ${loan.items_requested}`}
          type="error"
          showIcon
          style={{ marginBottom: 10 }}
        />
      )}
      <FrameModal
        showModal={showStips && loan.status === "customer"}
        title="Resolve Issue"
        setShow={setShowStips}
        url={loan.stipulation_url}
      />
      <Badge.Ribbon
        text={formatStatus(loan.status)}
        color={getStatusColor("loan", loan.status, themeColor)}
      >
        <Card>
          {merchantId !== "trek" && (
            <>
              <Title style={{ textAlign: "center", paddingTop: 10 }} level={4}>
                {title}
              </Title>
              <div style={{ textAlign: "center" }}>
                <img src={loan.originator.image_url} alt="lender-logo" />
              </div>
            </>
          )}
          {process.env.REACT_APP_FE !== "bolster" && renderLoanSummary()}
          {loan.stipulation_url !== null && (
            <Row justify="center" style={{ paddingTop: 20 }}>
              <Button type="primary" onClick={() => setShowStips(true)}>
                Resolve Issue
              </Button>
            </Row>
          )}
          {loan.status !== "declined" && (
            <Tabs
              defaultActiveKey={activeTheme.activeTab}
              centered
              style={{ padding: 0, margin: 0, marginTop: 10 }}
            >
              <TabPane tab="Orders" key="orders">
                {renderOrders()}
              </TabPane>
              {loan.originator.key !== "ownlease" && (
                <TabPane tab="Account" key="account">
                  {renderDetails()}
                </TabPane>
              )}
            </Tabs>
          )}
          {!NEW_CX && <Divider />}
        </Card>
      </Badge.Ribbon>
    </>
  )
}

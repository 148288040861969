import { Form, Input } from "formik-antd"

function NumericInput(props) {
  function validate(value) {
    const testLength = props.minLength ? props.minLength : props.length
    if (typeof value === "undefined") return `${props.label} is required`
    if (value.length < testLength) {
      return `${props.label} must be ${testLength} digits`
    }
    const num = parseInt(value)
    if (num >= props.min && num <= props.max) {
      return undefined
    }
    return `Please enter a valid ${props.label.toLowerCase()}`
  }

  return (
    <Form.Item
      name={props.name}
      hasFeedback={props.hasFeedback}
      showValidateSuccess={props.showValidateSuccess}
      required={props.required}
      validate={validate}
      label={props.label}
    >
      <Input
        data-openreplay-obscured
        data-private="lipsum"
        name={props.name}
        size="large"
        style={{ textAlign: "center" }}
        inputMode="numeric"
        placeholder={props.placeholder}
        required={props.required}
        onChange={(e) => {
          const value = e.target.value.replace(/[^\d]/g, "")
          props.formik.setFieldValue(props.name, value.slice(0, props.length))
        }}
      />
    </Form.Item>
  )
}
export default NumericInput
/*
            label={<Text style={{fontSize: 11}}>{props.label}</Text>}

*/

import { useEffect, useState } from "react"
import { Form, Input } from "formik-antd"
import React from "react"

const SsnInput = ({
  name,
  hasFeedback,
  showValidateSuccess,
  tooltip,
  label,
  placeholder,
  formik,
  errorMessage,
  autoTooltip,
}) => {
  const [isMask, setIsMask] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const ssnValue = formik.values?.personal?.ssn
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleFocus = () => {
    setIsTooltipVisible(true)

    setIsMask(false)
  }
  const handleBlur = () => {
    setIsTooltipVisible(false)
    setIsMask(true)
  }

  useEffect(() => {
    if (validateSSN(ssnValue, errorMessage)) {
      setIsShow(false)
    } else {
      if (isMask) {
        setIsShow(true)
      } else {
        setIsShow(false)
      }
    }
    // eslint-disable-next-line
  }, [isMask, ssnValue])

  function validateSSN(value) {
    return value && value.length <= 12
      ? undefined
      : errorMessage || "A valid SSN is required"
  }

  return (
    <Form.Item
      name={name}
      hasFeedback={hasFeedback}
      showValidateSuccess={showValidateSuccess}
      tooltip={{
        title: tooltip,
        visible: autoTooltip ? isTooltipVisible : undefined,
      }}
      label={label}
      validate={validateSSN}
    >
      {isShow ? (
        <Input
          data-openreplay-obscured
          data-private="lipsum"
          name={name}
          placeholder={placeholder}
          style={{ width: "100%" }}
          size="large"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value="***-**-****"
        />
      ) : (
        <Input
          data-openreplay-obscured
          data-private="lipsum"
          name={name}
          placeholder={placeholder}
          style={{ width: "100%" }}
          inputMode="numeric"
          size="large"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) => {
            let display = ""
            const value = e.target.value.replace(/\D/g, "")
            if (value.length < 4) {
              display = value
            } else if (value.length < 6) {
              display = `${value.slice(0, 3)}-${value.slice(3)}`
            } else {
              display = `${value.slice(0, 3)}-${value.slice(
                3,
                5
              )}-${value.slice(5, 9)}`
            }
            formik.setFieldValue(name, display)
          }}
        />
      )}
    </Form.Item>
  )
}
export default SsnInput

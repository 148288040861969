import React, { Fragment } from "react"

class FrontChat extends React.Component {
  constructor(props) {
    super(props)

    this.addInitScript = this.addInitScript.bind(this)
    this.addMainScript = this.addMainScript.bind(this)
    this.insertScript = this.insertScript.bind(this)
    this.insertScriptText = this.insertScriptText.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined" && !window.FrontChat) {
      this.addMainScript(this.addInitScript)
    }
  }

  addInitScript() {
    const { chatId } = this.props
    const scriptText = `window.FrontChat("init", {chatId: "${chatId}", useDefaultLauncher: true});`
    this.insertScriptText(scriptText)
  }

  addMainScript(callback) {
    this.insertScript(
      "https://chat-assets.frontapp.com/v1/chat.bundle.js",
      callback
    )
  }

  insertScript(src, callback) {
    const script = document.createElement("script")
    script.src = src
    script.type = "text/javascript"
    script.async = true
    if (callback) {
      script.onload = callback
      script.onreadystatechange = callback
    }
    document.body.appendChild(script)
  }

  insertScriptText(scriptText) {
    const script = document.createElement("script")
    script.innerText = scriptText
    script.async = true
    script.type = "text/javascript"
    document.body.appendChild(script)
  }

  render() {
    return <></>
  }
}

export default FrontChat

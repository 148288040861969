import { Modal, Space, Button, Row, Typography } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"
import { useTheme } from "../../hooks/useTheme"
import Compliance from "../../lang/compliance.json"

const Title = Typography

function AffFinwiseSummaryModal({ visible, close, offer }) {
  const { colors, isMobile } = useTheme()
  const language = Compliance[offer.originator.key][offer.loan_type]

  return (
    <Modal
      visible={visible}
      cancelText={null}
      centered
      title={
        <>
          <Row justify="center" style={{ marginTop: 10 }}>
            <CheckCircleOutlined
              style={{ color: colors.green, fontSize: isMobile ? 32 : 48 }}
            />
          </Row>
          <Row justify="center" style={{ marginTop: 10 }}>
            <Title style={{ textAlign: "center" }} level={3}>
              <FormattedMessage
                id="general.congratulations"
                defaultMessage="Congratulations"
              />
              !
            </Title>
          </Row>
        </>
      }
      footer={
        <Row justify="center">
          <Button onClick={close} style={{ justify: "center" }} type="primary">
            <FormattedMessage id="LenderSummaryModal.button" />
          </Button>
        </Row>
      }
      closable={false}
    >
      <img src={offer.originator.image_url} alt="lender-logo" />
      <br />
      <br />

      <Space direction="vertical" size="small" style={{ display: "flex" }}>
        <h3>
          {language.summary_offer} {offer.originator.name}
        </h3>

        <p style={{ fontSize: 14, fontWeight: 250 }}>
          {language.summary_message}
        </p>

        <h3>
          <FormattedMessage id="LenderSummaryModal.nextStepsTitle" />
        </h3>

        <p style={{ fontSize: 14, fontWeight: 250 }}>{language.summary_next}</p>
      </Space>
    </Modal>
  )
}

export default AffFinwiseSummaryModal

import React, { useState, useMemo, useCallback } from "react"
import { useHistory } from "react-router-dom"
// import { MerchantDescription, CustomerDescription } from "../components/descriptions";
import {
  Space,
  Tooltip,
  Statistic,
  Tag,
  Divider,
  Col,
  Row,
  Card,
  Typography,
  Tabs,
  Collapse,
  Button,
} from "antd"
import {
  RightOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import { formatStatus } from "../../utils/formatters"
import { NoOfferView } from "../cards"
import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"
import { getTheme } from "../../helpers/brand.helper"

const { Title, Text } = Typography
const { Panel } = Collapse
const { TabPane } = Tabs

function LeadView({ lead }) {
  const history = useHistory()
  const { merchantId } = useApply()
  const { themeColor } = useTheme()
  const theme = getTheme()

  const [panelKey, setPanelKey] = useState(null)

  function onClickApply(offer) {
    history.push(`/offers/${offer.id}`)
  }

  function managePanel(id) {
    console.log("panel ke", id)
    if (panelKey === id) {
      setPanelKey(null)
    } else {
      setPanelKey(id)
    }
  }

  const interestType = useCallback((offer) => {
    if (offer?.intro_term) {
      if (offer.originator.key === "fortiva") return "Deferred"
      if (offer.apr === "0%") {
        if (offer.originator.key === "citizens") return "Same As Cash"
        return "Deferred"
      }
    }
    return "Standard"
  }, [])

  const payment = useCallback((offer) => {
    if (offer?.payment) {
      if (offer.originator.key === "citi") return "-"
      return offer.payment
    }
    return "-"
  }, [])

  const offersTitle = useMemo(() => {
    if (merchantId === "trek" || theme.title === "citizens") {
      return `Let's Ride!`
    }
    return "Financing Offers"
  }, [merchantId, theme])

  const renderDetailCompany = useMemo(() => {
    if (merchantId === "trek" || theme.title === "citizens") {
      return "Citizen Support"
    }
    return "PayPossible Support"
  }, [merchantId, theme])

  const renderDetailPhone = useMemo(() => {
    if (merchantId === "trek" || theme.title === "citizens") {
      return "(877) 291-6391"
    }
    return "(631) 983-3181"
  }, [merchantId, theme])

  const renderDetailSupport = useMemo(() => {
    if (merchantId === "trek" || theme.title === "citizens") {
      return (
        <a target="_blank" rel="noreferrer" href="https://pay.citizensbank.com">
          https://pay.citizensbank.com
        </a>
      )
    }
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href="https://support.paypossible.com"
      >
        PayPossible Support
      </a>
    )
  }, [merchantId, theme])

  const introMonths = useCallback((term) => {
    return term.split("Months")[0].trim()
  }, [])

  const goToMonths = useCallback((offer) => {
    const introMonths = Number(offer?.intro_term.split("Months")[0])
    const totalMonths = Number(offer?.term.split("Months")[0])
    return totalMonths - introMonths
  }, [])

  function renderApp() {
    if (lead.status === "declined") {
      return <NoOfferView reapply={lead.reapply_countdown} lead={lead} />
    }
    if (lead.offers.length > 0) {
      const offers = Object.values(lead.offers).reverse()
      return (
        <div style={{ width: "100%" }}>
          <Title style={{ fontWeight: 600 }} level={4}>
            {offersTitle}&nbsp;
            <Tooltip
              title={
                "The financing options below are based upon the general criteria you provided on the initial application. Please note that all rates and terms are subject to change upon completing the application with the respective provider and that additional verification of the applicant's financial history will occur"
              }
            >
              <QuestionCircleOutlined
                style={{ fontSize: 12, position: "relative", top: -5 }}
              />
            </Tooltip>
          </Title>
          <Title style={{ fontWeight: 400 }} level={5}>
            Choose one of the available financing offers below to continue.
          </Title>

          {offers.map((offer, index) => {
            if (offer?.same_as_cash) {
              return (
                <Space
                  key={offer.id}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Card
                    key={offer.id}
                    onClick={() => onClickApply(offer)}
                    // onClick={() => managePanel(offer.id)}
                    bodyStyle={{
                      paddingBottom: 30,
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      paddingBottom: 0,
                      cursor: "pointer",
                    }}
                  >
                    <Row justify="space-between">
                      <Col style={{ color: themeColor }}>
                        <strong style={{ fontWeight: 800, fontSize: 24 }}>
                          {offer.amount}
                        </strong>
                      </Col>
                      <Col>
                        <Tag
                          style={{
                            color: "#ffffff",
                            borderRadius: 6,
                            borderColor: themeColor,
                            backgroundColor: themeColor,
                            fontSize: 14,
                            padding: 8,
                            fontWeight: 700,
                          }}
                        >
                          {offer.term
                            ? offer.term
                            : formatStatus(offer.loan_type)}
                        </Tag>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                      <Col span={5}>
                        <Statistic
                          title="Lender"
                          value={offer.originator.name}
                          valueStyle={{ fontSize: 14 }}
                        />
                      </Col>
                      <Col span={6} style={{ paddingRight: 5 }}>
                        <Statistic
                          title={"APR*"}
                          value={`${offer?.intro_apr} APR for ${introMonths(
                            offer?.intro_term
                          )}Months\nthen ${offer.apr} APR\nfor ${goToMonths(
                            offer
                          )}Months`}
                          valueStyle={{ fontSize: 14, whiteSpace: "pre-line" }}
                        />
                      </Col>
                      <Col span={5}>
                        <Statistic
                          title={"Interest Type"}
                          value={interestType(offer)}
                          valueStyle={{ fontSize: 14 }}
                        />
                      </Col>
                      <Col span={5}>
                        <Statistic
                          title="Est. Payment"
                          value={payment(offer)}
                          valueStyle={{ fontSize: 14, whiteSpace: "pre-line" }}
                        />
                      </Col>
                      <Col span={3} style={{ textAlign: "center" }}>
                        <RightOutlined
                          onClick={() => managePanel(offer.id)}
                          style={{
                            fontWeight: 1800,
                            fontSize: 18,
                            color: themeColor,
                          }}
                          rotate={offer.id === panelKey ? 90 : 0}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Space>
              )
            }

            return (
              <Space
                key={offer.id}
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <Card
                  key={offer.id}
                  onClick={() => onClickApply(offer)}
                  // onClick={() => managePanel(offer.id)}
                  bodyStyle={{
                    paddingBottom: 30,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    paddingBottom: 0,
                    cursor: "pointer",
                  }}
                >
                  <Row justify="space-between">
                    <Col style={{ color: themeColor }}>
                      <strong style={{ fontWeight: 800, fontSize: 24 }}>
                        {offer.amount}
                      </strong>{" "}
                      <strong />
                    </Col>
                    <Col>
                      {offer.originator.key === "fortiva" ? (
                        <Button
                          type="primary"
                          shape="default"
                          style={{ fontWeight: 700 }}
                          onClick={() => onClickApply(offer)}
                        >
                          View
                        </Button>
                      ) : (
                        <Tag
                          style={{
                            color: "#ffffff",
                            borderRadius: 6,
                            borderColor: themeColor,
                            backgroundColor: themeColor,
                            fontSize: 14,
                            padding: 8,
                            fontWeight: 700,
                          }}
                        >
                          {offer.term
                            ? offer.term
                            : formatStatus(offer.loan_type)}
                        </Tag>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 15 }} justify="middle">
                    <Col span={5}>
                      <Statistic
                        title="Lender"
                        value={offer.originator.name}
                        valueStyle={{ fontSize: 14 }}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title={
                          offer.originator.key === "fortiva"
                            ? "Purchase APR*"
                            : "APR*"
                        }
                        value={offer.apr ? offer.apr : "-"}
                        valueStyle={{ fontSize: 14 }}
                      />
                    </Col>
                    <Col span={5}>
                      <Statistic
                        title={
                          <>
                            {offer.originator.key === "fortiva"
                              ? "Promo Offer"
                              : "Interest Type"}
                            &nbsp;&nbsp;
                            {merchantId !== "trek" && (
                              <Tooltip
                                title={
                                  "Interest will be charged to your account from the purchase date if the promotional purchase is not paid in full within the promotional period. Minimum monthly payments are required. Required payments may or may not pay off your purchase before the end of the promotional period. To avoid paying the Deferred Interest Charges, you must pay the promotional purchase in full within the promotional period."
                                }
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            )}
                          </>
                        }
                        value={interestType(offer)}
                        valueStyle={{ fontSize: 14 }}
                      />
                    </Col>
                    <Col span={5}>
                      <Statistic
                        title="Est. Payment"
                        value={payment(offer)}
                        valueStyle={{ fontSize: 14 }}
                      />
                    </Col>
                    <Col span={3} style={{ textAlign: "center" }}>
                      <RightOutlined
                        onClick={() => managePanel(offer.id)}
                        style={{
                          fontWeight: 1800,
                          fontSize: 18,
                          color: themeColor,
                        }}
                        rotate={offer.id === panelKey ? 90 : 0}
                      />
                    </Col>
                  </Row>
                  {/* {true && (
                  <Collapse
                    style={{ margin: 0, padding: 0 }}
                    activeKey={panelKey}
                    ghost
                  >
                    <Panel
                      key={offer.id}
                      showArrow={false}
                      header={null}
                      style={{
                        height: offer.id === panelKey ? "100%" : "100%",
                      }}
                    >
                      <img
                        width="120px"
                        src={offer.originator.image_url}
                        alt="lender-logo"
                      />
                      <Row>
                        hi
                        <Button onClick={() => onClickApply(offer)}>
                          Apply
                        </Button>
                      </Row>
                    </Panel>
                  </Collapse>
                )} */}
                </Card>
              </Space>
            )
          })}
        </div>
      )
    }
    if (lead.status === "approved") {
      return <h1>approved bitch</h1>
    }
    return <Card />
  }

  function renderDetails() {
    return (
      <>
        <Title level={5}>Merchant</Title>
        <p>Name: {lead.merchant.name}</p>
        <p>Email: {lead.merchant.email ? lead.merchant.email.email : ""}</p>
        <Title level={5}>{renderDetailCompany}</Title>
        <p>Phone: {renderDetailPhone}</p>
        <p>Support: {renderDetailSupport}</p>
      </>
    )
  }

  function renderHelp() {
    return (
      <Collapse
        defaultActiveKey={["0"]}
        ghost
        expandIconPosition="start"
        collapsible={theme.title === "flexshopper" ? "disabled" : "header"}
      >
        <Panel
          header={
            <>
              <InfoCircleOutlined style={{ fontSize: 18 }} />
              &nbsp;&nbsp;
              <strong style={{ fontSize: 12 }}>More Information</strong>
            </>
          }
          key="1"
        >
          <Title level={5}>What is APR?</Title>
          <Text>
            The Annual Percentage Rate (APR) is the rate at which your loan
            accrues interest. It is based upon the amount of your loan, cost of
            the loan, term of the loan, repayment amounts and timing of payments
            and payoff. By law, the lender or lending partner must show you the
            APR before you enter into the loan. States have laws limiting the
            APR that the lender or lending partner can charge you. Rates will
            vary based on your credit, loan size and whether collateral is
            provided, with the lowest rates available to customers with good
            credit on larger, secured loans. Minimum and maximum loan amounts
            and APR may vary according to state law and lender or lending
            partner. We recommend you read the lender's and/or provider's terms
            and conditions in full before proceeding for a loan.
          </Text>
          <Title level={5}>Loan Example</Title>
          <Text>
            If you borrow $5,000 on a 36-month repayment term and at a 10% APR,
            the monthly repayment will be $161.34. Total repayment will be
            $5,808.24. Total interest paid will be $808.24.
          </Text>
          <Title level={5}>Disclaimer</Title>
          <Text>
            The financing options that appear on this page are from companies
            from which we and our partners receive compensation. This
            compensation may influence the selection, appearance, and order of
            appearance of the financing options listed. However, this
            compensation also facilitates the provision by us of certain
            services to you at no charge. The financing options shown do not
            include all financial services companies or all of their available
            product and service offerings.
          </Text>
        </Panel>
      </Collapse>
    )
  }

  return (
    <Card bodyStyle={{ margin: 0, padding: "1.2em" }}>
      <Row justify="center" style={{ textAlign: "center", paddingTop: "2rem" }}>
        <Title style={{ fontWeight: 700 }} level={3}>
          {lead.merchant.name}
        </Title>
      </Row>
      <Tabs
        defaultActiveKey="1"
        centered
        style={{ padding: 0, margin: 0, width: "100%" }}
      >
        <TabPane
          style={{ margin: 0, padding: 0 }}
          tab={<div style={{}}>Application</div>}
          key="1"
        >
          {renderApp()}
        </TabPane>
        <TabPane tab={<div style={{}}>Details</div>} key="2">
          {renderDetails()}
        </TabPane>
      </Tabs>
      <Divider />
      {lead.status !== "declined" && <Row>{renderHelp()}</Row>}
    </Card>
  )
}

export default LeadView

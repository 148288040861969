import { useState } from "react"
import { Button, Card, Col, Row } from "antd"
import { FileSearchOutlined } from "@ant-design/icons"
import { useTheme } from "../../hooks/useTheme"
import FrameModal from "../modals/FrameModal"

export default function LoanAction({ loan, approveLoan }) {
  const [showModal, setShowModal] = useState(false)
  const { colors, isMobile } = useTheme()

  console.log("um hello", loan)
  return (
    <>
      <FrameModal
        showModal={showModal}
        setShow={setShowModal}
        title={loan.items_requested}
        url={loan.stipulation_url}
      />
      <Card style={{ radius: 2 }}>
        <Row justify="space-between">
          <Col>
            <h2 style={{ fontWeight: "800" }}>Verification</h2>
          </Col>
          <Col />
        </Row>
        <Row justify="center" style={{ marginTop: 10 }}>
          <FileSearchOutlined
            style={{ color: colors.green, fontSize: isMobile ? 32 : 48 }}
          />
        </Row>
        <Row justify="center" style={{ marginTop: 10, textAlign: "center" }}>
          <p style={{ fontWeight: 150, fontSize: 18 }}>
            We need some more details to finalize your account.
          </p>
        </Row>
        <Row justify="center" style={{ marginTop: 10, textAlign: "center" }}>
          <Button
            onClick={() => setShowModal(true)}
            type="primary"
            size="large"
            shape="default"
          >
            Continue
          </Button>
        </Row>
      </Card>
    </>
  )
}

import React, { useCallback, useEffect, useState } from "react"
import { Card, Row, Button, Alert } from "antd"

import { initialize } from "@chargeafter/payment-sdk"
import { useHistory } from "react-router-dom"

import { Spinner } from "../core/Spinner"
import {
  getCitiSdkParams,
  validateCitiSdkAuth,
  checkOutCitiSdk,
} from "../../services/offer.service"
import { fetchLead } from "../../services/lead.service"
import { useApply } from "../../hooks/useApply"

const CitiOffer = ({ offer }) => {
  const history = useHistory()
  const { lead } = useApply()

  const [errorStaus, setErrorStatus] = useState("")
  const [loading, setLoading] = useState(false)

  const goLead = () => {
    history.push(`/apps/${offer.lead.id}`)
  }

  const handleAuth = useCallback(async () => {
    setErrorStatus("")
    const response = await getCitiSdkParams(offer.id)
    if (response.success) {
      const { api_key, merchant_id, lender_id, session_token, test_mode } =
        response.data
      const cfg = {
        apiKey: api_key,
        delegatedMerchantId: merchant_id,
        preferences: {
          language: "en",
          currency: "USD",
        },
      }

      try {
        const caObject = await initialize(
          cfg,
          test_mode ? "sandbox" : "production"
        )
        caObject.payments.present("checkout", {
          callback: async (token, data, status) => {
            await validateCitiSdkAuth(offer.id, data, status)
            if (
              status.code === "AUTHENTICATION_COMPLETED" ||
              status.code === "AUTHENTICATION_SUCCEEDED"
            ) {
              goLead()
            } else {
              setErrorStatus(status.message)
            }
          },
          intent: {
            lenderId: lender_id,
            type: "Authenticate",
          },
          sessionToken: session_token,
        })
      } catch (ex) {
        setErrorStatus(ex.message)
      }
    }
    //eslint-disable-next-line
  }, [offer])

  const redirectToLoan = useCallback(async () => {
    let leadResponse = await fetchLead(offer.lead.id)

    if (leadResponse.success) {
      if (leadResponse.data.loans.length === 0) {
        history.push(`/leads/${leadResponse.lead.id}`)
      } else if (leadResponse.data.loans.length === 1) {
        history.push(`/loans/${leadResponse.data.loans[0].id}`)
      } else {
        history.push(`/loans`)
      }
    }
  }, [history, offer])

  const handleAcceptOffer = useCallback(async () => {
    setLoading(true)
    const response = await getCitiSdkParams(offer.id)
    if (response.success) {
      const { api_key, merchant_id, session_token, test_mode } = response.data
      const cfg = {
        apiKey: api_key,
        delegatedMerchantId: merchant_id,
        preferences: {
          language: "en",
          currency: "USD",
        },
      }
      try {
        const caObject = await initialize(
          cfg,
          test_mode ? "sandbox" : "production"
        )
        caObject.payments.present("checkout", {
          callback: async (token, data, status) => {
            await checkOutCitiSdk(offer.id, status, data, token)
            if (!status || status.code === "PENDING") {
              redirectToLoan()
            } else {
              setErrorStatus(status.message)
            }
          },
          sessionToken: session_token,
        })
      } catch (ex) {
        setErrorStatus(ex.message)
      } finally {
        setLoading(false)
      }
    }
  }, [offer, redirectToLoan])

  useEffect(() => {
    if (offer.status === "applied") {
      redirectToLoan()
    }
  }, [offer, redirectToLoan])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      {errorStaus && (
        <Alert
          message={errorStaus}
          type="error"
          showIcon
          closable
          style={{ marginBottom: 7, width: "100%", textAlign: "left" }}
        />
      )}
      <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
        <Row justify="center">
          <img src={offer.originator.image_url} alt="lender-logo" height={60} />
        </Row>

        {offer.status === "existing" && (
          <>
            <Row justify="center">
              <span
                style={{
                  marginTop: 50,
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                It looks like you have an existing account with CitiPay
              </span>
            </Row>
            <Row justify="center">
              <span
                style={{
                  marginTop: 10,
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Login to see offers
              </span>
            </Row>

            <Row justify="center" style={{ marginTop: 40 }}>
              <Button type="primary" onClick={handleAuth}>
                Login
              </Button>
            </Row>
          </>
        )}
        {offer.status === "offered" && (
          <>
            <Row justify="center">
              <p style={{ fontSize: 20, margin: 0, fontWeight: 300 }}>
                Pre-Qualified for
              </p>
            </Row>

            <div style={{ textAlign: "center", margin: "0", padding: "0" }}>
              <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>
                {offer.max_amount_offered}
              </p>
              <p style={{ fontSize: 20, margin: 0, fontWeight: 400 }}>
                credit limit
              </p>
            </div>

            <Row
              justify="left"
              style={{
                maxWidth: 400,
                width: "100%",
                margin: "20px auto",
                textAlign: "left",
                fontSize: 13,
                fontWeight: 400,
              }}
            >
              <div style={{ marginBottom: 15 }}>
                <b>
                  Congratulations, {lead?.personal?.first_name || ""}{" "}
                  {lead?.personal?.last_name || ""}!
                </b>
              </div>
              <div style={{ marginBottom: 7 }}>
                You’re pre-qualified for a Citi Pay® Credit account with a
                credit limit up to {offer.amount} to use towards your purchase
                of ${offer.lead.amount}. To continue, click Next to review and
                accept the terms and submit your application.
              </div>
              <div style={{ marginBottom: 7 }}>
                Application approval is subject to final review.
              </div>
              <div style={{ marginBottom: 7 }}>
                You authorize Citibank, N.A. (1) to disclose your name, Social
                Security Number, and date of birth to Experian to verify if the
                information you submit matches Social Security Administration
                records as described in the Terms and Conditions of this Offer
                and (2) to use this information to check your credit history
                with a national credit bureau when processing your application.
              </div>
              <div style={{ marginBottom: 7 }}>
                By providing your email address, Citibank, N.A. and other
                financing providers may use it to contact you about your account
                and tell you about useful products and services.
              </div>
              <div style={{ marginBottom: 7 }}>
                By providing your phone number, you agree that Citibank, N.A. or
                its service providers and other financing providers can contact
                you at the number by text message, artificial voice,
                pre-recorded or autodialed calls. Your phone plan charges may
                apply.
              </div>
            </Row>

            <Row justify="center">
              <Button type="primary" onClick={handleAcceptOffer}>
                Next
              </Button>
            </Row>
          </>
        )}
      </Card>
    </>
  )
}
export default CitiOffer

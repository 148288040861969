import React, { Fragment } from "react"

class SEON extends React.Component {
  constructor(props) {
    super(props)

    this.initAgent = this.initAgent.bind(this)
    this.addAgentScript = this.addAgentScript.bind(this)
    this.insertScript = this.insertScript.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined" && !window.seon) {
      this.addAgentScript(this.initAgent)
    }
  }

  initAgent() {
    const { sessionId } = this.props
    const { seon } = window
    seon &&
      seon.config({
        host: "seondf.com",
        session_id: sessionId,
        audio_fingerprint: true,
        canvas_fingerprint: true,
        webgl_fingerprint: true,
        onSuccess: function (message) {
          console.debug("SEON success", message)
          seon.getBase64Session(function (data) {
            if (data) {
              console.debug("SEON Session payload", data)
              sessionStorage.setItem("seonSessionPayload", data)
            } else {
              console.debug("SEON Failed to retrieve session data.")
            }
          })
        },
        onError: function (message) {
          console.debug("SEON error", message)
        },
      })
  }

  addAgentScript(callback) {
    this.insertScript("https://cdn.seondf.com/js/v5/agent.js", callback)
  }

  insertScript(src, callback) {
    const script = document.createElement("script")
    script.src = src
    script.type = "text/javascript"
    script.async = true
    if (callback) {
      script.onload = callback
      script.onreadystatechange = callback
    }
    document.body.appendChild(script)
  }

  render() {
    return <></>
  }
}

export default SEON

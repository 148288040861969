import { Typography, Modal, Row } from "antd"

const { Text } = Typography

function OrderProcessingModal({ visible, onClose, logo }) {
  return (
    <Modal
      visible={visible}
      title={
        <Row justify="center">
          <img src={logo} height={40} alt="lender-logo" />
        </Row>
      }
      onOk={onClose}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Row>
        <Text style={{ marginBottom: 20 }}>
          Congratulations on your new Citizens Pay Line of Credit! Now, it's
          time to ride. Keep an eye on your inbox for details on how to:
        </Text>
        <ul>
          <li>
            <Text>See transactions</Text>
          </li>
          <li>
            <Text>Make payments</Text>
          </li>
          <li>
            <Text>View statements</Text>
          </li>
          <li>
            <Text>Edit your information</Text>
          </li>
        </ul>
      </Row>
    </Modal>
  )
}

export default OrderProcessingModal

import axios from "axios"
import * as Sentry from "@sentry/react"

import { API_URL } from "../utils/constants"

export const createCarts = async (items) => {
  try {
    const response = await axios.post(`${API_URL}/v1/carts/`, { items })
    return { success: true, data: response.data }
  } catch (err) {
    console.error("Error creating carts:", err, err?.response)
    Sentry.captureException(err)
    return { success: false, error: err.message }
  }
}

const BRANDS = {
  peakpb: {
    img: "/logos/peakpb.jpg",
    color: "#003E8C",
  },
  "lrx-prequalified": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-mesa": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-coloradosprings": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-chicago": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-hoffman": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-naperville": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-orlandpark": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-indianapolis": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-louisville": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-towson": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-birmingham": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-canton": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-dearborn": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-grandblanc": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-novi": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-okemos": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-troy": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-stlouis": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-charlotte": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-summerlin": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-pittsburgh": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-greenville": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-knoxville": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-stoneoak": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-alexandria": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  "lrx-virginiabeach": {
    img: "/logos/lightRx.png",
    color: "#CB2C38",
  },
  recoverycircle: {
    img: "/logos/recoverycircle.png",
    color: "#81C433",
  },
  mindfulhabitcoaching: {
    img: "/logos/mindfulhabitcoaching.png",
    color: "#A9162D",
  },
  weldscholarsllc: {
    img: "/logos/weldscholars.png",
    color: "#F2D080",
  },
  advanceyourreach: {
    img: "/logos/ayr.png",
    color: "#00CA8B",
  },
  rpuri: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  rokfi: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  susan_njie: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  brian_voros: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  sandra_gurol: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  optimal_b_enterprises: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  "optimal-b-enterprises": {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  dpascucci: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  cmanter: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  jdvalerio: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  gryerson: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  cohesia_consulting: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  "cohesia-consulting": {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  "rokfi-services": {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  hmcbride: {
    img: "/logos/rok.png",
    color: "#FF002A",
  },
  weldscholars: {
    img: "/logos/weldscholars.png",
    color: "#F2D080",
  },
  ecells: {
    img: "/logos/ecells.jpg",
    color: "#FF341C",
  },
  "rij-perkins": {
    img: "/logos/rij.jpg",
    color: "#F0C78F",
  },
  "rij-bartlett": {
    img: "/logos/rij.jpg",
    color: "#F0C78F",
  },
  "rij-southaven": {
    img: "/logos/rij.jpg",
    color: "#F0C78F",
  },
  "rij-wolfchase": {
    img: "/logos/rij.jpg",
    color: "#F0C78F",
  },
  "rij-littlerock": {
    img: "/logos/rij.jpg",
    color: "#F0C78F",
  },
  springpools: {
    img: "/logos/springpools.png",
    color: "#00AAE7",
  },
  absoluteairflow: {
    img: "/logos/absoluteairflow.png",
    color: "#B6001F",
  },
  xfinitymoving: {
    img: "/logos/xfinitymoving.jpg",
    color: "#00CCD8",
  },
  customcoach: {
    img: "/logos/customcoach.jpg",
    color: "#89BF47",
  },
  blocklegal: {
    img: "/logos/blocklegal.png",
    color: "#212B27",
  },
  myphotos: {
    img: "/logos/myphotos.jpg",
    color: "#2F2F2F",
  },
  nbc: {
    img: "/logos/nbc.png",
    color: "#20346B",
  },
  nbcservices: {
    img: "/logos/nbc.png",
    color: "#20346B",
  },
  sfsbi: {
    img: "/logos/sfsbi.png",
    color: "#00A8DA",
  },
  bosstore: {
    img: "/logos/bosstore.png",
    color: "#000000",
  },
  incredihome: {
    img: "/logos/incredihome.png",
    color: "#001B81",
  },
  parkoutdoorfurn: {
    img: "/logos/parkoutdoorfurn.png",
    color: "#00AAE7",
  },
  writechoiceconsult: {
    img: "/logos/writechoiceconsult.png",
    color: "#DC7A33",
  },
  elegantoccasions: {
    img: "/logos/elegantoccasions.jpg",
    color: "#206BB7",
  },
  lachri: {
    img: "/logos/elegantoccasions.jpg",
    color: "#206BB7",
  },
  diamondsolutions360: {
    img: "/logos/diamondsolutions360.png",
    color: "#0090A0",
  },
  connellyphoto: {
    img: "/logos/connellyphoto.jpg",
    color: "#3F0009",
  },
  medevatehealth: {
    img: "/logos/medevatehealth.jpg",
    color: "#00597A",
  },
  trinityproximity: {
    img: "/logos/trinityproximity.png",
    color: "#0064A5",
  },
  everywhere: {
    img: "/logos/everywhere.png",
    color: "#000000",
  },
  budgetblinds: {
    img: "/logos/budgetblinds.jpg",
    color: "#0F737E",
  },
  lecaprice: {
    img: "/logos/lecaprice.png",
    color: "#2D181F",
  },
  transmedcare: {
    img: "/logos/transmedcare.png",
    color: "#9D001E",
  },
  abcsolutions: {
    img: "/logos/abcsolutions.jpg",
    color: "#008EA3",
  },
  kingwheel1: {
    img: "/logos/kingwheel.png",
    color: "#008623",
  },
  kingwheel2: {
    img: "/logos/kingwheel.png",
    color: "#008623",
  },
  kingwheel3: {
    img: "/logos/kingwheel.png",
    color: "#008623",
  },
  kingwheel4: {
    img: "/logos/kingwheel.png",
    color: "#008623",
  },
  kingwheel5: {
    img: "/logos/kingwheel.png",
    color: "#008623",
  },
  kingwheel7: {
    img: "/logos/kingwheel.png",
    color: "#008623",
  },
  fitnessgrind: {
    img: "/logos/fitnessgrind.png",
    color: "#FFD33A",
  },
  justicetax: {
    img: "/logos/justicetax.png",
    color: "#FDAE63",
  },
  inspired: {
    img: "/logos/inspired.jpg",
    color: "#3E8483",
  },
  wdcarroll: {
    img: "/logos/inspired.jpg",
    color: "#3E8483",
  },
  kiser: {
    img: "/logos/kiser.png",
    color: "#07213D",
  },
  innate: {
    img: "/logos/innate.png",
    color: "#00A1E9",
  },
  bizcoach: {
    img: "/logos/bizcoach.png",
    color: "#DDA254",
  },
  flpaver: {
    img: "/logos/flpaver.jpg",
    color: "#278D4A",
  },
  getfinancingtest: {
    img: "/logos/flpaver.jpg",
    color: "#278D4A",
  },
  qualitycars: {
    img: "/logos/qualitycars.png",
    color: "#ED0024",
  },
  curated: {
    img: "/logos/curated.jpg",
    color: "#191919",
  },
  ingaugelabs: {
    img: "/logos/curated.jpg",
    color: "#191919",
  },
  aurora: {
    img: "/logos/aurora.png",
    color: "#2E6AA5",
  },
  garnerfinancial: {
    img: "/logos/garnerfinancial.png",
    color: "#90C163",
  },
  "valliani-arden": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-victorville": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-plazabonita": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-inland": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-livermore": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-oakridge": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-valleyplaza": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-fashionfair": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-valleyfair": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-sherwood": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-serramonte": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-sunvalley": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-northridge": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-newpark": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-vintagefaire": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-tanforan": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-eastridge": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-westfield": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-visalia": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-culvercity": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-southland": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-westvalley": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-greatmall": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-santaanita": {
    img: "/logos/valliani.jpeg",
    color: "#000000",
  },
  "valliani-solano": {
    img: "/logos/valliani-solano.png",
    color: "#000000",
  },
  "scc-demo": {
    img: "/logos/scc.png",
    color: "#C357BA",
  },
  brizee: {
    img: "/logos/brizeeLogo.png",
    color: "#C12B29",
  },
  keylifesolutions: {
    img: "/logos/keylifesolutions.png",
    color: "#1E5F93",
  },
  echelonway: {
    img: "/logos/echelonway.png",
    color: "#162E8A",
  },
  "161powersports": {
    img: "/logos/161powersports.png",
    color: "#C4413C",
  },
  pioneerpowersports: {
    img: "/logos/pioneerpowersports.png",
    color: "#C4413C",
  },
  "boost-1": {
    img: "/logos/boost-logo.png",
    color: "#f79323",
  },
  ace: {
    img: "/logos/ace.png",
    color: "#C51B38",
  },
  trek: {
    img: "/logos/trek.png",
    dark: "undefined",
    color: "#3A8259",
    themeColor: "#3A8259",
    title: "citizens",
    label: "Citizens Pay",
  },
  tireagent: {
    color: "#194a6c",
    themeColor: "#194a6c",
    logo: "/logos/ownlease-logo.png",
    img: "/logos/ownlease-logo.png",
    dark: "undefined",
  },
  "valliani-ontario": {
    img: "/logos/valliani.png",
    color: "#000000",
  },
  "hannoush-southshore": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-universitymall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-florencemall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-cincinnati-colerain": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-cincinnati-eastgate": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-libertycenter": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-sangertown": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-pittsfield": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-rutland": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-eastview": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-rayham": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-destiny": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-holyoke": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-swansea": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-westgate": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-chicopee": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-auburnmall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-emeraldsquare": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-solomonpond": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-daytonmall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-eastfield": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-dover": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-foxrun": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-pheasantlane": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-salem": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-northshore": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-springfield": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-squareone": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-mainemall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-midriversmall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-waterford": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-northampton": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "hannoush-hampshiremall": {
    img: "/logos/hannoush.png",
    color: "#000000",
  },
  "tx-powersports": {
    img: "/logos/tx-powersports.png",
    color: "#CB302F",
  },
  "diamondsforever-solano": {
    img: "/logos/diamondsforever.png",
    color: "#000000",
  },
  "diamondsforever-eastridge": {
    img: "/logos/diamondsforever.png",
    color: "#000000",
  },
  "diamondsforever-northridge": {
    img: "/logos/diamondsforever.png",
    color: "#000000",
  },
  "apexjewelers-solano": {
    img: "/logos/apexjewelers.png",
    color: "#000000",
  },
  "apexjewelers-eastridge": {
    img: "/logos/apexjewelers.png",
    color: "#000000",
  },
  "nuovo-houston": {
    img: "/logos/nuovo.png",
    color: "#000000",
  },
  "nuovo-miami": {
    img: "/logos/nuovo.png",
    color: "#000000",
  },
  "nuovo-dallas": {
    img: "/logos/nuovo.png",
    color: "#000000",
  },
  emattressoutlet: {
    img: "/logos/emattressoutlet.png",
    color: "#194a6c",
  },
  wefund: {
    img: "/logos/wefund.png",
    color: "#000000",
  },
  flexshopper: {
    color: "#3A73D4",
    themeColor: "#3A73D4",
    logo: "/logos/flexshopper.png",
    img: "/logos/flexshopper.png",
    dark: "undefined",
  },
}

export default BRANDS

import { Col, Row } from "antd"
import { Form } from "formik-antd"
import React from "react"
import NumericInput from "./NumericInput"

function BirthdayInput(props) {
  return (
    <Form.Item
      name="dob"
      style={{ marginBottom: 0 }}
      tooltip="You must 18 or older to be eligible."
      label="Birthday"
    >
      <Row align="middle" justify="center">
        <Col span={7}>
          <NumericInput
            name="bday_month"
            length={2}
            max={12}
            min={1}
            hasFeedback
            placeholder="MM"
            required={false}
            label="Month"
            showValidateSuccess={false}
            formik={props.formik}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <NumericInput
            name="bday_day"
            hasFeedback
            length={2}
            max={31}
            min={1}
            placeholder="DD"
            required={false}
            label="Day"
            showValidateSuccess={false}
            formik={props.formik}
          />
        </Col>
        <Col span={1} />
        <Col span={8}>
          <NumericInput
            name="bday_year"
            hasFeedback
            max={new Date().getFullYear() - 17}
            min={new Date().getFullYear() - 100}
            length={4}
            placeholder="YYYY"
            required={false}
            label="Year"
            showValidateSuccess={false}
            formik={props.formik}
          />
        </Col>
      </Row>
    </Form.Item>
  )
}
export default BirthdayInput

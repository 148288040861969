import React, { useState } from "react"
import { Form, Input } from "formik-antd"
import moment from "moment"

const formatDate = (value, format) => {
  const digits = value.replace(/\D/g, "")
  let formatted = ""

  switch (format) {
    case "YYYY-MM-DD":
      if (digits.length <= 4) {
        formatted = digits
      } else if (digits.length <= 6) {
        formatted = `${digits.slice(0, 4)}-${digits.slice(4)}`
      } else {
        formatted = `${digits.slice(0, 4)}-${digits.slice(4, 6)}-${digits.slice(
          6,
          8
        )}`
      }
      break

    case "MM/DD/YYYY":
      if (digits.length <= 2) {
        formatted = digits
      } else if (digits.length <= 4) {
        formatted = `${digits.slice(0, 2)}/${digits.slice(2)}`
      } else {
        formatted = `${digits.slice(0, 2)}/${digits.slice(2, 4)}/${digits.slice(
          4,
          8
        )}`
      }
      break

    default:
      formatted = digits
  }

  return formatted
}

const validateDate = (value) => {
  if (value.length < 10) return "Please enter a valid date."
  const date = moment(value, ["YYYY-MM-DD", "MM/DD/YYYY"], true)
  if (!date.isValid()) return "Please enter a valid date."

  const now = moment()
  const difference = now.diff(date, "years")

  if (difference > 100 || difference < 0) return "Please enter a valid date."

  return undefined
}

const validateBirthday = (value) => {
  const basicValidation = validateDate(value)
  if (typeof basicValidation === "string") return basicValidation

  const birthday = moment(value, ["YYYY-MM-DD", "MM/DD/YYYY"], true)
  const now = moment()
  const difference = now.diff(birthday, "years")

  if (difference > 100) return "Please enter a valid date."
  if (difference < 18)
    return "You must be at least 18 years old to be eligible."

  return undefined
}

const validateFuture = (value) => {
  const basicValidation = validateDate(value)
  if (typeof basicValidation === "string") return basicValidation

  const date = moment(value, ["YYYY-MM-DD", "MM/DD/YYYY"], true)
  if (date.isAfter()) return undefined

  return "Please enter a date in the future"
}

const validatePast = (value) => {
  const basicValidation = validateDate(value)
  if (typeof basicValidation === "string") return basicValidation

  const date = moment(value, ["YYYY-MM-DD", "MM/DD/YYYY"], true)
  if (date.isBefore()) return undefined

  return "Please enter a date in the past"
}

const DateTextInput = ({
  name,
  label,
  tooltip,
  placeholder,
  formik,
  isBirthday,
  validator,
  autoTooltip,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleFocus = () => {
    setIsTooltipVisible(true)
  }
  const handleBlur = () => {
    setIsTooltipVisible(false)
  }

  let validateFn = validateDate

  if (isBirthday) validateFn = validateBirthday
  if (validator === "future") validateFn = validateFuture
  if (validator === "past") validateFn = validatePast

  return (
    <Form.Item
      name={name}
      label={label}
      tooltip={{
        title: tooltip,
        visible: autoTooltip ? isTooltipVisible : undefined,
      }}
      validate={validateFn}
    >
      <Input
        name={name}
        placeholder={placeholder}
        size="large"
        inputMode="numeric"
        onChange={(e) => {
          const formattedValue = formatDate(e.target.value, placeholder)
          formik.setFieldValue(name, formattedValue)
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Form.Item>
  )
}

export default DateTextInput

import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Alert, Card, Typography, notification, Segmented, Row } from "antd"

import { useTheme } from "../../hooks/useTheme"
import LoginForm from "../inputs/LoginForm"
import { useAuth } from "../../hooks/useAuth"
import OtpForm from "../forms/OtpForm"
import PoweredBy from "../core/PoweredBy"

const { Title } = Typography

function LoginComponent() {
  const { poweredByLogo } = useTheme()
  const [loginType, setLoginType] = useState("Email")
  const [otpToken, setOtpToken] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [errors, setErrors] = useState(null)
  const history = useHistory()
  const location = useLocation()

  const { from } = location.state || { from: { pathname: "/" } }

  const { login, getOtp, verifyOtp } = useAuth()

  function authChange(value) {
    setLoginType(value)
  }

  async function doVerifyOtp(values, actions) {
    actions.setSubmitting(true)
    setShowSuccess(false)
    setErrors(null)
    const payload = {}
    payload.code = values.identifier
    const response = await verifyOtp(otpToken, payload)
    actions.setSubmitting(false)
    if (response.success) {
      history.replace(from)
    } else {
      const { data } = response.error.response
      const key = Object.keys(data)[0]
      setErrors(data[key])
    }
  }

  async function onSubmitOtp(values, actions) {
    actions.setSubmitting(true)
    setErrors(null)
    const payload = {}
    payload[loginType.toLowerCase()] = values.identifier
    const response = await getOtp(payload)
    if (response.success) {
      setShowSuccess(true)
      setOtpToken(response.data.id)
    } else if (response.error.response.status === 500) {
      notification.error({
        message: "Server Error",
        description:
          "There was an error processing your request, please try again",
        duration: 5,
      })
    } else {
      const { data } = response.error.response
      const key = Object.keys(data)[0]
      setErrors(data[key])
    }
    actions.setSubmitting(false)
  }

  async function onSubmit(values, actions) {
    const response = await login(values.email, values.password)
    actions.setSubmitting(false)
    if (response.success) {
      console.log("okay so i'm logged in, what is the path", from)
      history.replace(from)
    } else {
      actions.resetForm({
        values: {
          email: values.email,
          password: "",
        },
      })
      const { data } = response?.error?.response
      const key = Object.keys(data)[0]
      console.log("his is", key)
      setErrors(data[key])
    }
  }

  return (
    <>
      {showSuccess && (
        <Alert
          message={`Success, your One Time Passcode was sent to your ${loginType.toLowerCase()}`}
          type="success"
          showIcon
          closable
          style={{ marginBottom: 10 }}
        />
      )}
      {errors && (
        <Alert
          message={errors}
          type="error"
          showIcon
          closable
          style={{ marginTop: 0, marginBottom: 10 }}
        />
      )}
      <Card style={{ paddingBottom: "1em" }}>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ margin: "1em" }}
        >
          <Title level={4}>Account Login</Title>
        </Row>
        <Row justify="center" style={{ margin: "1em" }}>
          <Segmented
            defaultValue={loginType}
            onChange={authChange}
            options={["Email", "Phone", "Password"]}
          />
        </Row>
        <div style={{ marginBottom: "1em" }}>
          {loginType === "Password" && <LoginForm onSubmit={onSubmit} />}
          {!otpToken && loginType === "Email" && (
            <OtpForm onSubmit={onSubmitOtp} mode="email" submitText="Submit" />
          )}
          {!otpToken && loginType === "Phone" && (
            <OtpForm onSubmit={onSubmitOtp} mode="phone" submitText="Submit" />
          )}
          {otpToken && (
            <OtpForm
              onSubmit={doVerifyOtp}
              mode={loginType}
              submitText="Verify OTP"
              otpId={otpToken}
            />
          )}
        </div>
        {poweredByLogo && <PoweredBy />}
      </Card>
    </>
  )
}
export default LoginComponent

import { Card } from "antd"
import Compliance from "../../lang/compliance.json"

export default function AffFinwiseOffer({ offer, user, acceptButton }) {
  const language = Compliance[offer.originator.key][offer.loan_type]

  return (
    <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
      <img src={offer.originator.image_url} alt="lender-logo" />
      <br />
      <br />
      <p style={{ fontSize: 22, fontWeight: 250 }}>
        {language.prequal_message} {offer.originator.name}
      </p>
      <p style={{ fontSize: 20, fontWeight: 250 }}>{language.type_label}</p>
      <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>{offer.amount}</p>

      <br />
      {language.apr_label ? (
        <div>
          <b>{language.apr_label}</b>&nbsp;&nbsp;{offer.apr}
        </div>
      ) : null}
      {language.term_label ? (
        <div>
          <b>{language.term_label}</b>&nbsp;&nbsp;{offer.term}
        </div>
      ) : null}
      {language.payment_label ? (
        <div>
          <b>{language.payment_label}</b>&nbsp;&nbsp;{offer.monthly_payment}
        </div>
      ) : null}

      <br />
      {acceptButton}

      {language.prequal_notice ? (
        <p style={{ marginTop: 15, fontStyle: "italic", fontSize: 11 }}>
          {language.prequal_notice}
        </p>
      ) : null}
      {language.prequal_disclaimer ? (
        <p style={{ marginTop: 15, fontStyle: "italic", fontSize: 11 }}>
          {language.prequal_disclaimer}
        </p>
      ) : null}
    </Card>
  )
}

export const creditScore = [
  { value: "excellent", option: "Excellent (720+)" },
  { value: "good", option: "Good (660-719)" },
  { value: "fair", option: "Fair (600-659)" },
  { value: "poor", option: "Poor (Below 600)" },
]

export const employmentStatus = [
  { value: "employed", option: "Employed" },
  { value: "retired", option: "Retired" },
  { value: "self_employed", option: "Self Employed" },
  { value: "not_employed", option: "Not Employed" },
  { value: "other", option: "Other" },
]

export const payFrequency = [
  { value: "weekly", option: "Weekly" },
  { value: "biweekly", option: "Every Two Weeks" },
  { value: "monthly", option: "Monthly" },
  { value: "twice_monthly", option: "Twice A Month" },
  { value: "other", option: "Other" },
]

export const payMethod = [
  { value: "direct_deposit", option: "Direct Deposit" },
  { value: "check", option: "Check" },
  { value: "cash", option: "Cash" },
]

export const loanPurpose = [
  { value: "auto", option: "Auto" },
  { value: "baby", option: "Baby" },
  { value: "boat", option: "Boat" },
  { value: "business", option: "Business" },
  { value: "debt_consolidation", option: "Debt Consolidation" },
  { value: "green", option: "Green Loan" },
  { value: "home_improvement", option: "Home Improvement" },
  { value: "household_expenses", option: "Household Expenses" },
  { value: "large_purchases", option: "Large Purchase" },
  { value: "medical_dental", option: "Medical/Dental" },
  { value: "moving_relocation", option: "Moving/Relocation" },
  { value: "student_loan", option: "Student Loan" },
  { value: "taxes", option: "Taxes" },
  { value: "vacation", option: "Vacation" },
  { value: "wedding", option: "Wedding/Special Occasion" },
  { value: "other", option: "Other" },
]

export const housingStatus = [
  { value: "rent", option: "Rent" },
  { value: "own_outright", option: "Own" },
  { value: "own_with_mortgage", option: "Own with Mortgage" },
]

export const timeAtAddress = [
  { value: 8, option: "Less Than 1 Year" },
  { value: 36, option: "1-3 Years" },
  { value: 60, option: "3-5 Years" },
  { value: 84, option: "More Than 5 Years" },
]

import { Row } from "antd"
import DetailCard from "../cards/DetailCard"
import LoanBalance from "./LoanBalance"
import { moneyFormat } from "../../utils/formatters"

export default function LoanSummary({ loan }) {
  return (
    <DetailCard
      headingId="LoanSummary.heading"
      secondaryHeading={
        <img alt="lender-logo" src={loan.originator.image_url} />
      }
      firstStatId="LoanSummary.merchantStat"
      firstStatValue={loan.merchant.name}
      secondStatId="LoanSummary.amountStat"
      secondStatValue={`${moneyFormat(loan.approved_amount.split(".")[0])}`}
    >
      <Row style={{ paddingTop: 20 }}>
        <LoanBalance loan={loan} />
      </Row>
    </DetailCard>
  )
}

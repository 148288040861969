import React from "react"
import { Row, Spin } from "antd"

export function Spinner() {
  return (
    <Row justify="center">
      <div style={{ paddingTop: 100 }}>
        <Spin size="large" />
      </div>
    </Row>
  )
}

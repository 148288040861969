import React from "react"
import { Select, Form } from "formik-antd"
import { payFrequency } from "../../utils/values"

export default function PayrollFrequencySelect(props) {
  return (
    <Form.Item
      name="income.payroll_frequency"
      hasFeedback
      required={false}
      label="Payroll Frequency"
    >
      <Select
        name="income.payroll_frequency"
        mode="single"
        size="large"
        style={{ textAlign: "left" }}
        placeholder="Payroll Frequency"
      >
        {payFrequency.map((f) => (
          <Select.Option key={f.value}>{f.option}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

import { Steps } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const { Step } = Steps

export default function OrderStatus({ step, loan }) {
  return (
    <Steps current={step} style={{ width: "100%" }} direction="vertical">
      <Step
        title="Activate Account"
        description="Finalize & activate your account"
      />
      <Step
        title="Create Order"
        icon={step === 1 ? <LoadingOutlined /> : null}
        description="Waiting for merchant to create order"
      />
      <Step
        title="Send Order"
        icon={step === 2 ? <LoadingOutlined /> : null}
        description="Waiting for merchant to send order for approval"
      />
      <Step
        title="Approve Order"
        icon={step === 3 ? <LoadingOutlined /> : null}
        description="Review and approve your order"
      />
      {loan && loan.originator.key === "finwise" && (
        <Step
          title="Sign Contract"
          icon={step === 4 ? <LoadingOutlined /> : null}
          description="E-Sign loan documents"
        />
      )}
    </Steps>
  )
}

import Tracker from "@openreplay/tracker"

let tracker = null

export function startTrackerSession(projectKey) {
  tracker = new Tracker({ projectKey })
  tracker.start()
}

export function getTrackerSession() {
  return tracker
}

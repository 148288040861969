import { Modal, Row, Typography } from "antd"

const { Text } = Typography

function LoanApprovedModal({ loan, visible, close }) {
  return (
    <Modal
      visible={visible}
      closable={true}
      centered
      title={
        <Row justify="center" style={{ marginTop: 10 }}>
          <img src={loan.originator.image_url} alt="lender-logo" width={120} />
        </Row>
      }
      onCancel={close}
      footer={null}
    >
      <Text style={{ textAlign: "center", fontSize: 15, lineHeight: 2 }}>
        Congratulations, {loan?.user?.first_name || ""} you're approved!
        <br />
        Your new Concora™ Credit Account Number ends in ...
        {loan?.reference_id?.substring(
          loan?.reference_id.length - 4,
          loan?.reference_id.length
        )}
      </Text>
    </Modal>
  )
}

export default LoanApprovedModal

import React from "react"
import { Result, Button } from "antd"

export default function PageNotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={() => window.location.replace("/")} type="primary">
          Back Home
        </Button>
      }
    />
  )
}

import { Modal } from "antd"

function DocumentModal({ visible, onCancel, doc }) {
  if (doc === null) return null
  return (
    <Modal visible={visible} footer={null} onCancel={onCancel} width="100vw">
      {doc.text !== null && (
        <iframe
          style={{ padding: 20 }}
          title="Offer"
          height="700"
          frameBorder="0"
          width="100%"
          srcDoc={`<html style="font-family: 'Segoe UI', Roboto, 'Helvetica Neue';">${doc.text}</html>`}
        />
      )}
    </Modal>
  )
}

export default DocumentModal

import { Statistic, Button, Spin, Card, Row, Col } from "antd"
import {
  LoadingOutlined,
  FileDoneOutlined,
  ShoppingCartOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"
import { FormattedMessage } from "react-intl"
import { useTheme } from "../../hooks/useTheme"

export default function DetailCard({
  firstStatId,
  firstStatValue,
  secondStatId,
  secondStatValue,
  headingId,
  secondaryHeading,
  titleId,
  iconType,
  descriptionId,
  buttonId,
  buttonHandler,
  children,
}) {
  const { colors, isMobile } = useTheme()

  function StatsRow() {
    if (!firstStatId) return null
    return (
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Statistic
            title={<FormattedMessage id={firstStatId} />}
            value={firstStatValue}
          />
        </Col>
        {secondStatId && (
          <Col md={12} xs={24}>
            <Statistic
              title={<FormattedMessage id={secondStatId} />}
              value={secondStatValue}
            />
          </Col>
        )}
      </Row>
    )
  }

  function TitleRow() {
    if (!titleId) return null

    return (
      <Row justify="center" style={{ marginTop: 10 }}>
        <h3 style={{ fontWeight: "400" }}>
          <FormattedMessage id={titleId} />
        </h3>
      </Row>
    )
  }

  function ButtonRow() {
    if (!buttonId) return null

    return (
      <Row justify="center" style={{ marginTop: 10, textAlign: "center" }}>
        <Button
          onClick={buttonHandler}
          type="primary"
          size="large"
          shape="default"
        >
          <FormattedMessage id={buttonId} />
        </Button>
      </Row>
    )
  }

  function DescriptionRow() {
    if (!descriptionId) return null
    return (
      <Row justify="center" style={{ marginTop: 10, textAlign: "center" }}>
        <p style={{ fontWeight: 150, fontSize: 18 }}>
          <FormattedMessage id={descriptionId} />
        </p>
      </Row>
    )
  }

  function IconRow() {
    let icon = null
    switch (iconType) {
      case "spin":
        icon = (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ color: colors.green, fontSize: isMobile ? 32 : 48 }}
              />
            }
          />
        )
        break
      case "cart":
        icon = (
          <ShoppingCartOutlined
            style={{ color: colors.green, fontSize: isMobile ? 64 : 96 }}
          />
        )
        break
      case "file":
        icon = (
          <FileDoneOutlined
            style={{ color: colors.green, fontSize: isMobile ? 64 : 96 }}
          />
        )
        break
      case "check":
        icon = (
          <CheckCircleOutlined
            style={{ color: colors.green, fontSize: isMobile ? 64 : 96 }}
          />
        )
        break
      default:
        return null
    }

    if (icon === null) return null

    return (
      <Row justify="center" style={{ marginTop: 10 }}>
        {icon}
      </Row>
    )
  }

  return (
    <Card style={{ borderRadius: 2 }}>
      <Row justify="space-between">
        <Col>
          <h2 style={{ fontWeight: "800" }}>
            <FormattedMessage id={headingId} />
          </h2>
        </Col>
        <Col>{secondaryHeading}</Col>
      </Row>
      <StatsRow />
      <TitleRow />
      <IconRow />
      <DescriptionRow />
      <ButtonRow />
      {children}
    </Card>
  )
}

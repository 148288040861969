import * as Sentry from "@sentry/react"

import api from "./api"
import { getOrder, approveOrder } from "../hooks/requests"

export const fetchOrder = async (orderId) => {
  console.log("Fetching Order:", orderId)
  try {
    const response = await api(getOrder(orderId))
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "order", id, status }, "*")
    return { success: true, data }
  } catch (err) {
    console.error("Error Fetching Order:", err, err?.response)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const doApproveOrder = async (orderId) => {
  console.log("Approving Order:", orderId)
  try {
    const response = await api(approveOrder(orderId))
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "order", id, status }, "*")
    return { success: true, data }
  } catch (err) {
    console.error("Error Approving Order:", err, err?.response)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

export const doApproveOrderForCitizens = async (orderId, offer) => {
  console.log("Approving Order:", orderId)
  try {
    const response = await api(approveOrder(orderId, offer))
    const { data } = response
    const { id, status } = data
    window.parent &&
      window.parent.postMessage({ type: "order", id, status }, "*")
    return { success: true, data }
  } catch (err) {
    console.error("Error Approving Order:", err, err?.response)
    Sentry.captureException(err)
    return { success: false, error: err.response }
  }
}

import React, { useMemo } from "react"

import { Button, Card, Row, Typography, Tooltip } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"
import { useApply } from "../../hooks/useApply"
import { useTheme } from "../../hooks/useTheme"
// import ConcoraTermsModal from "../modals/ConcoraTermsModal"

const { Text } = Typography

function GenesisApprovalCard({ offer, onReviewDisclosures }) {
  const { merchant } = useApply()
  const { colors } = useTheme()

  // const [isOpenTerms, setIsOpenTerms] = useState(false)

  const month = useMemo(() => {
    if (offer.intro_term) {
      return offer.intro_term.split("@")[0]
    }
    return 0
  }, [offer])

  console.log(offer)
  return (
    <Card bodyStyle={{ margin: 0 }}>
      <Row justify="center" style={{ marginTop: 15, paddingTop: 0 }}>
        <img alt="lender-logo" src={offer?.originator?.image_url} height={42} />
      </Row>
      <Row justify="center" style={{ marginTop: 15, marginBottom: 10 }}>
        <p style={{ fontSize: 22, margin: 0, fontWeight: 500 }}>
          Pre-Qualified Credit Limit
        </p>
      </Row>
      <div justify="center" style={{ marginTop: 0, paddingTop: 0 }}>
        <p style={{ fontSize: 22, margin: 0, fontWeight: 300 }}>
          {offer.amount}
        </p>
      </div>

      {/* <Row justify="center" style={{ marginTop: 10 }}>
        <Text strong>Loan Type:</Text>&nbsp;&nbsp;
        <Text>{formatStatus(offer.loan_type)}</Text>
      </Row> */}
      <Row justify="center">
        <b>
          <FormattedMessage id="general.purchaseApr" />
          :&nbsp;&nbsp;
        </b>
        {offer.apr}
      </Row>

      {/* <Row justify="center">
        <b>
          <FormattedMessage id={"general.deferredInterest"} />
          :&nbsp;&nbsp;
        </b>
        {offer.intro_term ? offer.intro_term.split("@")[0] : "0 Months"}
        &nbsp;&nbsp;
        <Tooltip
          title={
            "No Interest if Paid in Full in ${month} Months on All Purchases. Interest will accrue and be charged to your account at [y]% APR from the purchase date if the purchase is not paid in full within ${month} months or your account becomes 180 days past due, or is charged off for any reason. Monthly minimum payments required. As a reminder, paying only the monthly minimum payment amount each month may not pay off your purchase within ${month} months. You may have to make additional or increased payments during the deferred interest period to avoid having to pay the accrued deferred interest."
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Row> */}

      <Row justify="center" style={{ marginTop: 15, marginBottom: 5 }}>
        <Text style={{ fontSize: 11 }}>No Minimum Interest Charge.</Text>
      </Row>
      <Row justify="center" style={{ marginBottom: 5 }}>
        <Button
          type="text"
          style={{
            textAlign: "center",
            fontSize: 11,
            color: colors.green,
          }}
          onClick={onReviewDisclosures}
        >
          See complete terms and conditions
        </Button>
      </Row>
      {month > 0 && (
        <Row justify="center" style={{ marginBottom: 15 }}>
          <Text
            style={{
              textAlign: "center",
              fontSize: 11,
            }}
          >
            {month} Months Special Financing
            <Tooltip
              title={`No Interest if Paid in Full in ${month} Months on All Purchases. Interest will accrue and be charged to your account at ${offer.apr} APR from the purchase date if the purchase is not paid in full within ${month} months or your account becomes 180 days past due, or is charged off for any reason. Monthly minimum payments required. As a reminder, paying only the monthly minimum payment amount each month may not pay off your purchase within ${month} months. You may have to make additional or increased payments during the deferred interest period to avoid having to pay the accrued deferred interest.`}
            >
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </Text>
        </Row>
      )}
      <Row justify="center" style={{ marginBottom: 5 }}>
        <Text style={{ fontSize: 13 }}>For use with the Merchant below:</Text>
      </Row>
      <Row justify="center">
        <Text style={{ fontSize: 12, fontWeight: 500 }}>{merchant.name}</Text>
      </Row>
      <Row justify="center">
        {merchant.address && (
          <Text style={{ fontSize: 12, fontWeight: 500 }}>
            {merchant.address?.street1 || ""} {merchant.address?.street2 || ""}{" "}
            {merchant.address?.city}, {merchant.address?.state}{" "}
            {merchant.address?.zip}
          </Text>
        )}
      </Row>
      <Row justify="center" style={{ marginTop: 30 }}>
        <Button type="primary" onClick={onReviewDisclosures}>
          Review Disclosures
        </Button>
      </Row>
      <Row
        justify="center"
        style={{ textAlign: "center", margin: 0, padding: 0, marginTop: 10 }}
      >
        <Text>
          Before submitting your application, please carefully review the rate
          and information along with other important terms and conditions for
          your Concora™ Credit Account issued by {offer.bank_name}
        </Text>
      </Row>
      {/* <ConcoraTermsModal
        showModal={isOpenTerms}
        close={() => setIsOpenTerms(false)}
      /> */}
    </Card>
  )
}
export default GenesisApprovalCard

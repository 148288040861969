import React from "react"
import { formatDate, formatStatus } from "../../utils/formatters"

export default function OrderInfo({ order, title }) {
  return (
    <>
      <p className="basic-par">
        <b>{title}</b>
      </p>
      <p className="basic-par">Order: {order.purchase_order}</p>
      <p className="basic-par">
        Date Created: {formatDate(order.date_created, "MM/DD/YY")}
      </p>
      <p className="basic-par">Status: {formatStatus(order.status)}</p>
    </>
  )
}

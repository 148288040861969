import React from "react"
import { Formik } from "formik"
import { Form, SubmitButton, Input } from "formik-antd"
import { Row } from "antd"
import * as Yup from "yup"
import EmailInput from "../inputs/EmailInput"
import PhoneInput from "../inputs/PhoneInput"

export default function OtpForm({ mode, onSubmit, submitText, otpId }) {
  return (
    <Formik
      initialValues={{ identifier: "" }}
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        identifier: Yup.string().required("This field is required"),
      })}
    >
      {(formik) => (
        <Form layout="vertical">
          {!otpId && mode === "email" && (
            <div className="account-login-placeholder">
              <EmailInput
                formik={formik}
                key="identifier"
                name="identifier"
                placeholder="Please enter the email associated with your account to receive a one time pass code to log in"
                label="Email"
                hasFeedback
                unique={false}
              />
            </div>
          )}
          {!otpId && mode === "phone" && (
            <div className="account-login-placeholder">
              <PhoneInput
                formik={formik}
                key="identifier"
                name="identifier"
                placeholder="Please enter the phone number associated with your account to receive a one time passcode to log in"
                label="Phone"
                hasFeedback
                className="account-login-placeholder"
              />
            </div>
          )}
          {otpId && (
            <Form.Item
              name="identifier"
              label={`Please Enter The Code Sent to Your ${mode}`}
              hasFeedback
            >
              <Input
                name="identifier"
                size="large"
                autoComplete="off"
                autoCorrect="off"
                placeholder="OTP Verification Code"
              />
            </Form.Item>
          )}
          <Row justify="center">
            <SubmitButton>{submitText}</SubmitButton>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Row, Button } from "antd"
import { FormattedMessage } from "react-intl"
import OrderStatus from "./OrderStatus"
import DetailCard from "../cards/DetailCard"
import { moneyFormat, formatDate } from "../../utils/formatters"
import IndexListView from "../core/IndexListView"

export default function OrdersSummary({ loan }) {
  const [step, setStep] = useState(0)
  const history = useHistory()

  useEffect(() => {
    if (loan.orders.length === 0) {
      setStep(1)
    } else if (loan.orders.length === 1) {
      if (loan.orders[0].status === "pending") {
        setStep(2)
      } else if (loan.orders[0].status === "sent") {
        setStep(3)
      }
    }
  }, [loan])

  function orderButton() {
    if (loan.orders.length === 0) return null
    if (loan.orders.length === 1) {
      if (loan.orders[0].status === "sent") {
        return (
          <Button
            size="large"
            shape="default"
            type="primary"
            onClick={() => history.push(`/orders/${loan.orders[0].id}`)}
          >
            Review & Approve
          </Button>
        )
      }
      return (
        <Button
          size="large"
          shape="default"
          onClick={() => history.push(`/orders/${loan.orders[0].id}`)}
        >
          View Order
        </Button>
      )
    }
  }

  if (
    loan.orders.length === 0 ||
    (loan.orders.length === 1 && loan.orders[0].status !== "approved")
  ) {
    return (
      <DetailCard headingId="OrdersSummary.heading">
        <>
          <Row justify="center">
            <p style={{ fontWeight: "150", fontSize: 14, textAlign: "center" }}>
              <FormattedMessage id="OrdersSummary.description" />
            </p>
          </Row>
          <Row>
            <OrderStatus step={step} loan={loan} />
          </Row>
          <Row justify="center">{orderButton()}</Row>
        </>
      </DetailCard>
    )
  }

  const tableData = []

  loan.orders.forEach((order) => {
    tableData.push({
      merchant: `Order # ${order.purchase_order}`,
      secondary: moneyFormat(order.total),
      secondary_title: "Total",
      view: order.id,
      key: order.id,
      status: order.status,
      amount: moneyFormat(order.total),
      date: formatDate(order.date_created, "lll"),
    })
  })

  return (
    <DetailCard headingId="OrdersSummary.heading">
      <IndexListView data={tableData} type="order" resource="orders" />
    </DetailCard>
  )
}

import React from "react"
import { Formik } from "formik"
import { Row, Typography } from "antd"
import { SubmitButton, Form, Checkbox } from "formik-antd"

const { Text } = Typography

function AgreeForm(props) {
  return (
    <Formik initialValues={{ agree: null }} onSubmit={props.onSubmit}>
      <Form layout="vertical">
        <Checkbox
          name="agree"
          required
          validate={(value) => {
            if (value && value === true) return undefined
            return "Please agree to the terms to continue"
          }}
        />
        &nbsp;&nbsp;
        <Text style={{ textAlign: "center", fontSize: 14, fontColor: "black" }}>
          {props.agree}
        </Text>
        <Row justify="center">
          <div style={{ marginTop: 20 }}>
            <SubmitButton>{props.submitText}</SubmitButton>
          </div>
        </Row>
      </Form>
    </Formik>
  )
}

export default AgreeForm

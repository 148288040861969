import React from "react"
import { formatPhoneNumber } from "../../utils/formatters"

export default function MerchantInfo({ title, merchant }) {
  return (
    <>
      <p className="basic-par">
        <b>{title}</b>
      </p>
      <p className="basic-par">{merchant.name}</p>
      <p className="basic-par">{merchant.email?.email}</p>
      <p className="basic-par">{formatPhoneNumber(merchant.phone?.number)}</p>
      <p className="basic-par">
        {merchant.address?.street1} {merchant.address?.street2}{" "}
        {merchant.address?.city}
      </p>
      <p className="basic-par">
        {merchant.address?.state ? `${merchant.address?.state},` : ""}{" "}
        {merchant.address?.zip}
      </p>
    </>
  )
}

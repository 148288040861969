import React from "react"
import { Card, Row, Typography, Tooltip } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"

import { formatLoan } from "../../utils/formatters"

const { Text } = Typography

function SingleOfferCard({
  subTitle,
  offer,
  disclaimer,
  acceptButton,
  compliance,
}) {
  return (
    <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
      <Row justify="center">
        <img src={offer.originator.image_url} alt="lender-logo" height={28} />
      </Row>

      <Row justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
        <p style={{ fontSize: 22, margin: 0, fontWeight: 300 }}>{subTitle}</p>
      </Row>
      <Row
        justify="center"
        style={{ marginTop: 0, marginBottom: 0, paddingBottom: 0 }}
      >
        <p style={{ fontSize: 20, margin: 0, fontWeight: 400 }}>
          {formatLoan(offer.loan_type)} Up To
        </p>
      </Row>

      <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>{offer.amount}</p>
      <br />

      <div>
        <b>
          <FormattedMessage id="general.purchaseApr" />
          :&nbsp;&nbsp;
        </b>
        {offer.apr}
      </div>

      {offer.term ? (
        <div>
          <b>
            <FormattedMessage id={"general.deferredInterest"} />
            :&nbsp;&nbsp;
          </b>
          {offer.originator.key !== "fortiva"
            ? offer.term
            : offer.intro_term
            ? offer.intro_term.split("@")[0]
            : "0 Months"}
          &nbsp;&nbsp;
          <Tooltip
            title={
              "Interest will be charged to your account from the purchase date if the promotional purchase is not paid in full within the promotional period. Minimum monthly payments are required. Required payments may or may not pay off your purchase before the end of the promotional period. To avoid paying the Deferred Interest Charges, you must pay the promotional purchase in full within the promotional period."
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ) : null}
      {offer.originator.key === "fortiva" && offer.annual_fee && (
        <div>
          <b>
            <FormattedMessage id="general.annualFee" />
            :&nbsp;&nbsp;
          </b>
          {offer.annual_fee}
          &nbsp;&nbsp;
        </div>
      )}
      {offer.monthly_payment && offer.originator.key !== "fortiva" ? (
        <div>
          <b>
            <FormattedMessage id="general.monthlyPayment" />
            :&nbsp;&nbsp;
          </b>
          {offer.monthly_payment}
        </div>
      ) : null}
      {offer.payment ? (
        <div>
          <b>
            <FormattedMessage id="general.payment" />
            :&nbsp;&nbsp;
          </b>
          {offer.payment}
        </div>
      ) : null}
      {acceptButton && (
        <Row justify="center" style={{ marginTop: 20 }}>
          {acceptButton}
        </Row>
      )}
      {compliance}
      <Row justify="center" style={{ marginTop: 10 }}>
        {
          <Row
            justify="center"
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontSize: 10,
              textAlign: "center",
            }}
          >
            <Text>{disclaimer || offer.originator.disclaimer}</Text>
          </Row>
        }
      </Row>
    </Card>
  )
}
export default SingleOfferCard

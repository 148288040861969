import React from "react"
import { Typography } from "antd"
import { useTheme } from "../../hooks/useTheme"

const { Text } = Typography

function PoweredBy() {
  const { poweredByLogo } = useTheme()
  return (
    <div style={{ textAlign: "center" }}>
      <Text
        disabled
        style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
      >
        <br />
        <i>Powered By</i>
        <br />
        <img src={poweredByLogo} alt="paypossible-logo" height={28} />
        <br />
      </Text>
    </div>
  )
}

export default PoweredBy

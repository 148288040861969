import React, { useState, useMemo } from "react"
import { Result, Button, Row, notification } from "antd"
import { useHistory } from "react-router-dom"
import Loader from "../core/Loader"
import { reapplyLead, pingAndWait } from "../../services/lead.service"

function NoOfferView({ reapply, lead }) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const title = useMemo(() => {
    switch (lead.merchant.code) {
      case "trek":
        return "We are unable to approve your application."
      default:
        return "Unfortunately there were no offers at this time."
    }
  }, [lead.merchant.code])

  const description = useMemo(() => {
    switch (lead.merchant.code) {
      case "trek":
        return (
          <div>
            <div>Thank you for considering Citizens Pay.</div>
            <div style={{ marginTop: 15 }}>
              You will receive an email/text when your detailed decision notice
              is available to be viewed at cpaydocuments.citizensbank.com.
            </div>
            <div style={{ marginTop: 15 }}>
              If you do not hear from us within 30 days, or have any further
              questions, please call us at 888-522-9881.
            </div>
            <div style={{ marginTop: 15 }}>
              <strong>Please Note:</strong> For your privacy, Trek Bicycle
              Corporation does not have any information regarding this Citizens
              Pay application.
            </div>
          </div>
        )
      default:
        return (
          <h5>
            If you did not receive offers you will receive an Adverse Action
            Notice via email or in the mail, from the Payment Solution Providers
            that did not offer you an approval. An Adverse Action Notice
            will detail your true credit score and the reason you receive a
            status of 'No Offers.' If you did not receive one for some reason
            please check your spam folder, or you can contact the Payment
            Solution Providers directly. If there is a credit freeze on your
            account, you’ll have to temporarily remove it and reapply.
          </h5>
        )
    }
  }, [lead.merchant.code])

  console.log("lead is", lead)
  async function handleReapply() {
    setLoading(true)
    const reapply = await reapplyLead(lead.id)
    if (reapply.success) {
      const pingd = await pingAndWait(reapply.data.id)
      if (pingd.success) {
        setLoading(false)
        history.push(`/apps/${pingd.lead.data.id}`)
      } else {
        notification.error({
          message: "Error",
          description: "There was an error reapplying",
          duration: 5,
        })
      }
    } else {
      notification.error({
        message: "Error",
        description: "There was an error reapplying",
        duration: 5,
      })
    }
  }

  return (
    <>
      <Loader
        visible={loading}
        title="Searching For Offers"
        body="Please wait while we search our partner network for your best available rate!"
      />

      <Result
        status="error"
        title="No Offers"
        subTitle={
          <>
            {title}
            {lead.actions.reapply && (
              <Row style={{ marginTop: 10 }} justify="center">
                <Button onClick={handleReapply} type="primary">
                  Re-Apply Now!
                </Button>
              </Row>
            )}
          </>
        }
        style={{ padding: 10 }}
      >
        <div style={{ textAlign: "center" }}>{description}</div>
      </Result>
    </>
  )
}
export default NoOfferView

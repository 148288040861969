import React from "react"
import { Typography } from "antd"
import BounceLoader from "react-spinners/BounceLoader"
import BeatLoader from "react-spinners/BeatLoader"

import { css } from "@emotion/react"
import { useTheme } from "../../hooks/useTheme"

const { Title } = Typography

function LoaderPage(props) {
  const { themeColor } = useTheme()
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${themeColor};
  `
  let loader = (
    <BounceLoader color={themeColor} loading css={override} size={125} />
  )
  loader = <BeatLoader color={themeColor} loading />

  return (
    <div style={{ textAlign: "center" }}>
      <Title level={3}>{props.title}</Title>
      <div style={{ padding: 30 }}>{loader}</div>
      <br />
      <Title level={5}>{props.body}</Title>
    </div>
  )
}
export default LoaderPage

import React from "react"
import { Card, Row, Col, Typography, Button } from "antd"
import { useHistory } from "react-router-dom"

import { useTheme } from "../../hooks/useTheme"
import PoweredBy from "../core/PoweredBy"
import MerchantDetails from "../descriptions/MerchantDetails"

const { Text } = Typography

function DefaultLander(props) {
  const { push } = useHistory()
  const { poweredByLogo, themeLander } = useTheme()

  return (
    <Card style={{ marginTop: 5 }}>
      <Row
        align="middle"
        justify="center"
        style={{ padding: 10, paddingBottom: 20 }}
      >
        <Col>
          <MerchantDetails merchant={props.merchant} />
        </Col>
      </Row>
      {themeLander !== "ownlease" && (
        <Row>
          <Text strong style={{ textAlign: "center" }}>
            Boost Mobile is now offering device financing for qualified
            customers. Upgrade your device today, the application takes just
            minutes to complete to get offers instantly.
          </Text>
        </Row>
      )}
      <Row justify="center" style={{ marginTop: 25 }}>
        <Button
          type="primary"
          onClick={() => {
            push("/apply")
          }}
        >
          Apply Now
        </Button>
      </Row>
      {poweredByLogo && <PoweredBy />}
    </Card>
  )
}

export default DefaultLander

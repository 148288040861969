import React from "react"
import { Modal, Row, Button } from "antd"
import { useTheme } from "../../hooks/useTheme"

export default function FrameModal({
  showModal,
  title,
  setShow,
  url,
  setLoading,
}) {
  const { isMobile } = useTheme()

  const handleLoading = () => {
    if (setLoading) {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={showModal}
      title={title}
      width={isMobile ? "100vw" : "80vw"}
      centered
      footer={
        true ? null : (
          <Row justify="center">
            <Button type="primary" onClick={() => setShow(false)}>
              Close
            </Button>
          </Row>
        )
      }
      bodyStyle={{ height: isMobile ? "85vh" : "85vh", padding: 0, margin: 0 }}
      onCancel={() => setShow(false)}
      onOk={() => setShow(false)}
    >
      <iframe
        title={title}
        height="100%"
        frameBorder="0"
        width="100%"
        onLoad={handleLoading}
        src={url}
      />
      <a href={url} rel="noreferrer" target="_blank">
        Open In New Window
      </a>
    </Modal>
  )
}

import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Formik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import { Typography } from "antd"

const { Title } = Typography

function MerchantForm(props) {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Formik
        initialValues={{
          mid: null,
        }}
        onSubmit={async (values, actions) => {
          setLoading(true)
          await props.onSubmit(values, actions)
          setLoading(false)
        }}
      >
        {(formik) => (
          <Form
            layout="vertical"
            style={{ paddingTop: 25, paddingLeft: 20, paddingRight: 20 }}
          >
            <Title style={{ paddingTop: 25, paddingBottom: 25 }} level={3}>
              {props.title}
            </Title>
            <Form.Item name="mid" layout="horizontal" label={props.label}>
              <Input
                name="mid"
                style={{ height: 125, fontSize: 36, textAlign: "center" }}
                required={false}
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                placeholder={props.placeholder}
              />
            </Form.Item>
            <SubmitButton
              shape="default"
              style={{ width: "100%", height: 50 }}
              loading={loading}
            >
              Continue
            </SubmitButton>
          </Form>
        )}
      </Formik>
      <div style={{ margin: 20 }}>
        <p>
          If you already have an account, please <Link to="/login">Login</Link>
        </p>
      </div>
    </>
  )
}

export default MerchantForm

import React from "react"
import { Row, Col } from "antd"
import { Form, Input } from "formik-antd"
import NumericInput from "./NumericInput"

export default function DebitCardInput(props) {
  return (
    <>
      <Row gutter={16}>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          <Form.Item
            name="debit_card.number"
            label="Card Number"
            required={false}
            hasFeedback
            validate={(value) => {
              return undefined
            }}
          >
            <Input
              data-openreplay-obscured
              data-private="lipsum"
              name="debit_card.number"
              size="large"
              placeholder="Card Number"
              autoComplete="off"
              autoCorrect="off"
              inputMode="numeric"
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/g, "")
                props.formik.setFieldValue(
                  "debit_card.number",
                  value.slice(0, props.length)
                )
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <NumericInput
            name="debit_card.cvv"
            length={4}
            minLength={3}
            hasFeedback
            required={false}
            label="CVV"
            min={0}
            max={9999}
            placeholder="CVV"
            formik={props.formik}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <NumericInput
            name="debit_card.expiry_month"
            length={2}
            hasFeedback
            required={false}
            label="Exp Month"
            placeholder="MM"
            min={1}
            max={12}
            formik={props.formik}
          />
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <NumericInput
            name="debit_card.expiry_year"
            length={4}
            hasFeedback
            required={false}
            label="Exp Year"
            placeholder="YYYY"
            min={new Date().getFullYear()}
            max={new Date().getFullYear() + 100}
            formik={props.formik}
          />
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <NumericInput
            name="debit_card.zip"
            length={5}
            minLength={5}
            hasFeedback
            required={false}
            label="ZIP Code"
            min={0}
            max={99999}
            placeholder="ZIP"
            formik={props.formik}
          />
        </Col>
      </Row>
    </>
  )
}

import { Row, Card } from "antd"

const CategoryCard = ({ item, setSelectCatId }) => {
  return (
    <Card
      bodyStyle={{
        paddingBottom: 0,
        paddingRight: 10,
        paddingLeft: 10,
      }}
      style={{
        marginTop: 10,
        marginBottom: 10,
        paddingBottom: 20,
        cursor: "pointer",
        width: "100%",
        fontSize: 12,
      }}
      onClick={() => setSelectCatId(item.id)}
    >
      <Row justify="space-between" style={{ width: "100%" }}>
        <div style={{ fontWeight: 600 }}>{item?.name || ""}</div>
        <div style={{ fontWeight: 600 }}>({item?.product_count})</div>
      </Row>
    </Card>
  )
}

export default CategoryCard

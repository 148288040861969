import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Alert } from "antd"
import { Spinner } from "../components/core/Spinner"
import LoanView from "../components/loan/LoanView"
import NewLoanModal from "../components/modals/NewLoanModal"
import LoanApprovedModal from "../components/modals/LoanApprovedModal"
import sleep from "../utils/sleep"
import { useLoan } from "../hooks/hooks"
import { useApply } from "../hooks/useApply"

export default function LoanPage(props) {
  const { loan_id } = useParams()
  const history = useHistory()
  const { fetchMerchant, getLead, setLoan } = useApply()

  const [refreshLoan, setRefreshLoan] = useState(0)
  const [loanStatus, setLoanStatus] = useState(null)

  const loan = useLoan(loan_id, refreshLoan)

  const [modalAcknowledged, setModalAcknowledged] = useState(true)
  const [showModal, setShowModal] = useState(
    !!(loan && loan.data && loan.data.status === "approved")
  )
  const [approvedModal, setApprovedModal] = useState(false)
  const [isOpenedApprove, setIsOpenedApprove] = useState(true)

  /**
   * Redirect to Orders
   *
   * If the user has only one order and didn't
   * come from the order page, redirect to order
   */

  useEffect(() => {
    if (
      loan.data &&
      loan.data.originator.key !== "citizens" &&
      loan.data.orders.length === 1 &&
      loan.data.orders[0].status === "sent" &&
      (!props.location.state || !props.location.state.fromOrder)
    ) {
      history.push(`/orders/${loan.data.orders[0].id}`)
    }
  }, [loan, history, props.location.state])

  /**
   * Refresh Loan
   *
   * If we have a loan without an order or an order that hasn't been sent
   * continue polling for updates to that loan/order
   */
  useEffect(() => {
    let mounted = true

    if (mounted && loan.data && loan.data.status === "approved") {
      setShowModal(!modalAcknowledged)
    } else {
      setShowModal(false)
    }

    ;(async () => {
      console.log("are we refreshing the load")
      await sleep(5000)
      if (
        mounted &&
        loan.data &&
        loan.data.status !== "declined" &&
        (loan.data.orders.length === 0 ||
          loan.data.orders[0].status === "pending")
      ) {
        setRefreshLoan((prevRefresh) => prevRefresh + 1)
      }
    })()

    return () => (mounted = false)
  }, [setRefreshLoan, loan.data, modalAcknowledged])

  useEffect(() => {
    setLoan(loan.data)
    if (loan.data?.lead?.id) {
      getLead(loan.data.lead.id)
    }
    if (loan.data?.merchant) {
      fetchMerchant(loan.data.merchant.code)
    }
    if (loan.data?.status) {
      setLoanStatus(loan.data?.status)
    }
    //eslint-disable-next-line
  }, [loan.data])

  useEffect(() => {
    if (loanStatus) {
      const { id, status } = loan.data
      window.parent &&
        window.parent.postMessage({ type: "loan", id, status }, "*")
    }
    //eslint-disable-next-line
  }, [loanStatus])

  useEffect(() => {
    if (
      loan.data?.originator?.key === "genesis" &&
      loan.data.status === "approved" &&
      isOpenedApprove
    ) {
      setIsOpenedApprove(false)
      setApprovedModal(true)
    }
    //eslint-disable-next-line
  }, [loan.data])

  function closeModal() {
    setModalAcknowledged(true)
    setShowModal(false)
  }

  if (loan.error?.response?.data?.detail) {
    return (
      <Alert
        message={loan.error.response.data.detail}
        type="error"
        showIcon
        closable
        style={{ marginBottom: 5 }}
      />
    )
  }

  if (loan.loading || !loan.data) {
    return <Spinner />
  }

  if (loan.data) {
    return (
      <>
        <NewLoanModal visible={showModal} close={closeModal} />
        <LoanApprovedModal
          loan={loan.data}
          visible={approvedModal}
          close={() => setApprovedModal(false)}
        />
        <LoanView loan={loan.data} />
      </>
    )
  }
}

import React from "react"
import { Typography } from "antd"
import { Modal } from "antd"
import MoonLoader from "react-spinners/MoonLoader"
import { css } from "@emotion/react"

const { Title } = Typography
const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-color: #f79323;
`
export default function DocuSignModal({ visible, onCancel }) {
  return (
    <Modal
      visible={visible}
      bodyStyle={{
        backgroundColor: "#194a6c",
      }}
      style={{ padding: 25 }}
      footer={null}
      closable={true}
      onCancel={onCancel}
    >
      <Title level={2} style={{ color: "white", textAlign: "center" }}>
        DocuSign Loading
      </Title>

      <MoonLoader color="white" loading css={override} size={50} />

      <Title level={5} style={{ color: "white", textAlign: "center" }}>
        Please do hit the back button or refresh your screen while DocuSign is
        loading.
      </Title>
    </Modal>
  )
}

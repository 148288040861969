import { FormattedMessage } from "react-intl"

export default function FormattedTitle({ id, size }) {
  let fontSize = 0
  switch (size) {
    case "small":
      fontSize = 18
      break
    case "medium":
      fontSize = 24
      break
    case "large":
      fontSize = 32
      break
    default:
      fontSize = 18
  }

  return (
    <p style={{ fontSize, fontWeight: 250 }}>
      <FormattedMessage id={id} />
    </p>
  )
}

import BeatLoader from "react-spinners/BeatLoader"
import BounceLoader from "react-spinners/BounceLoader"
import { css } from "@emotion/react"
import { Typography, Modal, Row } from "antd"
import { FormattedMessage } from "react-intl"
import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"
import { useMemo } from "react"

const { Text } = Typography

function OrderApproveModal({ order, visible, onApprove, onCancel, approving }) {
  const { themeColor } = useTheme()
  const { merchantId } = useApply()

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${themeColor};
  `

  let loader = (
    <BounceLoader color={themeColor} loading css={override} size={125} />
  )
  loader = (
    <Row justify="center">
      <BeatLoader color={themeColor} loading />
    </Row>
  )

  const purchaseLender = useMemo(() => {
    switch (order.loan.originator.key) {
      case "finwise":
        return "OrderApproveModal.finWise.purchaseLender"
      default:
        return "OrderApproveModal.purchaseLender"
    }
  }, [order])

  const payLender = useMemo(() => {
    switch (order.loan.originator.key) {
      case "finwise":
        return "OrderApproveModal.finWise.payLender"
      default:
        return "OrderApproveModal.payLender"
    }
  }, [order])

  const title = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return "OrderApproveModal.trek.title"
      default:
        return "OrderApproveModal.title"
    }
  }, [merchantId])

  const bodyContent = useMemo(() => {
    switch (order?.loan?.originator?.key) {
      case "fortiva":
        return (
          <Row>
            <Text>
              <FormattedMessage
                id="OrderApproveModal.fortiva"
                values={{ amount: order.total }}
              />
            </Text>
          </Row>
        )
      case "genesis":
        return (
          <Row>
            <Text>
              <FormattedMessage id="OrderApproveModal.genesis" />
            </Text>
          </Row>
        )
      default:
        return (
          <Row>
            <Text>
              <FormattedMessage id="OrderApproveModal.acknowledgement" />
            </Text>
            <br />
            <br />
            <ul>
              <li>
                <FormattedMessage
                  id={purchaseLender}
                  values={{ lender: order.loan.originator.name }}
                />
              </li>
              <li>
                <FormattedMessage
                  id={payLender}
                  values={{ lender: order.loan.originator.name }}
                />
              </li>
            </ul>
          </Row>
        )
    }
  }, [order, purchaseLender, payLender])

  return (
    <Modal
      visible={visible}
      title={<FormattedMessage id={title} />}
      onCancel={approving ? null : onCancel}
      onOk={approving ? null : onApprove}
      okText={null}
    >
      {approving ? loader : bodyContent}
    </Modal>
  )
}

export default OrderApproveModal

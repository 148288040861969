import React from "react"
import { Redirect } from "react-router-dom"
import { Card } from "antd"
import { useLeads } from "../hooks/hooks"
import { Spinner } from "../components/core/Spinner"
import { formatDate, moneyFormat } from "../utils/formatters"
import IndexListView from "../components/core/IndexListView"

function LeadsPage(props) {
  const { error, loading, data } = useLeads()

  if (data && data.count === 1) {
    return (
      <Redirect
        to={{
          pathname: `/apps/${data.results[0].id}`,
          state: {
            from: props.location,
            lead: data.results[0],
          },
        }}
      />
    )
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    alert(error)
  }

  const tableData = []

  if (data) {
    data.results.forEach((data) => {
      tableData.push({
        name: data.personal
          ? `${data.personal.first_name} ${data.personal.last_name}`
          : "",
        merchant: data.merchant ? data.merchant.code : "",
        secondary: null, // moneyFormat(data.amount),
        secondary_title: null, // "Amount",
        status: data.status,
        amount: moneyFormat(data.amount),
        date: formatDate(data.date_created, "lll"),
        view: data.id,
        key: data.id,
      })
    })
  }

  return (
    <Card>
      <IndexListView
        title="Apps"
        data={tableData}
        type="lead"
        resource="apps"
        showDate={false}
      />
    </Card>
  )
}

export default LeadsPage

import React, { useState } from "react"
import { Divider, Card, Row, Typography, Modal } from "antd"
import Barcode from "react-barcode"
import { CreditCardOutlined } from "@ant-design/icons"

const { Paragraph, Title, Text } = Typography

function CardPage(props) {
  const [showModal, setShowModal] = useState(true)
  return (
    <>
      <Modal
        visible={showModal}
        title={<Title level={3}>Your Virtual Card</Title>}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <Row justify="center">
          <CreditCardOutlined style={{ fontSize: 48, color: "#595959" }} />
        </Row>
        <Row style={{ marginTop: 20, textAlign: "center" }}>
          <Text style={{ fontSize: 16 }}>
            Congratuations on your Fortiva Line of Credit! You've been issued a
            virtual card which can be used immediately to complete your
            purchase.
          </Text>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 0, textAlign: "start" }}>
          <Text strong>To Make a Purchase</Text>
          <Paragraph>
            To make a purchase present this virtual card to the associate at
            checkout as you would a normal card. The associate may enter the
            card number direclty or scan the provided barcode
          </Paragraph>
        </Row>
        <Row style={{ marginTop: 0, marginBottom: 0, textAlign: "start" }}>
          <Text strong>Merchant Notice</Text>
          <Paragraph>
            This card may only be used for the purchse of physical goods and
            cannot be applied to services. If your cart includes service fees
            you will be required to pay for those charges with a separate
            payment method
          </Paragraph>
        </Row>
      </Modal>
      <Card
        title="Virtual Card"
        headStyle={{ backgroundColor: "#595959", color: "#ffffff" }}
        bodyStyle={{ border: "2px solid #f0f0f0" }}
      >
        <Row justify="center" style={{ marginTop: 15 }}>
          <img alt="lender-logo" src="/logos/fortiva-logo.png" width="200" />
        </Row>
        <Divider />
        <Row style={{ marginTop: 15 }}>
          <Title level={3}>Boost Mobile Retailer #1234</Title>
        </Row>
        <Row justify="start" style={{ marginTop: 20, fontSize: 16 }}>
          <Text strong>Customer Name:</Text>&nbsp;&nbsp;
          <Text>Sample Customer</Text>
        </Row>
        <Row justify="start" style={{ marginTop: 5, fontSize: 16 }}>
          <Text strong>Account Number:</Text>&nbsp;&nbsp;
          <Text>4328-9439-6628-440</Text>
        </Row>
        <Row justify="start" style={{ marginTop: 5, fontSize: 16 }}>
          <Text strong>CVV:</Text>&nbsp;&nbsp;<Text>393</Text>
        </Row>
        <Row justify="start" style={{ marginTop: 5, fontSize: 16 }}>
          <Text strong>Valid Thru:</Text>&nbsp;&nbsp;<Text>10/2025</Text>
        </Row>

        <Row justify="center" style={{ marginTop: 25 }}>
          <Barcode value="432894396628440" />
        </Row>
        <Row justify="center" style={{ marginTop: 25, textAlign: "center" }}>
          <Text style={{ color: "red" }} strong>
            This card can only be applied to physical goods (devices &
            accessories) - service fees must be collected with an alternative
            form of payment
          </Text>
        </Row>
      </Card>
    </>
  )
}

export default CardPage

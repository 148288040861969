import React from "react"
import {
  Statistic,
  Divider,
  Col,
  Tag,
  Space,
  Card,
  Row,
  Typography,
} from "antd"
import { useHistory } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import { formatStatus, getStatusColor } from "../../utils/formatters"

const { Title } = Typography

export default function IndexListView(props) {
  const history = useHistory()
  console.log(props)

  async function managePanel(id) {
    history.push(`/${props.resource}/${id}`)
  }

  return (
    <>
      {props.title && (
        <>
          <Title style={{ textAlign: "center" }} level={3}>
            {props.title}
          </Title>
          <Divider />
        </>
      )}
      {props.data.map((item, index) => (
        <Space
          key={item.key}
          direction="vertical"
          size="middle"
          style={{ display: "flex" }}
        >
          <Card
            key={item.key}
            onClick={() => managePanel(item.key)}
            bodyStyle={{ paddingBottom: 0, paddingRight: 10, paddingLeft: 10 }}
            style={{
              marginTop: 10,
              marginBottom: 10,
              paddingBottom: 20,
              cursor: "pointer",
            }}
          >
            <Row justify="space-between">
              <Col>
                <strong style={{ fontWeight: 800, fontSize: 20 }}>
                  {item.merchant}
                </strong>
                <strong />
              </Col>
              <Col>
                <Tag
                  style={{
                    color: "#ffffff",
                    borderRadius: 6,
                    borderColor: getStatusColor(props.type, item.status),
                    backgroundColor: getStatusColor(props.type, item.status),
                    fontSize: 14,
                    padding: 8,
                    fontWeight: 700,
                  }}
                >
                  {formatStatus(item.status)}
                </Tag>
              </Col>
            </Row>
            {props.type === "loan" && (
              <Row style={{ marginTop: 0, paddingTop: 0 }}>
                <img src={item.image} alt="lender-logo" />
              </Row>
            )}
            <Row style={{ marginTop: 15 }} justify="space-between">
              {item.secondary && (
                <Col span={10}>
                  <Statistic
                    title={item.secondary_title}
                    value={item.secondary}
                    valueStyle={{ fontSize: 14 }}
                  />
                </Col>
              )}
              <Col span={10}>
                {item.date && (
                  <Statistic
                    title="Date"
                    value={item.date}
                    valueStyle={{ fontSize: 14 }}
                  />
                )}
              </Col>
              <Col span={4} style={{ textAlign: "center", paddingTop: 10 }}>
                <RightOutlined
                  onClick={() => managePanel(item.key)}
                  style={{ fontWeight: 1800, fontSize: 18, color: "black" }}
                />
              </Col>
            </Row>
          </Card>
        </Space>
      ))}
    </>
  )
}

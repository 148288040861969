import { Row, Col, Button, Card } from "antd"

const ProductCard = ({
  item,
  selectProduct,
  btnText,
  removeProduct,
  disabled = false,
  isQTY = false,
}) => {
  const handleChange = (value) => {
    if (value === "add") {
      let data = { ...item }
      data.quantity++
      selectProduct(data)
    } else {
      let data = { ...item }
      if (data.quantity === 1) return
      data.quantity--
      selectProduct(data)
    }
  }

  return (
    <Card
      bodyStyle={{
        paddingBottom: 0,
        paddingRight: 10,
        paddingLeft: 10,
      }}
      style={{
        marginTop: 10,
        marginBottom: 10,
        paddingBottom: 20,
        cursor: "pointer",
        width: "100%",
        fontSize: 12,
      }}
    >
      <Row align="middle">
        <Col span={17}>
          <Row justify="space-between">
            <Col>
              <strong style={{ fontWeight: 600 }}>
                {item?.description || ""}
              </strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }} justify="space-between">
            <Col>
              <strong style={{ fontWeight: 600 }}>
                {item?.brand || ""} {item?.sku ? `| ${item?.sku}` : ""}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col span={7} style={{ paddingLeft: 15 }}>
          <Row justify="space-between">
            <Col>
              <strong style={{ fontWeight: 600 }}>
                ${Number(item?.price || 0).toLocaleString()}
              </strong>
            </Col>
            {!removeProduct && selectProduct && (
              <Col>
                <Button
                  type="primary"
                  style={{
                    marginTop: 5,
                    borderRadius: 10,
                    padding: "0px 10px",
                    fontSize: 12,
                  }}
                  disabled={disabled}
                  onClick={() => selectProduct(item)}
                >
                  {btnText}
                </Button>
              </Col>
            )}
            {removeProduct && (
              <Col>
                <Button
                  type="ghost"
                  style={{
                    marginTop: 5,
                    borderRadius: 10,
                    padding: "0px 10px",
                    fontSize: 12,
                  }}
                  onClick={() => removeProduct(item)}
                >
                  Remove
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {isQTY && (
        <Row style={{ marginTop: 7 }} align="center" justify="center">
          <Col>
            <strong style={{ fontWeight: 600, marginRight: 20 }}>
              QTY: {item?.quantity}
            </strong>
            <Button
              style={{
                padding: "0 8px",
                height: 28,
                borderRadius: "7px 0 0 7px",
              }}
              onClick={() => handleChange("add")}
            >
              +
            </Button>
            <Button
              style={{
                padding: "0 8px",
                height: 28,
                borderRadius: "0 7px 7px 0",
              }}
              onClick={() => handleChange("remove")}
            >
              -
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default ProductCard

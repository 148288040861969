import { Typography, Tooltip } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"

const { Text, Paragraph } = Typography

export function ApplyCompliance() {
  return (
    <Text
      type="secondary"
      style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
    >
      <FormattedMessage id="ApplyCompliance.checkingForOffer" />
      &nbsp;
      <Tooltip title={<FormattedMessage id="ApplyCompliance.ifAccept" />}>
        <QuestionCircleOutlined />
      </Tooltip>
    </Text>
  )
}

export function CitizensApplyCompliance() {
  return (
    <Text
      type="secondary"
      style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
    >
      <FormattedMessage id="CitizensApplyCompliance.eligabilityCheck" />
      &nbsp;
    </Text>
  )
}

export function CitizensOfferAvailableCompliance() {
  return (
    <>
      <Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
      >
        <FormattedMessage id="CitizensOfferAvailableCompliance.offerAvailable" />
        &nbsp;
      </Paragraph>
      <Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
      >
        <FormattedMessage id="CitizensOfferAvailableCompliance.copyright" />
        &nbsp;
      </Paragraph>
      <Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
      >
        <FormattedMessage id="CitizensOfferAvailableCompliance.registered" />
        &nbsp;
      </Paragraph>
      <Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
      >
        <FormattedMessage id="CitizensOfferAvailableCompliance.fdic" />
        &nbsp;
      </Paragraph>
    </>
  )
}

import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { Form, SubmitButton, Input } from "formik-antd"
import { Button, Row } from "antd"
import * as Yup from "yup"

function LoginForm(props) {
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    setIsSubmitted(false)
  }, [props])

  return (
    <Formik
      initialValues={{
        email: props.email,
        password: "",
      }}
      onSubmit={async (values, actions) => {
        props.onSubmit(values, actions)
        setIsSubmitted(true)
      }}
      validationSchema={Yup.object({
        email: Yup.string().required("Please enter your email"),
        password: Yup.string().required("Please enter you password"),
      })}
    >
      {(formik) => (
        <Form layout="vertical">
          <Form.Item name="email" label="Email" hasFeedback required>
            <Input
              name="email"
              size="large"
              autoComplete="off"
              autoCorrect="off"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item name="password" label="Password" hasFeedback required>
            <Input.Password
              name="password"
              size="large"
              autoComplete="off"
              placeholder="Password"
            />
          </Form.Item>
          <Row justify="center">
            {props.secondaryText && (
              <Button onClick={props.onSecondary}>{props.secondaryText}</Button>
            )}
            <SubmitButton disabled={isSubmitted}>Login</SubmitButton>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm

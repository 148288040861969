import React from "react"

function GenesisAuthorizationDisclosures({ bankName }) {
  window.scrollTo({ top: 0 })
  return (
    <div
      style={{
        height: 150,
        paddingLeft: 5,
        paddingRight: 5,
        overflow: "auto",
        textAlign: "left",
        border: "1px solid black",
      }}
    >
      By clicking the “Submit Application” button below, you confirm you are
      applying for a Concora™ Credit Account issued by {bankName} and you
      acknowledge, agree to, and/or represent the following:
      <br />
      <br />
      <ul>
        <li>
          The information you provided as part of your prequalification request
          and this application is true and correct;
        </li>
        <li>
          {bankName} may gather information about you, including from credit
          reporting agencies and other sources, to verify your identity and
          determine your eligibility for credit, renewal of credit, future
          extensions of credit, and consider you for additional products and
          services. If you ask, we will tell you whether or not we requested a
          credit report and the names and addresses of any credit reporting
          agency that provided us with such credit report;
        </li>
        <li>
          If you provided a cellular phone number or a number that is later
          converted to a cellular phone number, you consent that {bankName}. and
          any other servicer or owner of your Account may contact you about your
          Account via text message or telephone, including the use of
          prerecorded messages, and calls and messages from automated dialing
          systems. Mobile, message, and data rates may apply.
        </li>
        <li>
          If you provided an email address, you authorize {bankName}. and any
          other servicer or owner of your Account to contact you at your email
          address about your Account and/or market products and services to you.
        </li>
        <li>
          {bankName} may share your information, except for social security
          number, even if you are declined, with participating retailers that
          accept this Account. If you are approved, {bankName}. may also share
          experience and transaction information with them about your Account.
          To stop {bankName}. from sharing information about you or your Account
          with the retailer for marketing purposes, please call Concora Credit
          at 1-800-303-8341; and
        </li>
        <li>
          You have read and agree to the credit terms and other disclosures with
          this application, and you understand that if your application is
          approved, the complete Account Agreement (“Agreement”) will be sent to
          you and will govern your Account. Among other things,{" "}
          <strong>
            the Agreement includes an arbitration provision that limits your
            rights unless you reject the provision by following the provision’s
            instructions or if you are a Covered Borrower entitled to protection
            under the Federal Military Lending Act
          </strong>
          . Please see the “Military Lending Act” section of the Terms and
          Conditions and Important Disclosures.
        </li>
      </ul>
    </div>
  )
}

export default GenesisAuthorizationDisclosures

import React from "react"
import { Col, Row, Typography, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { getTheme } from "../../helpers/brand.helper"
import BRANDS from "../../utils/merchantBranding"

const { Text } = Typography
const antIcon = <LoadingOutlined style={{ fontSize: 96 }} spin />

// TODO: - Figure out how to handle the merchant logos & branding

function MerchantDetails({ merchant }) {
  const theme = getTheme()

  if (!merchant) {
    return <Spin indicator={antIcon} />
  }
  let { logo } = theme
  if (merchant.code in BRANDS) {
    logo = BRANDS[merchant.code].img
  }
  return (
    <>
      <Row style={{ textAlign: "center", margin: 0, padding: 0 }}>
        <Col span={24}>
          <img
            style={{ marginTop: -30 }}
            src={logo}
            alt="merchant-logo"
            width={124}
          />
        </Col>
      </Row>
      <Row
        style={{ paddingTop: 0, textAlign: "center" }}
        align="middle"
        justify="center"
      >
        <Col span={24}>
          <Text>{merchant.name}</Text>
          <br />
          {merchant.address && (
            <>
              <Text>{merchant.address?.street1}</Text>
              <br />
              <Text>
                {merchant.address.city}, {merchant.address.state}{" "}
                {merchant.address.zip}
              </Text>
              <br />
            </>
          )}
          {merchant.phone && (
            <>
              <Text strong>Phone:</Text> <Text>{merchant.phone.number}</Text>
              <br />
            </>
          )}
          {merchant.email && (
            <>
              <Text strong>Email:</Text>{" "}
              <Text default>{merchant.email.email}</Text>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}
export default MerchantDetails

import { Form, Select } from "formik-antd"
import * as React from "react"
import { employmentStatus } from "../../utils/values"

function CreditScoreInput(props) {
  return (
    <Form.Item
      name={props.name}
      hasFeedback
      required={false}
      label="Employment Status"
      validate={(value) =>
        value.length === 0
          ? `Please enter a valid employment status`
          : undefined
      }
    >
      <Select
        name={props.name}
        mode="single"
        size="large"
        style={{ textAlign: "left" }}
        placeholder="Employment Status"
      >
        {employmentStatus.map((s) => (
          <Select.Option key={s.value}>{s.option}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CreditScoreInput

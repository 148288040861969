import React from "react"
import "./App.less"
import { Switch, Route } from "react-router-dom"
import { RecoilRoot } from "recoil"
// import { ConfigProvider } from "antd"

import { ApplyProvider } from "./hooks/useApply"
import { ThemeProvider } from "./hooks/useTheme"

import ProtectedRoute from "./components/layout/ProtectedRoute"
import LoginComponent from "./components/auth/LoginComponent"
import BasicLayout from "./components/layout/BasicLayout"
import LogoutComponent from "./components/auth/LogoutComponent"
import PageNotFound from "./pages/PageNotFound"
import RootPage from "./pages/RootPage"
import MerchantPage from "./pages/merchant/MerchantPage"
import ApplyPage from "./pages/ApplyPage"
import LoansPage from "./pages/LoansPage"
import CardPage from "./pages/CardPage"
import {
  LoanPage,
  OfferApplyPage,
  OfferPage,
  LeadsPage,
  LeadPage,
  OrderPage,
} from "./pages"

function App() {
  // ConfigProvider.config({
  //   // prefixCls: 'custom',
  //   theme: {
  //     primaryColor: "#19C27F",
  //     infoColor: "#19C27F",
  //     // primaryColor: "#f79323",
  //     // "border-radius-base": "24px",
  //     // "input-height-base": "36px"
  //   },
  // })

  return (
    <ThemeProvider>
      <RecoilRoot>
        <ApplyProvider>
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <BasicLayout>
                  <RootPage />
                </BasicLayout>
              )}
            />
            <Route
              path="/login"
              exact
              render={() => (
                <BasicLayout>
                  <LoginComponent />
                </BasicLayout>
              )}
            />
            <Route
              path="/logout"
              exact
              render={() => (
                <BasicLayout>
                  <LogoutComponent />
                </BasicLayout>
              )}
            />
            <Route
              path="/apply"
              exact
              render={() => (
                <BasicLayout>
                  <ApplyPage />
                </BasicLayout>
              )}
            />
            <Route path={["/m/:merchant_id"]} exact component={MerchantPage} />
            <ProtectedRoute path={["/apps/:lead_id"]} component={LeadPage} />
            <ProtectedRoute path="/apps" component={LeadsPage} />
            <ProtectedRoute
              path="/offers/:offer_id"
              exact
              component={OfferPage}
            />
            <ProtectedRoute
              path="/offers/:offer_id/apply"
              exact
              component={OfferApplyPage}
            />
            <ProtectedRoute path="/loans/:loan_id" exact component={LoanPage} />
            <ProtectedRoute path="/loans" component={LoansPage} />
            <ProtectedRoute path="/card" component={CardPage} />
            <ProtectedRoute
              path="/orders/:order_id"
              exact
              component={OrderPage}
            />
            <Route path="/404" exact component={PageNotFound} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </ApplyProvider>
      </RecoilRoot>
    </ThemeProvider>
  )
}

export default App

import React from "react"
import { Col, Layout, Row } from "antd"
import BasicMenu from "./BasicMenu"
import { useTheme } from "../../hooks/useTheme"

const { Header, Content, Footer } = Layout

function BasicLayout(props) {
  const { bgColor, isMobile, brandLabel } = useTheme()

  return (
    <Layout
      style={{ backgroundColor: bgColor, height: "", overflowY: "scroll" }}
    >
      <Header
        style={{
          boxShadow: "0 -6px 10px 5px rgba(0,0,0,0.5)",
          backgroundColor: "#ffffff",
          paddingLeft: 10,
          paddingRight: 10,
          height: 60,
          position: "fixed",
          zIndex: 1,
          width: "100%",
        }}
      >
        <BasicMenu color={props.color ? props.color : null} />
      </Header>
      <Content style={{ marginTop: 60, backgroundColor: "", width: "100%" }}>
        <Row
          align="middle"
          justify="space-around"
          style={{
            backgroundColor: "",
            marginTop: isMobile ? 16 : 48,
            marginBottom: 0,
          }}
        >
          <Col
            style={{ maxWidth: 768 }}
            xxl={23}
            xl={23}
            lg={23}
            md={23}
            sm={23}
            xs={23}
          >
            {props.children}
          </Col>
        </Row>
      </Content>

      <Footer
        style={{ fontSize: 11, textAlign: "center", backgroundColor: bgColor }}
      >
        <>
          &copy;{new Date().getFullYear()} {brandLabel} Inc.
        </>
      </Footer>
    </Layout>
  )
}

export default BasicLayout

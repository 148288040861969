import React from "react"
import { Redirect } from "react-router-dom"
import { Card } from "antd"
import { Spinner } from "../components/core/Spinner"
import { useLoans } from "../hooks/hooks"
import { formatDate, moneyFormat } from "../utils/formatters"
import IndexListView from "../components/core/IndexListView"

export default function LoansPage(props) {
  const { error, loading, data } = useLoans()

  if (!data || loading || data.length < 1) {
    return <Spinner />
  }

  if (error) {
    alert("error", error)
  }

  const tableData = []

  if (data) {
    if (data.count === 1) {
      return (
        <Redirect
          to={{
            pathname: `/loans/${data.results[0].id}`,
            state: {
              from: props.location,
              lead: data.results[0],
            },
          }}
        />
      )
    }
    if (data.count === 0) {
      return (
        <div style={{ textAlign: "center" }}>
          <h1> You currently do not have any active accounts. </h1>
        </div>
      )
    }

    data.results.forEach((data) => {
      tableData.push({
        merchant: data.merchant.code,
        status: data.status,
        secondary: moneyFormat(data.approved_amount),
        secondary_title: "Amount",
        date: formatDate(data.date_created, "lll"),
        view: data.id,
        image: data.originator.image_url,
        key: data.id,
        primary_title: "Lender",
        primary: data.originator.name,
      })
    })
  }

  return (
    <Card>
      <IndexListView
        title="Loans"
        data={tableData}
        type="loan"
        resource="loans"
      />
    </Card>
  )
}

import { API_URL } from "../utils/constants"
import { authHeader } from "../services/auth-helper"

const createUrl = (base, path) => `${base}${path}`

export const getMerchant = (mid) => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/merchants/search/${mid}/`),
  }
}

export const getOrders = () => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/orders/`),
    headers: authHeader(),
  }
}

export const getOrder = (order_id) => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/orders/${order_id}/`),
    headers: authHeader(),
  }
}

export const approveOrder = (order_id, offer = null) => {
  if (offer) {
    return {
      method: "POST",
      url: createUrl(API_URL, `/v1/orders/${order_id}/approve/`),
      headers: authHeader(),
      data: offer,
    }
  }
  return {
    method: "POST",
    url: createUrl(API_URL, `/v1/orders/${order_id}/approve/`),
    headers: authHeader(),
  }
}

export const getLoans = () => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/loans/`),
    headers: authHeader(),
  }
}

export const createLead = (lead) => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/leads/`),
    body: lead,
  }
}

export const getLoan = (loan_id) => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/loans/${loan_id}/`),
    headers: authHeader(),
  }
}

export const getLeads = () => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/leads/`),
    headers: authHeader(),
  }
}

export const getLead = (lead_id) => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/leads/${lead_id}/`),
    headers: authHeader(),
  }
}

export const acceptOffer = (
  offer_id,
  applyFields = { address: {}, bank_account: {}, debit_card: {}, income: {} }
) => {
  return {
    method: "POST",
    url: createUrl(API_URL, `/v1/offers/${offer_id}/apply/`),
    data: applyFields,
    headers: authHeader(),
  }
}

export const getOffer = (offer_id) => {
  return {
    method: "GET",
    url: createUrl(API_URL, `/v1/offers/${offer_id}/`),
    headers: authHeader(),
  }
}

export const getUser = (id = "me") => [
  createUrl(API_URL, `/v1/users/${id}/`),
  {
    method: "GET",
    headers: authHeader(),
  },
]

export const register = async (first_name, last_name, email, password) => [
  createUrl(API_URL, `/v1/users/`),
  {
    method: "POST",
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      password,
    }),
  },
]

/* Validation Calls */

export const validateEmail = (email) => [
  createUrl(API_URL, `/v1/emails/validate/`),
  {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "content-type": "application/json",
    },
  },
]

export const validatePassword = (password) => [
  createUrl(API_URL, `/v1/users/validate-password/`),
  {
    method: "POST",
    body: JSON.stringify({
      password,
    }),
    headers: {
      "content-type": "application/json",
    },
  },
]

export const validatePhone = (phone) => [
  createUrl(API_URL, `/v1/phones/validate/`),
  {
    method: "POST",
    body: JSON.stringify({
      number: phone,
    }),
    headers: {
      "content-type": "application/json",
    },
  },
]
